import { DataService } from 'src/app/core/services/data.service';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { WarningModalComponent } from 'src/app/shared/components/warning-modal/warning-modal.component';
import { AFT_EMAIL, ARGO_EMAIL, EMAIL_PATTERN, LANDINI_EMAIL, MCCORMICK_EMAIL } from '../config/config.constants';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
const datepipe: DatePipe = new DatePipe('en');
import * as L from 'leaflet';
import 'moment-timezone';
import tokml from 'tokml'
import { ActivityScheduling } from '../models/farmbooks';
import { BehaviorSubject } from 'rxjs';

const MILES_CONV = 0.621371192;
const GALLONS_CONV = 0.21996925;
const GALLONS_US_CONV = 0.26417205;
const SQUARE_YARD_CONV = 11959.900463010803;
const ACRES_CONV = 2.47105381;
const POUNDS_CONV = 2.2046226218;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private dataService: DataService
  ) { }

  hostName = "";

  private _activitySchedulingBehaviorSubject: BehaviorSubject<any>  = new BehaviorSubject(null);
  activitySchedulingSelected = this._activitySchedulingBehaviorSubject.asObservable();

  selectActivityScheduling(activity: ActivityScheduling) {
    console.log('selectActivityScheduling: ' + activity);
    this._activitySchedulingBehaviorSubject.next(activity);
  }

  validateEmail(email: string) {
    return (email && email !== null && email.match(EMAIL_PATTERN) !== null);
  }

  getStylesheetString() {
    // to debug
    //this.hostName = 'test.mccormickfleet.argotractors.com';
    switch (location.host) {
      case 'argofleet.argotractors.com':
      case '178.255.75.102':
      case 'test.argofleet.argotractors.com':
        return 'argo';
      case 'landinifleet.argotractors.com':
      case 'test.landinifleet.argotractors.com':
        return 'landini';
      case 'mccormickfleet.argotractors.com':
      case 'test.mccormickfleet.argotractors.com':
        return 'mccormick';
      default: return 'aft';
    }
  }

  getMarkEmail() {
    // to debug
    //this.hostName = 'test.mccormickfleet.argotractors.com';
    switch (location.host) {
      case 'argofleet.argotractors.com':
      case '178.255.75.102':
      case 'test.argofleet.argotractors.com':
        return ARGO_EMAIL;
      case 'landinifleet.argotractors.com':
      case 'test.landinifleet.argotractors.com':
        return LANDINI_EMAIL;
      case 'mccormickfleet.argotractors.com':
      case 'test.mccormickfleet.argotractors.com':
        return MCCORMICK_EMAIL;
      default: return AFT_EMAIL;
    }
  }

  setHostName(hostname: string) {
    this.hostName = hostname;
  }

  getHostName() {
    return this.hostName;
  }

  getMainColor() {
    switch (this.getStylesheetString()) {
      case 'argo': return 'rgb(0, 67, 121)';
      case 'landini': return 'rgb(0, 161, 223)';
      case 'mccormick': return 'rgb(230, 48, 39)';
      default:
      return '#7bcd3b';
    }
  }

  showErrorPopup(error: any) {
    this.dialog.closeAll();
    let errorMessage = 'global.error.server.internalerror'
    if (error?.error?.errorCode) {
      errorMessage = 'global.error.functionalCodes.' + error?.error?.errorCode;
    }else if(error?.error?.length>0 && error?.error[0]?.errorCode){
      errorMessage = 'global.error.functionalCodes.' + error?.error[0]?.errorCode;
    }
    this.dialog.open(WarningModalComponent, {
      width: '500px',
      data: errorMessage,
      position: { top: '40px' }
    });
  }

  //Format epochTime(milliseconds) in formatType('short is default') from UTC to UserTimeZone
  //na=true return N/A if value is null
  formatterDateToUserTimezone(value: any, formatType: any, na: boolean) {

    if (na === true && !_.isNumber(value))
      return "N/A";
    else {
      var temp = this.toUserTimezone(value);
      return this.formatterDateFromEpoch(temp, formatType);
    }
  }

  //Convert datetime to user Timezone
  toUserTimezone(timestamp: any) {
    //add utc offset to timestamp
    var userDate = moment(timestamp).add(moment(timestamp).utcOffset(), 'm').valueOf();
    return userDate;
  }

  getNowTimezoneJS() {
    return this.toUserTimezoneDateJS(moment().valueOf());
  }

  /*
  * 0 smile green
  * 1 smile orange
  * 2 smile red
  * 3 X
  */
  getSmile(level: number) {
    switch (level) {
      case 0:
        return '<i class="fa fa-smile-o fa-lg text-success"></i>';
      case 1:
        return '<i class="fa fa-meh-o fa-lg text-warning"></i>';
      case 2:
        return '<i class="fa fa-frown-o fa-lg text-danger"></i>';
      case 3:
        return '<i class="fa fa-times fa-lg text-danger"></i>';
    }
    return "";
  }

  searchHighlight(html: any, term: any) {
    var src_str = html.html();
    term = term.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
    var pattern = new RegExp("(" + term + ")", "gi");

    src_str = src_str.replace(/<mark class="dx-datagrid-search-text">/gi, "");
    src_str = src_str.replace(/<\/mark>/gi, "");
    if (!_.isEmpty(term)) {
      src_str = src_str.replace(pattern, '<mark class="dx-datagrid-search-text">$1</mark>');
      src_str = src_str.replace(/(<mark class="dx-datagrid-search-text">[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/, '$1</mark>$2<mark class="dx-datagrid-search-text">$4');
    }
    html.html(src_str);
  }

  isNullOrUndefined(obj: any) {
    if (_.isNull(obj) || _.isUndefined(obj))
      return true;
    return false;
  }

  downloadFile(blob: any, filename: string) {
    var url = window.URL.createObjectURL(blob);
    var linkElement = document.createElement('a');
    linkElement.setAttribute('href', url);
    linkElement.setAttribute('download', filename);

    var clickEvent;
    var clickParams = {
      "view": window,
      "bubbles": true,
      "cancelable": false
    };
    clickEvent = new MouseEvent('click', clickParams);
    linkElement.dispatchEvent(clickEvent);
  }

  //Format epochTime(milliseconds) with angular(format:'short' is default, 'timezone':usertimezone is default)
  //date: timespan
  //format:https://docs.angularjs.org/api/ng/filter/date 'short' is default
  //timezone:'+HHMM' usertimezone is default
  formatDateFromEpoch(date, format, timezone) {
    if (_.isEmpty(format))
      format = 'short';
    if (_.isEmpty(timezone))
      timezone = moment(date).format('ZZ');

    return this.datePipe.transform(date, format, timezone);
  }

  /*#region date time utility*/
  /* USED in TimeSpan Chooser Component */
  calcTimeperiod(fromDate, toDate) {
    //var from = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    //var to  = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    var from = moment(fromDate);
    var to = moment(toDate);

    return {
      fromDate: from.startOf('day').valueOf(),
      toDate: to.endOf('day').valueOf()
    };
  }

  calcDaily(fromDate) {
    //var from = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    //var to  = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    var from = moment(fromDate);
    var to = from.clone();

    return {
      fromDate: from.startOf('day').valueOf(),
      toDate: to.endOf('day').valueOf()
    };
  }

  calcWeek(fromDate) {
    //var from = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    //var to  = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    var from = moment(fromDate);
    var to = from.clone();
    from.startOf('week').add(1, 'days');
    to.endOf('week').add(1, 'days');

    return {
      fromDate: from.startOf('day').valueOf(),
      toDate: to.endOf('day').valueOf()
    };
  }

  calcLast7Days() {
    //var from = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    //var to = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    var from = moment();
    var to = from.clone();
    from.subtract(6, 'days');

    return {
      fromDate: from.startOf('day').valueOf(),
      toDate: to.endOf('day').valueOf()
    };
  }


  calcMonth(fromDate) {
    //var from = moment.tz(fromDate, 'UTC');
    //var to = from.clone();
    var from = moment(fromDate);
    var to = from.clone();

    from.startOf('month');
    to.endOf('month');

    return {
      fromDate: from.startOf('day').valueOf(),
      toDate: to.endOf('day').valueOf()
    };
  }


  getSurfaceLabel() {
    let user = this.dataService.getCurrentUserInfo();
    switch (user.user.preferences.surface.label) {
      case 'square_yard':
        return 'sq yd';
      case 'acre':
        return 'ac';
      default:
        return 'ha';
    }
  }

  convertFromHectare(hectare) {
    var res: any = null;
    let user = this.dataService.getCurrentUserInfo();
    console.log(user.user.preferences.surface.label);
    
    if (_.isNumber(hectare)) {
      switch (user.user.preferences.surface.label) {
        case 'square_yard':
          res = this.mathround(hectare * SQUARE_YARD_CONV, 2);
          break;
        case 'acre':
          res = this.mathround(hectare * ACRES_CONV, 2);
          break;
        default:
          res = this.mathround(hectare, 2);
          break;
      }
    }
    return res;
  }

  convertToGeoJSON(fields: any) {
    var result: any = {};
    var geojson: any = {};
    var fenceLayer: any = null;
    var fenceLayerGeoJSON = null;
    var filename = "";
    if (fields.length > 0) {
      var layers: any = [];
      _.each(fields, function (field: any) {
        layers.push(field.layer);
        if (filename !== "") filename += '%';
        filename += field.name.replace(/ /g, '_');
      });
      fenceLayer = L.layerGroup(layers);
      fenceLayerGeoJSON = fenceLayer.toGeoJSON();

      geojson = fenceLayerGeoJSON;
      result.filename = filename + '.geojson';
      result.geojsonStr = JSON.stringify(geojson);
    }
    return result;
  }

  convertToKml(fields: any) {
    var result:any = {};
    var fenceLayer:any = null;
    var fenceLayerGeoJSON:any = null;
    var filename = "";

    if (fields.length > 0) {
        if (fields.length === 1) {
            fenceLayer = fields[0].layer;
            fenceLayerGeoJSON = fenceLayer.toGeoJSON();

            filename = fields[0].name.replace(/ /g, '_');
        } else { // > 1 
            var layers:any[] = [];
            _.each(fields, function (field) {
                layers.push(field.layer);
                if (filename !== "") filename += '%';
                filename += field.name.replace(/ /g, '_');
            });
            fenceLayer = L.layerGroup(layers);
            fenceLayerGeoJSON = fenceLayer.toGeoJSON();
        }
        result.filename = filename + '.kml';
        result.kml = tokml(fenceLayerGeoJSON, {
            name: 'name',
            description: 'description',
            simplestyle: true
        });
    }
    return result;
  }

  mathround(value, decimals) {
    return Number(Math.round(parseFloat( value + 'e' + decimals)) + 'e-' + decimals);
  }

  getWeightLabel() {
    let user = this.dataService.getCurrentUserInfo();
    switch (user.user.preferences.weight.label) {
      case 'pound':
        return 'lb';
      default:
        return 'kg';
    }
  }

  getVolumeLabel() {
    let user = this.dataService.getCurrentUserInfo();
    switch (user.user.preferences.volume.label) {
      case 'gallon_uk':
        return 'Gal';
      case 'gallon_us':
        return 'Gal(US)';
      default:
        return 'L';
    }
  }

  getConsLabel() {
    let user = this.dataService.getCurrentUserInfo();
    switch (user.user.preferences.consume.label) {
      case 'gallon_uk-h':
        return 'Gal(UK)/h';
      case 'gallon_us-h':
        return 'Gal(US)/h';
      default:
        return 'L/h';
    }
  }

  toUserTimezoneDateJS(timestamp) {
    var userTimestamp = this.toUserTimezone(timestamp);
    var browserOffset = moment.tz(timestamp, moment.tz.guess()).utcOffset();
    var userDate = moment(userTimestamp).subtract(browserOffset, 'm').toDate();
    return userDate;
  }


  userTimezoneDate(timestamp) {
    let user = this.dataService.getCurrentUserInfo();
    var userTimestamp = moment(timestamp).add(moment.tz(timestamp, user.user.preferences.timezone.label).utcOffset(), 'm').valueOf()
    var browserOffset = moment.tz(timestamp, moment.tz.guess(true)).utcOffset();
    var userDate = moment(userTimestamp).subtract(browserOffset, 'm').toDate();
    return userDate;
  }

  getStringDate(date: any, start?: any) {
    var time = (start === true) ? " 00:00:00" : " 23:59:59";
    var month = (date.getMonth() + 1);
    if (month.toString().length === 1) {
      month = "0" + month;
    }
    var day = date.getDate();
    if (day.toString().length === 1) {
      day = "0" + day;
    }
    var stringDate = date.getFullYear() + "-" + month + "-" + day + time;
    return stringDate;
  }

  convertFromLiters(liters) {
    let user = this.dataService.getCurrentUserInfo();
    var res: any = null;
    if (_.isNumber(liters)) {
      switch (user.user.preferences.volume.label) {
        case 'gallon_uk':
          res = this.mathround(liters * GALLONS_CONV, 2);
          break;
        case 'gallon_us':
          res = this.mathround(liters * GALLONS_US_CONV, 2);
          break;
        default:
          res = this.mathround(liters, 2);
          break;
      }
    }
    return res;
  }

  convertFromLitersHours(litersHours) {
    let user = this.dataService.getCurrentUserInfo();
    var res: any = null;
    if (_.isNumber(litersHours)) {
      switch (user.user.preferences.consume.label) {
        case 'gallon_uk':
          res = this.mathround(litersHours * GALLONS_CONV, 2);
          break;
        case 'gallon_us':
          res = this.mathround(litersHours * GALLONS_US_CONV, 2);
          break;
        default:
          res = this.mathround(litersHours, 2);
          break;
      }
    }
    return res;
  }

  convertFromKilograms(kilos) {
    let user = this.dataService.getCurrentUserInfo();
    var res: any = null;
    if (_.isNumber(kilos)) {
      switch (user.user.preferences.weight.label) {
        case 'pound':
          res = this.mathround(kilos * POUNDS_CONV, 2);
          break;
        default:
          res = this.mathround(kilos, 2);
          break;
      }
    }
    return res;
  }

  toUTC(dateJS) {
    var utcDate = moment.utc(dateJS).subtract(dateJS.getTimezoneOffset(), 'm').subtract(moment(dateJS).utcOffset(), 'm').valueOf();
    return utcDate;
  }

  convertToLiters(toLiters) {
    let user = this.dataService.getCurrentUserInfo();
    var res: any = null;
    if (_.isNumber(toLiters)) {
      switch (user.user.preferences.volume.label) {
        case 'gallon_uk':
          res = this.mathround(toLiters / GALLONS_CONV, 8);
          break;
        case 'gallon_us':
          res = this.mathround(toLiters / GALLONS_US_CONV, 8);
          break;
        default:
          res = this.mathround(toLiters, 4);
          break;
      }
    }
    return res;
  } convertToLitersHours(toLitersHours) {
    let user = this.dataService.getCurrentUserInfo();
    var res: any = null;
    if (_.isNumber(toLitersHours)) {
      switch (user.user.preferences.consume.label) {
        case 'gallon_uk':
          res = this.mathround(toLitersHours / GALLONS_CONV, 8);
          break;
        case 'gallon_us':
          res = this.mathround(toLitersHours / GALLONS_US_CONV, 8);
          break;
        default:
          res = this.mathround(toLitersHours, 4);
          break;
      }
    }
    return res;
  }

  convertToKilograms(toKilos) {
    let user = this.dataService.getCurrentUserInfo();
    var res: any = null;
    if (_.isNumber(toKilos)) {
      switch (user.user.preferences.weight.label) {
        case 'pound':
          res = this.mathround(toKilos / POUNDS_CONV, 2);
          break;
        default:
          res = this.mathround(toKilos, 2);
          break;
      }
    }
    return res;
  }

  getPropertyValueFromString(obj, propName) {
    var res = obj;
    if (!_.isEmpty(obj) && propName) {
      var props = propName.split('.');
      for (var i = 0; i < props.length; ++i) {
        if (!_.isEmpty(res))
          res = res[props[i]];
      }
    }
    return res;
  }

  getMenuItem(menu, labelToFound) {
    var menuItem;
    _.each(menu, function (item) {
      if (item.label === labelToFound) {
        menuItem = item;
      } else if (item.child.length > 0) {
        //checking childrens
        var childItemFouneded = _.find(item.child, { label: labelToFound });
        if (childItemFouneded !== undefined) {
          menuItem = childItemFouneded;
        }
      }
    });
    return menuItem;
  }

  convertSecondsToTime(seconds, withSeconds = false) {
    var res = "00:00";
    if (withSeconds)
      res += ":00";
    if (seconds === null) {
      res = "--:--";
      if (withSeconds)
        res += ":--";
    }
    else if (seconds > 0) {
      var hh = (seconds / 3600) >>> 0;
      var totsec = seconds - hh * 3600;
      var mm = (totsec / 60) >>> 0;
      totsec = totsec - mm * 60;

      res = this.formatNumberLength(hh, 2) + ":" + this.formatNumberLength(mm, 2);
      if (withSeconds)
        res += ":" + this.formatNumberLength(totsec, 2);
    }
    return res;
  }

  convertFromKmLiters(kmL) {
    var res: any = null;
    let identity = this.dataService.getCurrentUser();
    if (_.isNumber(kmL)) {
      switch (identity.user.preferences.consume.label) {
        case 'liter-100km':
          if (kmL > 0)
            res = this.mathround(100 / kmL, 2);
          else
            res = this.mathround(kmL, 2);
          break;
        case 'mile-gallon_uk':
          res = this.mathround(kmL * MILES_CONV / GALLONS_CONV, 2);
          break;
        case 'mile-gallon_us':
          res = this.mathround(kmL * MILES_CONV / GALLONS_US_CONV, 2);
          break;
        default:
          res = this.mathround(kmL, 2);
          break;
      }
    }
    return res;
  }

  getSpeedLabel() {
    let identity = this.dataService.getCurrentUser();
    switch (identity.user.preferences.length.label) {
      case 'mile':
        return 'Mph';
      default:
        return 'Km/h';
    }
  }

  getDistanceLabel() {
    let identity = this.dataService.getCurrentUser();
    switch (identity.user.preferences.length.label) {
      case 'mile':
        return 'Mil';
      default:
        return 'Km';
    }
  }

  getTodayTimezoneJS() {
    return this.toUserTimezoneDateJS(moment().startOf('day').valueOf());
  }

  chartCrossHair(customizeText?) {
    return {
      enabled: true,
      color: '#B1C543',
      width: 3,
      dashStyle: "dot",
      label: {
        visible: true,
        backgroundColor: '#B1C543',
        font: {
          color: "#fff",
          size: 12,
        },
        customizeText: customizeText
      }
    };
  }

  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  guid() {
    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
      this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }

  sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  //Format epochTime(milliseconds) in formatType('short is default')
  formatterDateFromEpoch(value: any, formatType?: any) {
    if (_.isEmpty(formatType))
      formatType = 'short';
    if (formatType === 'complete')
      return datepipe.transform(value, 'shortDate', 'UTC') + ' ' + datepipe.transform(value, 'mediumTime', 'UTC');

    return datepipe.transform(value, formatType, 'UTC');
  }

  getUids(input, objRecordsBool, uidRecordName, paramToValidate?, paramBooleanValue?) {
    var toRet: any = [];

    if (input && typeof input[0] === "object") {

      if (objRecordsBool) {
        _.each(input, function (item) {
          if (!(paramToValidate) || hasPropertyAndMatch(item)) {
            var obj = {};
            obj[uidRecordName] = item.uid;
            toRet.push(obj);
          }
        });
      } else {
        _.each(input, function (item) {
          if (!(paramToValidate) || hasPropertyAndMatch(item)) {
            toRet.push(item.uid);
          }
        });
        toRet = toRet.join(',');
      }

    } else if (input && typeof input[0] === "string") {

      _.each(input, function (item) {
        var obj = {};
        obj[uidRecordName] = item;
        toRet.push(obj);
      });
    }
    return toRet;

    function hasPropertyAndMatch(item) {
      return item.hasOwnProperty(paramToValidate) && item[paramToValidate] === paramBooleanValue;
    }
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  fromUTC_toDateJS(timestamp) {
    var offset = new Date(timestamp).getTimezoneOffset();
    var userDate = moment(timestamp).add(offset, 'm').toDate();
    return userDate;
  }

  getDrivernameWithCardId(driver) {
    if (_.isEmpty(driver.fullName))
      driver.fullName = driver.lastName + ' ' + driver.firstName;
    if (!_.isEmpty(driver.cardId))
      return driver.fullName + ' (' + driver.cardId + ')';
    else
      return driver.fullName;
  }

  extendVehiclesProperty(vehicles) {
    _.each(vehicles, (vehicle) => {
        if (!_.isEmpty(vehicle.internalId))
            vehicle.plateWithInternalId = vehicle.plate + ' (' + vehicle.internalId + ')';
        else
            vehicle.plateWithInternalId = vehicle.plate;
    });
}

extractVehiclesFromFleets(fleets, sorted) {
  let vehicles: any[] = [];
  _.each(fleets, (fleet) => {
      _.each(fleet.items, (vehicle: any) => {
          if (_.isEmpty(_.find(vehicles, (vehiclefilled) => {
              return vehicle.uid === vehiclefilled.uid;
          }))
          ) {
              vehicles.push(vehicle);
          }
      });
  });
  if (vehicles.length > 0 && sorted) {
      vehicles = _.sortBy(vehicles, (vehicle) => {
          return [vehicle.plate.toLowerCase(), vehicle.internalId.toLowerCase()].join("_");
      });
  }
  return vehicles;
}

  convertFromKm(km) {
    var MILES_CONV = 0.621371192;
    let identity = this.dataService.getCurrentUser();
    var res: any = null;
    if (_.isNumber(km)) {
      if (identity.user.preferences.length.label === 'mile')
        res = this.mathround(km * MILES_CONV, 2);
      else
        res = this.mathround(km, 2);
    }
    return res;
  }

  cloneJsObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  formatNumberLength(num, length) {
    var r = "" + num;
    while (r.length < length) {
      r = "0" + r;
    }
    return r;
  }

  toTimestamp_fromDateJS(dateJS) {
    var offset = dateJS.getTimezoneOffset();
    var timestamp = moment(dateJS).subtract(offset, 'm').valueOf();
    return timestamp;
  }
}
