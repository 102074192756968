// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'test',
  baseApi: "https://test.actiafleet4agri.com/api",
  //baseApiCloud: "https://webapi-agri-test-agrirouter.azurewebsites.net/api/agrirouter",
  baseApiCloud: "https://func-agrirouter-api-test-westeu-001.azurewebsites.net/api",
  apiVersion: "1.18.0.0",
  clientVersion: "1.3.9.0",
  applicationVersion: "3.0.3",
  actiaSupportEmail: "actiasupport@actiaitalia.com",
  mailArgoTractors: "atfleetmanager@argotractors.com",
  actiaSupportPhone: "+39 02 93271399",
  hereApiKey: "wmD6vBkP3ZJNv78OUzcvWTIvBaaJETuZ3XBnrI_UGqE",
  weatherApiKey: "c710589b0e3d4a193cbbae4a637a092a",
  hereUrl: 'maps.ls.hereapi.com',
  weatherApiUrl: 'maps.openweathermap.org',
  newGeoreverseHereURL: 'https://revgeocode.search.hereapi.com/v1/revgeocode',
  multiReverseHereUrl: 'https://reverse.geocoder.ls.hereapi.com/6.2/multi-reversegeocode.json',
  //georeverseHereUrl: 'https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json',
  orsServiceUrl: 'https://api.openrouteservice.org/geocode/',
  orsApiToken: '5b3ce3597851110001cf6248ff41dc332def43858dff1ecccdd19bbc',
  redirectUrlAgrirouter: 'https://test.actiafleet4agri.com/#/agrirouter/registry',
  redirectUrlAgrirouterAdministration: 'https://test.actiafleet4agri.com/#/agrirouter/administration',
  signalRUrl: 'https://test.actiafleet4agri.com/signalr/tracking/',
  webCallSignalRurl:'https://test.actiafleet4agri.com/signalr/webcall/',
  alertingSignalRUrl: 'https://test.actiafleet4agri.com/signalr/alerting/',
  baseUrl: 'https://test.actiafleet4agri.com/',
  powerBIreport: 'AGRIMessages_Databricks_ETL_demo',
  pBI_silentDevices: 'ReportSection7107c454e9edcf400eef',
  pBI_homepage: 'ReportSection5c97cba0d90375be3528',
  pBI_consumptionTrend: 'ReportSection5f5cf732128a39477472',
  pBI_deadlines: 'ReportSection576f8066dcfeab5dad52',
  powerBIFiltersVisible:true, // FZ per redere visibili i filtri di POwerBi
  powerBIFiltesExpand:false, // per rendere espansi i filtri di POwerBi
  refreshMillisecondsMaps: 120000
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
