import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CANMAP_ENDPOINT, CANMAP_OFCUSTOMER_ENDPOINT } from 'src/app/core/config/config.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-assign-map-modal',
  templateUrl: './assign-map-modal.component.html',
  styleUrls: ['./assign-map-modal.component.scss']
})
export class AssignMapModalComponent implements OnInit {
  vehicles: any = [];
  models: any = [];
  selectedValue: any;
  submitted = false;
  isError = false;
  uidCanMapType: any;
  selectedVehicles: any[] = [];
  selectedModels: any[] = [];
  selectedUids: string[] = [];
  showClearButton: boolean = true
  selectedModelRows: any;
  selectedVehicleRows: any;
  service: string;
  selectedCustomerUid: string;
  tabPanelItems: { text: any; service: string; }[];
  chosenTab: { text: any; service: string; };

  constructor(public dialogRef: MatDialogRef<AssignMapModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private utilsService: UtilsService,
    private translate: TranslateService) {
    this.uidCanMapType = data.uidCanMapType;
    this.selectedCustomerUid = data.selectedCustomerUid;
    this.getData();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.tabPanelItems = [
        { text: this.translate.instant('global.models'), service: 'model' },
        { text: this.translate.instant('global.vehicles'), service: 'vehicle' }
      ];
      this.chosenTab = this.tabPanelItems[0];
    }, 500);
  }

  close(): void {
    this.dialogRef.close();
  }

  onTabChanged(e: any): void {
    this.chosenTab = e.addedItems[0];
  }

  onTreeItemClick(e): void {
    this.selectedCustomerUid = e.uid;
    this.getData();
  }

  getData(): void {
    if (this.selectedCustomerUid) {
        this.getModels();
        this.getVehicles();
    }
  }

  confirm(): void {
    
    if (this.chosenTab.service == 'vehicle'){
      this.assignVehicle();
    } else if (this.chosenTab.service == 'model') {
      this.assignModel();
    }
  }

  assignModel(): void {
    this.apiService.post(CANMAP_ENDPOINT + this.uidCanMapType + "/vehicleModels/associations", this.selectedModels)
      .subscribe(
        () => {
          this.dialogRef.close({refresh: true});
        },
        error => {
          this.dialogRef.close();
          this.utilsService.showErrorPopup(error);
        });
  }

  assignVehicle(): void {
    this.apiService.post(CANMAP_ENDPOINT + this.uidCanMapType + "/vehicles/associations", this.selectedVehicles)
      .subscribe(
        () => {
          this.dialogRef.close({refresh: true});
        },
        error => {
          this.dialogRef.close();
          this.utilsService.showErrorPopup(error);
        });
  }

  getModels(): void {
    this.models = [];
    this.apiService.get(CANMAP_OFCUSTOMER_ENDPOINT + '/' + this.selectedCustomerUid + '/list/byVehicleModels')
      .subscribe(
        (models: any) => {
          this.models = models;
        },
        error => {
          this.utilsService.showErrorPopup(error);
        });
  }

  getVehicles(): void {
    this.vehicles = [];
    this.apiService.get(CANMAP_OFCUSTOMER_ENDPOINT + '/' + this.selectedCustomerUid + '/list/byVehicles')
      .subscribe(
        (vehicles: any) => {
          this.vehicles = vehicles;
        },
        error => {
          this.utilsService.showErrorPopup(error);
        });
  }

  modelSelectionChanged(e: any): void {
    this.selectedModelRows = e.selectedRowsData;
    this.selectedModels = this.selectedModelRows.map(row => row.uid);
  }

  vehicleSelectionChanged(e: any): void {
    this.selectedVehicleRows = e.selectedRowsData;
    this.selectedVehicles = this.selectedVehicleRows.map(row => row.uid);
  }  

}
