import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { ICON_STATE_MAPPER } from 'src/app/core/config/config.constants';
import { FilterService, AssetsModel } from '../filter/filter.service';

@Component({
  selector: 'app-assets-chooser',
  templateUrl: './assets-chooser.component.html',
  styleUrls: ['./assets-chooser.component.scss']
})
export class AssetsChooserComponent implements OnInit, OnDestroy {

  hasStarted = false;
  clearAssetsEvent: Subscription;
  applyFilterEvent: Subscription;
  onApplyWithoutSelection: Subscription;
  assetsTabEvent: Subscription;
  timer: any;
  firstAssetVisit = true;
  selectedAssets: any[] = [];

  constructor(
    public translateService: TranslateService,
    private filterService: FilterService,
    private cdRef: ChangeDetectorRef
  ) { }

  assetChooserTypes = [
    { icon: 'fa fa-user', name: this.translateService.instant("global.driver"), service: 'driver' },
    { icon: ICON_STATE_MAPPER['tacho'], name: this.translateService.instant("global.vehicles"), service: 'vehicle' },
    { icon: 'fa fa-group', name: this.translateService.instant("global.menu.teams"), service: 'teams' },
    { icon: ICON_STATE_MAPPER['tacho'], icon2: 'af3 af3-tractor', name: this.translateService.instant("global.menu.fleets"), service: 'fleets' }
  ];


  enabledTabPanelItems: any[] = [];
  selectedTab: string;
  currentApiDataSource: any[] = [];
  currentIndex = 0;


  defaultTypes = ['vehicle', 'driver', 'fleets', 'teams'];
  currentDataSource: DataSource;

  async ngOnInit() {
    this.checkTabPanelItems();
    this.selectedTab = this.enabledTabPanelItems[0]?.type;
    this.filterService.assetsInitEvent.subscribe(async (_) => {
      this.enabledTabPanelItems = [];
      this.checkTabPanelItems();
      this.selectedTab = this.enabledTabPanelItems[0]?.type;
      this.selectedAssets = [];
      let selectedAssetsCache = this.filterService.getSelectedAssets();
      this.hasStarted = false;
      this.timer =  setTimeout(async () => {
        if (!this.hasStarted){
        this.selectedTab = selectedAssetsCache?.assets?.type;
        this.enabledTabPanelItems.forEach((panelItem, idx) => {
          if(panelItem.type == this.selectedTab) this.currentIndex = idx;
        });
        this.currentDataSource = await this.updateDataSource();
        if (selectedAssetsCache?.assets?.selected?.length > 0) {
          selectedAssetsCache?.assets?.selected.forEach((selectedAsset) => {
            this.selectedAssets.push(selectedAsset);
          })
        } else this.selectedAssets = [];
        this.hasStarted = true;
      } 
       }, 100);


    });

    this.applyFilterEvent = this.filterService.applyFilterEvent.subscribe(
      async (data: any) => {
        if (this.filterService.getCurrentFilterOptions()!.assets) {
          this.filterService.updateSelectedAssetsCache();
        }
      }
    )

    this.clearAssetsEvent = this.filterService.clearAssetsEvent.subscribe((data: any) => {
      this.selectedAssets = [];
      this.filterService.updateSelectedAssets({
        customer: this.filterService.getSelectedCustomer(),
        assets: {
          type: 'vehicle',
          selected: []
        }
      });
    })

    this.onApplyWithoutSelection = this.filterService.onApplyWithoutSelection.subscribe(
      async (data: any) => {
        this.selectedTab = 'vehicle';
        this.currentDataSource = await this.updateDataSource();
        let newAssetsAfterApply: AssetsModel = {
          customer: this.filterService.getSelectedCustomer(),
          assets: {
            type: this.selectedTab.toLowerCase(),
            selected: this.currentApiDataSource
          }
        };
        if (newAssetsAfterApply.assets.selected.length > 0) {
          newAssetsAfterApply.assets.selected.forEach((selectedAsset) => {
            this.selectedAssets.push(selectedAsset);
          })
        } else this.selectedAssets = [];
        this.filterService.updateSelectedAssets(newAssetsAfterApply);
        this.filterService.updateSelectedAssetsCache();
      }
    )

    this.assetsTabEvent = this.filterService.assetsTabEvent.subscribe(async (data) => {
      this.firstAssetVisit = true;
      this.currentDataSource = await this.updateDataSource();
    });

  };

  onCheckboxToggle(ev) {
    if (ev.addedItems?.length > 0) {
      ev.addedItems.forEach(addedItem => {
        let addedItemUid = addedItem.uid;
        if (this.selectedAssets?.length > 0) {
          let idxFound = this.selectedAssets.findIndex(function(selectedAsset) {
            return selectedAsset.uid == addedItemUid;
          });
          if (idxFound == -1) this.selectedAssets.push(addedItem);
        }
        
      });
    }

    if (ev.removedItems?.length > 0) {
      ev.removedItems.forEach(removedItem => {
        let removedItemUid = removedItem.uid;
        if (this.selectedAssets?.length > 0) {
          let idxFound = this.selectedAssets.findIndex(function(selectedAsset) {
            return selectedAsset.uid == removedItemUid;
          });
          if (idxFound != -1) {
            let idxToRm = this.selectedAssets.indexOf(removedItem);
            this.selectedAssets.splice(idxToRm, 1);
          }
        }
      });
    }
    this.updateAssets();
  }

  updateAssets() {
    this.filterService.updateSelectedAssets({
      customer: this.filterService.getSelectedCustomer(),
      assets: {
        type: this.selectedTab?.toLowerCase(),
        selected: this.selectedAssets
      }
    });
  }

  checkTabPanelItems() {
    if(this.filterService.getCurrentFilterOptions()?.assets)
    {
      let typesToCycle = this.filterService.getCurrentFilterOptions()!.assets && this.filterService.getCurrentFilterOptions()!.assets!.types?.length > 0 ? this.filterService.getCurrentFilterOptions()!.assets!.types : this.defaultTypes;
      typesToCycle.forEach((type) => {
        this.assetChooserTypes.forEach((tabPanelItem) => {
          if (type == tabPanelItem.service) this.enabledTabPanelItems.push(
            {
              icon: tabPanelItem.icon,
              type: tabPanelItem.service.toLowerCase(),
              translatedType: tabPanelItem.name,
            });
        });
      });
    }
  }

  async onTabChanged(event) {
    this.selectedTab = event.itemData.type as string;
    this.selectedAssets = [];
    this.enabledTabPanelItems.forEach((panelItem, idx) => {
      if (panelItem.type == this.selectedTab) this.currentIndex = idx;
    });
    if(this.selectedTab === this.filterService.getSelectedAssets()?.assets?.type && this.filterService.getSelectedAssets()?.assets?.selected?.length > 0)
      this.selectedAssets = this.filterService.getSelectedAssets()?.assets?.selected;
    this.updateAssets();
    this.currentDataSource = await this.updateDataSource();
  }

  async updateDataSource() {
    this.currentApiDataSource = await this.filterService.getAssetsDataSource(this.selectedTab);
    let editedApiDataStore = this.currentApiDataSource;    
    if (editedApiDataStore && editedApiDataStore?.length > 0) {
      if (this.selectedTab == 'vehicle') editedApiDataStore?.forEach((el) => el.text = `${el.internalId} (${el.plate})`);
      else if(this.selectedTab == 'driver') editedApiDataStore?.forEach((el) => el.text = `${el.fullName}`);
      else editedApiDataStore?.forEach((el) => el.text = `${el.name}`);
    }
    return new DataSource({
      store: new ArrayStore({
        key: 'uid',
        data: editedApiDataStore
      }),
      paginate: editedApiDataStore?.length > 10 ? true : false,
      pageSize: 10
    });
  }

  onContentReady(e: any){
    if (this.firstAssetVisit){
      if (this.filterService.getSelectedAssets()?.assets?.selected?.length !== 0){
        this.selectedAssets = this.filterService.getSelectedAssets()?.assets?.selected;
      } else {
        e?.component?.selectAll(); 
      }
      this.firstAssetVisit = false;
    }
  }

  ngAfterViewChecked(){
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.selectedAssets = [];
    this.hasStarted = false;
    clearTimeout(this.timer)
    this.clearAssetsEvent?.unsubscribe();
    this.applyFilterEvent?.unsubscribe();
    this.onApplyWithoutSelection?.unsubscribe();
    this.assetsTabEvent?.unsubscribe();
  }

}
