import { Component, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { EventEmitter } from '@angular/core';
import { UtilsService } from 'src/app/core/services/utils.service';
import { FilterService } from '../filter/filter.service';
import { VehicleMaintenanceService } from 'src/app/pages/vehicules-maintenance/vehicles-maintenance.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'grid-toolbar',
    templateUrl: './grid-toolbar.component.html',
    styleUrls: ['./grid-toolbar.component.scss']
})
export class GridToolbarComponent implements OnInit {
    @Output() onExportClicked = new EventEmitter<any>();
    @Output() vehicleChanged = new EventEmitter<any>();
    @Output() onSwitchChanged = new EventEmitter<any>();
    @Input() vehiclesList: any;

    @Input() options: any;

    defaultConfig = {
        showVehicles: false,
        showExport: true,
    };
    selectedVehicle: any;
    prevVehicle: any;
    vehicles: any[] = [];
    switchedOnText: string;
    switchedOffText: string;

    constructor(private utilsService: UtilsService,
        private filterService: FilterService,
        private translateService: TranslateService,
        private vehicleMaintenanceService: VehicleMaintenanceService) {
        //this.fillVehicles();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.switchedOnText = this.translateService.instant('global.deadlines.pending')
            this.switchedOffText = this.translateService.instant('global.deadlines.history')
            this.fillVehicles();
        }, 2000)
        this.options = _.extend(this.defaultConfig, this.options);
    }

    btnExportClick() {
        this.onExportClicked.emit()
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.fillVehicles();
    }

    onVehicleChanged(e: any) {
        if (e.previousValue !== e?.value) {
            this.selectedVehicle = e.selectedItem;
            let vehicle = this.vehicles.find(vehicle => vehicle?.uid === e?.value)
            this.vehicleChanged?.emit(vehicle);
        }
        else {
            this.prevVehicle = null;
        }
    }

    switchChanged(e) {
        this.onSwitchChanged.emit(e.value);
    }

    fillVehicles() {
        this.selectedVehicle = null;
        this.prevVehicle = null;
        this.vehicles = [];
        let filters = this.filterService.getApiParams();
        if (filters.assetType === "VEHIC") {
            if (this.filterService.getSelectedAssets()) {
                this.vehicles = this.filterService.getSelectedAssets()?.assets.selected;
                this.utilsService.extendVehiclesProperty(this.vehicles);
                if (this.vehicles !== null && this.vehicles.length > 0) {
                    this.selectedVehicle = this.vehicles[0];
                    this.vehicleChanged?.emit(this.selectedVehicle);
                }
            }
        }
        else if (filters.assetType === "FLEET") {
            this.loadVehiclesFromFleets(this.filterService.getSelectedAssets()?.assets.selected);
        }
    }

    loadVehiclesFromFleets(fleets) {
        var params = { uidFleets: _.map(fleets, "uid").join(',') };
        this.vehicleMaintenanceService.getListByFleetsUid(params).subscribe((fleets) => {
            if (!_.isEmpty(fleets)) {
                this.vehicles = this.utilsService.extractVehiclesFromFleets(fleets, true);
                this.utilsService.extendVehiclesProperty(this.vehicles);
                if (this.vehicles !== null && this.vehicles.length > 0) {
                    this.selectedVehicle = this.vehicles[0];
                }
            }
        });
    }

}
