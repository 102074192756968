import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgWizardConfig, NgWizardService, StepChangedArgs, THEME } from 'ng-wizard';
import { FilterService } from './filter.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterOptions } from 'src/app/core/models/filterOptions';
import { UtilsService } from 'src/app/core/services/utils.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, AfterViewInit {

  filterBody: HTMLElement;
  wizardToolbar: HTMLElement;
  isFilterVisible = false;
  isCustomerStringVisible = true;
  stylesheetString = "aft";
  customersReadGrant : boolean;
  stepName: string;

  @ViewChild('filter') filter: ElementRef;


  @HostListener('document:click', ['$event'])
  clickout(event) {
    const clickedElement = event?.target as HTMLElement;

    // Check if the clicked element is the "ok" button
    const isOkButtonClicked = clickedElement.classList.contains('btn-ok');
    if (isOkButtonClicked) {
      return;
    }

    if(!this.filter.nativeElement.contains(clickedElement)) {
      if (!event?.DXCLICK_FIRED && this.isFilterOpen()) {
        this.filterService.closeFilter();
      }
    }
  }
  
  translationObject = {
    customer: {
      title: this.translateService.instant("global.customers"),
      description: this.translateService.instant("global.selectCustomer"),
    },
    asset: {
      title: this.translateService.instant("global.assets"),
      description: this.translateService.instant("global.selectAssets"),
    },
    timeperiod: {
      title: this.translateService.instant("global.timeperiod"),
      description: this.translateService.instant("global.selectTimePeriod"),
    },
    filter: this.translateService.instant("global.filter")

  }

  customerFilterState: any;
  assetsFilterState: any;
  timespanFilterState: any;

  ngWizardConfigs: NgWizardConfig = {
    selected: 0,
    theme: THEME.default,
    anchorSettings: {
      enableAllAnchors: true,
      markAllPreviousStepsAsDone: false,
      markDoneStep: false
    },
    toolbarSettings: {
      showNextButton: false, showPreviousButton: false,
      toolbarExtraButtons: [
        { text: this.translateService.instant("global.applyFilter"), class: 'btn btn-sm', event: () => { this.applyFilter(); } }
      ],
    }
  };

  constructor(private ngWizardService: NgWizardService,
    private filterService: FilterService,
    public translateService: TranslateService,
    private utilsService: UtilsService,
    private authService: AuthService) { }

  ngOnInit() {
    this.stylesheetString = this.utilsService.getStylesheetString();
    this.filterBody = document.querySelector(".filter-body")! as HTMLElement;
    this.customersReadGrant = this.authService.isFeatureGranted('customers', 'read');
    this.filterService.filterChangeEvent.subscribe((filterOptions: FilterOptions | null) => {
      this.isFilterVisible = false;
      this.isCustomerStringVisible = true;
      if (filterOptions != null) {
        this.isFilterVisible = true;
        if(this.customersReadGrant){
          this.customerFilterState = filterOptions?.customer;
        }else
        {
          if(filterOptions?.assets == null && filterOptions?.timespan == null){
            this.isFilterVisible = false;
          }
        }
        this.assetsFilterState = filterOptions?.assets != null;
        this.timespanFilterState = filterOptions?.timespan != null;
        if (filterOptions.showCustomerString != null) this.isCustomerStringVisible = filterOptions?.showCustomerString;
        setTimeout(() => {
          if (this.isCustomerStringVisible) this.wizardToolbar.style.visibility = "hidden";
          else this.wizardToolbar.style.visibility = "visible";
          this.setStepperFlex();
          this.ngWizardService.reset();
        }, 100); 
      }
    })
  }

  applyFilter() {
    this.filterService.applyFilter(this.stepName);
  }

  onTreeItemClick(e){
    if (this.filterService.currentSelectedAssets){
      this.filterService.currentSelectedAssets.customer = e;
      this.filterService.currentSelectedAssets.assets.selected = [];
      localStorage.setItem("selectedAssetsCache", JSON.stringify(this.filterService.currentSelectedAssets));
    }
  }

  ngAfterViewInit(): void {
    // Additional styling for custom wizard toolbar & body
    this.wizardToolbar = document.querySelector(".ng-wizard-toolbar") as HTMLElement;
    if(this.stylesheetString != 'aft') (document.querySelector("#ngwizard") as HTMLElement).classList.add(this.stylesheetString);
    this.wizardToolbar.style.backgroundColor = "white";
    this.wizardToolbar.style.borderTop = "1px solid black";
  }

  setStepperFlex() {
    let stepperMain = document.querySelector("#ngwizard > ul")!;
    let lastActiveChild = stepperMain.lastElementChild;
    if (lastActiveChild) {
      for (let i = 0; i < stepperMain.children.length; i++) {
        let stepperMainChild = stepperMain.children[i] as HTMLElement;
        stepperMainChild.style.flex = "none";
      }
      (<HTMLElement>lastActiveChild).style.flex = "1";
    }
  }

  getCustomerTranslation() {
    return this.filterService.getCustomerTranslation();
  }

  stepChanged(args: StepChangedArgs) {
    this.stepName = args.step.title;
    if (args.step.title == this.translateService.instant("global.assets")) {
      this.filterService.assetsTabEvent.emit();
    }
  }

  toggleFilter() {
    this.filterService.toggleFilter();
  }

  isFilterOpen(): boolean {
    return this.filterService.isFilterOpen();
  }

}
