import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-modal',
  templateUrl: './import-success-modal.component.html',
  styleUrls: ['./import-success-modal.component.scss']
})
export class ImportSuccessModalComponent implements OnInit {
display: string[] = [];
  constructor(public dialogRef: MatDialogRef<ImportSuccessModalComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {
    if (typeof(data) == 'object'){
      Object.keys(data).forEach(key => {
        this.display.push(data[key]);
      })
    }
   }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }
}
