import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { UtilsService } from 'src/app/core/services/utils.service';
import { FilterService } from '../filter/filter.service';

type SelectBoxType = {
  id: string,
  value: string
};

type DataAvailabilty = {
  unit: string,
  num: number
}

@Component({
  selector: 'app-timespan-chooser',
  templateUrl: './timespan-chooser.component.html',
  styleUrls: ['./timespan-chooser.component.scss']
})
export class TimespanChooserComponent implements OnInit {

  constructor(private utilsService: UtilsService, private filterService: FilterService, private translateService: TranslateService) { }

  defaultPeriods = ['timeperiod', 'daily', 'week', 'last7days', 'month'];
  daysMaxPeriodDefault = 100;

  timespanSelectBoxItems: SelectBoxType[] = [];
  selectedPeriodType: SelectBoxType;

  nowDate = moment().valueOf();

  maxDaysPeriod: number;

  //default values from service
  currentTimespan: string = 'month';
  fromDtPicker: number;
  toDtPicker: number;

  fromDtPickerMinVal = moment().subtract(2, 'year').valueOf();

  fromDate: number;
  toDate: number;

  displayFormat: any;
  calendarOptions: Object;

  summaryFromDate: string | null;
  summaryToDate: string | null;

  oldDataAvailabilityDefault: DataAvailabilty = {
    unit: "year",
    num: 2
  };

  oldDataAvailability: DataAvailabilty;

  ngOnInit(): void {
    //default values from service
    this.filterService.initTimespanChooserEvent.subscribe(
      (data: any) => {
        this.maxDaysPeriod = this.filterService.getCurrentFilterOptions()!.timespan && this.filterService.getCurrentFilterOptions()!.timespan?.daysMaxPeriod ?  this.filterService.getCurrentFilterOptions()!.timespan!.daysMaxPeriod : this.daysMaxPeriodDefault;
        this.timespanSelectBoxItems = this.createTranslatedOptionsForTimespanSelectBox();
        this.currentTimespan = this.filterService.getTimespanSelection();
        this.fromDtPicker = this.filterService.getFromDtPicker();
        this.toDtPicker = this.filterService.getToDtPicker();

      }
    );

    this.filterService.applyFilterEvent.subscribe(
      (data: any) => {
        this.filterService.setTimespanSelection(this.filterService.getTimespanSelection());
        this.filterService.setFromDtPicker(this.filterService.getFromDtPicker());
        this.filterService.setToDtPicker(this.filterService.getToDtPicker());
        this.filterService.setFromDate(this.filterService.getFromDate());
        this.filterService.setToDate(this.filterService.getToDate());
        this.filterService.updateSelectedDateCache();
      }
    )

  }

  createTranslatedOptionsForTimespanSelectBox() {
    let periodsToCycle = this.filterService.getCurrentFilterOptions()!.timespan && this.filterService.getCurrentFilterOptions()!.timespan!.periods.length > 0 ?  this.filterService.getCurrentFilterOptions()!.timespan!.periods : this.defaultPeriods;
    var items: SelectBoxType[] = [];
    periodsToCycle.forEach((item) => {
      items.push({ id: item, value: this.translateService.instant("global." + item) });
    }); return items;
  }

  toValueChanged(ev) {
    // this.setMaxIntervalDate(true);
    if (ev.value) this.toDtPicker = ev.value.valueOf();
    this.calcToDate();
  }

  fromValueChanged(ev) {
    // this.setMaxIntervalDate(false);
    if (ev.value) this.fromDtPicker = ev.value.valueOf();
    this.calcToDate();
  }

  selectBoxChanged(ev) {
    if(ev.selectedItem) {
     if(ev.selectedItem.id != this.currentTimespan) this.fromDtPicker = this.toDtPicker = moment().valueOf();
    this.currentTimespan = ev.selectedItem.id;
    this.updateCalendarOptions();
    //this.setMaxIntervalDate(true);
    this.calcToDate();
    }
  }

  updateCalendarOptions() {
    if (this.currentTimespan == 'month') {
      this.fromDtPickerMinVal = moment().subtract(1, 'month').startOf('month').valueOf();
      this.displayFormat = 'MMM y';
      this.calendarOptions = {
        maxZoomLevel: 'year',
        minZoomLevel: 'century'
      }
    } else {
      this.fromDtPickerMinVal = moment().subtract(this.maxDaysPeriod, 'days').startOf('day').valueOf();
      this.translateService.get('global.dateFormat').subscribe(dateFormat => {
          this.displayFormat = dateFormat;
      })
      this.calendarOptions = {
        maxZoomLevel: 'month',
        minZoomLevel: 'century'
      }
    }
  }

  calcToDate() {
    switch (this.currentTimespan) {
      case 'timeperiod':
        this.calcTimeperiod();
        break;
      case 'daily':
        this.calcDaily();
        break;
      case 'week':
        this.calcWeek();
        break;
      case 'last7days':
        this.calcLast7Days();
        break;
      case 'month':
        this.calcMonth();
        break;
      default:
        break;
    }
    this.filterService.setToDate(this.toDate);
    this.filterService.setFromDate(this.fromDate);
    this.filterService.setTimespanSelection(this.currentTimespan);
  }

  calcTimeperiod() {
    var span = this.utilsService.calcTimeperiod(this.fromDtPicker, this.toDtPicker);
    this.fromDate = span.fromDate;
    this.toDate = span.toDate;
    this.setPeriodData(this.fromDtPicker, this.toDtPicker, this.fromDate, this.toDate);
  }

  calcDaily() {
    var span = this.utilsService.calcDaily(this.fromDtPicker);
    this.fromDate = span.fromDate;
    this.toDate = span.toDate;
    this.setPeriodData(this.fromDtPicker, this.toDtPicker, this.fromDate, this.toDate);
  }

  calcWeek() {
    var span = this.utilsService.calcWeek(this.fromDtPicker);
    this.fromDate = span.fromDate;
    this.toDate = span.toDate;
    this.summaryFromDate = this.utilsService.formatDateFromEpoch(span.fromDate, 'shortDate', null);
    this.summaryToDate = this.utilsService.formatDateFromEpoch(span.toDate, 'shortDate', null);
    this.toDtPicker = moment().valueOf();
    this.setPeriodData(this.fromDtPicker, this.toDtPicker, this.fromDate, this.toDate);
  }

  setPeriodData(fromDtPicker, toDtPicker, fromDate, toDate){
    this.filterService.setFromDtPicker(fromDtPicker);
    this.filterService.setToDtPicker(toDtPicker);
    this.filterService.setFromDate(fromDate);
    this.filterService.setToDate(toDate);
  }

  calcMonth() {
    var span = this.utilsService.calcMonth(this.fromDtPicker);
    this.fromDate = span.fromDate;
    this.toDate = span.toDate;
    this.summaryFromDate = this.utilsService.formatDateFromEpoch(span.fromDate, 'shortDate', null);
    this.summaryToDate = this.utilsService.formatDateFromEpoch(span.toDate, 'shortDate', null);
    this.toDtPicker = moment().valueOf();
    this.setPeriodData(this.fromDtPicker, this.toDtPicker, this.fromDate, this.toDate);
  }

  calcLast7Days() {
    var span = this.utilsService.calcLast7Days();
    this.fromDate = span.fromDate;
    this.toDate = span.toDate;
    this.summaryFromDate = this.utilsService.formatDateFromEpoch(span.fromDate, 'shortDate', null);
    this.summaryToDate = this.utilsService.formatDateFromEpoch(span.toDate, 'shortDate', null);
    this.setPeriodData(this.fromDtPicker, this.toDtPicker, this.fromDate, this.toDate);
  }

}
