import { AuthService } from './../services/auth.service';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        
        return next.handle(req).pipe(catchError(x=> this.handleAuthError(x)));
    }
    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401) {
            this.authService.logOut();         
        }
        return throwError(err);
    }
}