<div class="card text-center">
  <div class="card-header">
    <div class="{{stylesheetString}}-header__logo">
    </div>
  </div>
  <div class="card-body boldText">
    <img class="spacing" src="/assets/images/support_team.png" height="60"/>
    <p class="card-text">{{"agrirouter.supportMessage" | translate}}</p>
    <p class="card-text" style="text-decoration: underline;">Email: {{email}}</p>
  </div>
  <div class="text-center spacing">
    <button class="btn {{stylesheetString}}-button-background-color" (click)="closeModal()">OK</button>
  </div>
</div>