import { DataService } from './../../../../core/services/data.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { faBars, faBell, faCog, faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons';
import { CURRENT_USER_ENDPOINT, LOGINAS_USER, LOGIN_ENDPOINT, VERSION_BAG_ENDPOINT } from 'src/app/core/config/config.constants';
import { environment } from 'src/environments/environment';
import { CurrentUserInfo } from 'src/app/core/models/currentUserInfo';
import { ApiService } from 'src/app/core/services/api.service';
import { VersionBag } from 'src/app/core/models/versionBag';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { WarningModalComponent } from '../../warning-modal/warning-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';
import { AlertingService } from 'src/app/core/services/alerting.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { SelectedCustomerCacheInterface } from 'src/app/core/store/interface/selectedCustomerCache.interface';
import { AppStateInterface } from 'src/app/appState.interface';
import { Store, select } from '@ngrx/store';
import * as PostActions from '../../../../core/store/actions'
import { Subscription } from 'rxjs';
import { getApplySelectedCustomer } from 'src/app/core/store/selectors';
import { SelectedPeriodFitler } from 'src/app/core/store/interface/selectedPeriod.interface';
import * as moment from 'moment';
import { OverlayPanel } from 'primeng/overlaypanel';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  clientVersion: string;
  faBars = faBars;
  faUser = faUser;
  faCog = faCog;
  faBell = faBell;
  faPowerOff = faPowerOff;
  public versions: VersionBag[];
  public impersonating = false;
  public hasAlerting: boolean;
  public demoMode: boolean;
  public user: any;
  public alertNotReads: number;
  @Output() navToggled = new EventEmitter<any>();
  @ViewChild('opUser') public opUser: OverlayPanel = null as any;
  public withShadingOptionsVisible: boolean;
  stylesheetString = 'aft';
  classOpen: any;

  constructor(private apiService: ApiService,
    private dataService: DataService,
    private authService: AuthService,
    private dialog: MatDialog,
    public router: Router,
    public alertingService: AlertingService,
    private utilService: UtilsService,
    private store: Store<AppStateInterface>) {
    this.clientVersion = environment.applicationVersion;
  }

  ngOnInit(): void {
    this.classOpen = this.opUser;
    console.log('classOpen', this.classOpen)
    this.withShadingOptionsVisible = false;
    this.hasAlerting = true;
    this.demoMode = false;
    // this.checkCurrent();
    let customerinfo = this.dataService.getCurrentUserInfo()

    this.checkCurrent();
    if (customerinfo) {
      let customerInfo: SelectedCustomerCacheInterface = {

        isVirtual: false,
        isFieldTest: false,
        isSubscriptionEnded: false,
        subscriptionEndDate: null,
        vatCode: null,
        fiscalCode: null,
        contactName: null,
        contactPhone1: null,
        contactPhone2: null,
        contactEmail: null,
        contactAddress: null,
        logo: null,
        uidCountry: '',
        uidLegalRule: '',
        groups: [],
        features: [],
        children: [],
        isTachoLight: false,
        maxVehicles: null,
        name: customerinfo.customer.name,
        uidParent: customerinfo.customer.uidParent,
        isHardLocked: customerinfo.customer.isHardLocked,
        uid: customerinfo.customer.uid,
        nameWithChildren: '',
        timezone: customerinfo.customer.timezone


      }

      let appStorePeriod: SelectedPeriodFitler = {
        fromDtPicker: moment(new Date).valueOf(),
        toDtPicker: moment(new Date).valueOf(),
        timespanSel: 'timeperiod', //timeperiod month daily week last7days
        isApply: false
      };

      this.store.dispatch(PostActions.setSelectedCustomerCache(customerInfo))
      this.store.dispatch(PostActions.setApplySelectedCustomer(customerInfo))
      this.store.dispatch(PostActions.getVehiclesOfCustomers());
      this.store.dispatch(PostActions.getDriversOfCustomers());
      //   this.store.dispatch(PostActions.getTrailersOfCustomers());
      this.store.dispatch(PostActions.setSelectedPediod(appStorePeriod));
      this.store.dispatch(PostActions.setApplySelectedPediod(appStorePeriod))


      //#FZ IMPORTANTE filter-ref
      // qui setto il default preso da customerInfo se non trovo il setSelectedCustomerCache in local Store
      // NB vedi hydration dello store -> state
      // e carico già i CUSTOMER da visualizzare nel menù
      this.store.dispatch(PostActions.getCustomers());

      setTimeout(() => {
        let subgetApplySelectedCustomer: Subscription;
        subgetApplySelectedCustomer = this.store.pipe(select(getApplySelectedCustomer)).subscribe((res) => {
          let appStorePeriod: SelectedPeriodFitler = {
            fromDtPicker: moment(new Date).valueOf(),
            toDtPicker: moment(new Date).valueOf(),
            timespanSel: 'timeperiod', //timeperiod month daily week last7days
            isApply: false
          };
          if (res.uid == '') {
            this.store.dispatch(PostActions.setSelectedCustomerCache(customerInfo))
            this.store.dispatch(PostActions.setApplySelectedCustomer(customerInfo))
            this.store.dispatch(PostActions.getVehiclesOfCustomers());
            this.store.dispatch(PostActions.getDriversOfCustomers());
            //   this.store.dispatch(PostActions.getTrailersOfCustomers());
            this.store.dispatch(PostActions.setSelectedPediod(appStorePeriod));
            this.store.dispatch(PostActions.setApplySelectedPediod(appStorePeriod))
  
  
  
          }
  
  
  
          // let appStoreList:SelectedAssets
          // if (res.defaultAssetType == 'VEHIC') {
          //   appStoreList = {
          //     assetType: 'VEHIC',
          //     selected: []
          //   };
          // } else {
          //   appStoreList = {
          //     assetType: 'DRIVR',
          //     selected: []
          //   };
          // }
          // // this.store.dispatch(PostActions.setApplySelectedAssets(appStoreList));
          // this.store.dispatch(PostActions.setSelectedAssets(appStoreList));
  
        });
        subgetApplySelectedCustomer.unsubscribe();
      }, 3000);





      //  this.store.dispatch(PostActions.setSelectedCustomerCache(customerInfo))
      //   this.store.dispatch(PostActions.setApplySelectedCustomer(customerInfo))
      this.store.dispatch(PostActions.setUser(customerinfo))

    }

    this.getVersion();
    this.stylesheetString = this.utilService.getStylesheetString();
  }

  openTogle($event) {
    this.classOpen = this.opUser.overlayVisible;
    console.log('classOpen', this.classOpen)
    this.opUser.toggle($event)
    setTimeout(() => {
      let element = document.querySelector('.p-overlaypanel') as HTMLElement;
      if (element) {
        if (!this.classOpen) {
          element.style.position = 'fixed';
          element.style.top = '35px'
        } else {
          element.style.position = 'absolute';
        }
      }
    }, 100);
  }


  toggleWithShadingOptions() {
    this.withShadingOptionsVisible = !this.withShadingOptionsVisible;
  }

  toggleNav() {
    this.navToggled.emit();
  }

  confirmLogout() {

    this.authService.logOut();
  }

  confirmLogoutImpersonatedUser() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: "global.confirmEndEmulatingUser",
      position: { top: '40px' }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        let { token } = await <any>this.apiService.delete(LOGINAS_USER).toPromise();
        localStorage.clear();
        localStorage.setItem("token", token);
        this.dataService.refreshNav();
      }
    });
  }

  //  getCurrent() {
  //   let current: any = this.dataService.getCurrentUserInfo();
  //   if (current) {
  //     this.user = current.user;
  //     this.impersonating = current.user.impersonatingOtherUser;
  //     console.log(current, "getCurrent");
  //   } else {
  //     localStorage.clear();
  //     this.router.navigate(['/auth/login']);
  //   }
  // }

  checkCurrent() {
    this.apiService.get(CURRENT_USER_ENDPOINT)
      .subscribe(
        (currentUserInfo: any) => {
          currentUserInfo = currentUserInfo as CurrentUserInfo;
          this.user = currentUserInfo.user;
          this.dataService.setCurrentUserInfo(currentUserInfo);
          this.impersonating = this.dataService.getCurrentUserInfo().impersonatingOtherUser;

          this.alertingService.alertingConnect();
        },
        error => {
          let e = error as HttpErrorResponse;

          if (e.status == 401) {
            localStorage.clear();
            this.router.navigate(['/auth/login']);
          }
          this.dialog.open(WarningModalComponent, {
            width: '500px',
            data: e.status + e.statusText,
            position: { top: '40px' }
          });

        });
  }

  getVersion() {
    this.apiService.get(VERSION_BAG_ENDPOINT)
      .subscribe(
        (versions: any) => {
          versions = versions as VersionBag[];
          this.versions = versions;
        },
        error => {

        });
  }
  toogleMenu() {
    this.opUser.toggle(event);
  }
}
