import { HelpHeaderPopupComponent } from './help-header-popup/help-header-popup.component';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICON_STATE_MAPPER } from 'src/app/core/config/config.constants';
import { FilterOptions } from 'src/app/core/models/filterOptions';
import { UtilsService } from 'src/app/core/services/utils.service';

import { faBinoculars, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AssetsSearchComponent } from '../assets-search/assets-search.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {

  constructor(

    public translateService: TranslateService,
    private utilsService: UtilsService,
    private authService: AuthService,
    private dialogService: MatDialog
  ) { }

  @Input() headTitle: string;
  @Input() help: any = null;

  isCustomerVisible: boolean;
  customerString: string;

  faBinoculars: any;
  faHelp: any;
  assetsInitEvent: Subscription;
  applyFilterEvent: Subscription;

  searchAssetEnable: boolean = true;

  isAssetsVisible: boolean;
  assetsObject: Partial<{
    icon: any,
    type: string,
    length: number
  }> = {};

  isTimespanVisible: boolean;
  timespanObject: Partial<{
    timespan: string,
    from: any,
    to: any
  }> = {};

  ngOnInit(): void {
    this.searchAssetEnable = this.authService.isFeatureGranted('customers', 'read');
    this.faBinoculars = faBinoculars;
    this.faHelp = faQuestion;




  }

  openSearchAsset() {
    this.dialogService.open(AssetsSearchComponent, {
      width: "950px",
      position: {top: '40px'}
    })
  }

  openHelpPopup(){
    this.dialogService.open(HelpHeaderPopupComponent, {
      width: '600px',
      data: this.help
    });
  }




  ngOnDestroy(): void {
      this.assetsInitEvent?.unsubscribe();
      this.applyFilterEvent?.unsubscribe();
  }

}
