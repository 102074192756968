<div class="card">
  <h4 class="card-header">{{'global.detailPlan' | translate}}</h4>
  <div class="card-body">
    <dx-data-grid id="gridContainer" [dataSource]="piani" [allowColumnReordering]="true" [allowColumnResizing]="true"
      [showRowLines]="true" (onRowInserted)="add($event)" (onRowValidating)="onRowValidating($event)" [height]="500">
      <dxi-column dataField="idManual" caption="" *ngIf="!custom" [width]="70">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>
      <dxi-column dataField="description" caption="{{'global.description' | translate}}" dataType="string"
        [allowSearch]="true">
      </dxi-column>
      <dxo-paging [pageSize]="pageSize"> </dxo-paging>
      <dxo-pager [allowedPageSizes]="allowedPageSizes" [showPageSizeSelector]="true" [visible]="true"
        [displayMode]="'full'" [showInfo]="true" [showNavigationButtons]="true">
      </dxo-pager>
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{'global.search' | translate}}">
      </dxo-search-panel>
      <dxo-editing mode="cell" [allowUpdating]="edit" [allowAdding]="edit" [useIcons]="true"></dxo-editing>
      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-toolbar>
        <dxi-item name="addRowButton"></dxi-item>
        <dxi-item name="searchPanel"></dxi-item>
      </dxo-toolbar>
    </dx-data-grid>


  </div>
  <div class="card-footer">
    <a (click)="close()" class="btn btn-no">{{'global.close' | translate}}</a>
  </div>
</div>