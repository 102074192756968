import { faRightLeft, faMagnifyingGlass,faCircleExclamation, faFileExcel,faPlus, faRefresh,faClone, faBackward, faFloppyDisk, faCheck, faPen, faBan, faCheckCircle, faWarning, faCalendarCheck, faTrash, faCogs, faCreditCard, faGasPump, faLineChart, faLocationDot, faRoad, faTint, faTractor, faUserTie, faSeedling, faExclamationTriangle, faEyeSlash, faEye, faArrowRightArrowLeft, faBoxArchive, faUsd, faCircleInfo,faUserShield, faScrewdriverWrench} from "@fortawesome/free-solid-svg-icons";

export const ESRI_APIKEY = "AAPK392a310f2a96449380cc087e6008b0d8no4SHm_DUPpEe_ZEkoiNzl9jWADLn5rjHQXw3z9tMiAHnhAjR-gYpelkkmuEmfSs";

/*EndPoints*/
export const PREFERENCE_ENDPOINT = "/common/preferences";
export const CHANGE_PASSWORD_ENDPOINT = "/account/password/change";
export const LOGIN_ENDPOINT = "/account/authentication";
export const CURRENT_USER_ENDPOINT = "/users/current";
export const VERSION_BAG_ENDPOINT = "/platform/version";

export const MAINTENANCE_OPERATION_ENDPOINT = "/maintenance/operation";
export const MAINTENANCE_PLAN_ENDPOINT_OFCUSTOMER = "/maintenance/plan/ofCustomer";
export const MAINTENANCE_PLAN_ENDPOINT_OPERATIONS_BLOCKS = "/maintenance/plan/operationsBlocks";
export const MAINTENANCE_OPERATION_ENDPOINT_OPERATIONS_BLOCKS = "/maintenance/operation/operationsBlock";
export const MAINTENANCE_LAST_ENGINE_HOURS = "/maintenance/lastEngineHours/";
export const MAINTENANCE_PLAN_ENDPOINT_OFVEHICLE = "/maintenance/plan/ofVehicle";
export const MAINTENANCE_PLAN_ENDPOINT = "/maintenance/plan";
export const MAINTENANCE_PLAN_DELEATE_ENDPOINT = "/maintenance/plan/operationsBlock/";
export const MAINTENANCE_EDIT_ENDPOINT = "/maintenance/pending/";
export const MAINTENANCE_EDIT_ENDPOINT_OFVEHICLE = "/maintenance/pending/";
export const MAINTENANCE_HISTORY = "/maintenance/history/";
export const MAINTENANCE_PLAN_CUSTOMPLAN = "/maintenance/plan/customPlan";
export const MAINTENANCE_PLAN_CUSTOMPLAN_UPDATE = "/maintenance/plan/customPlan/update";
export const MAINTENANCE_OPERATION_OPERATION_BLOCK_UPDATE = "/maintenance/operation/operationsBlock/update";
export const MAINTENANCE_PENDING_OFVEHICLE = "/maintenance/pending/ofVehicle/";
export const MAINTENANCE_HISTORY_OFVEHICLE = "/maintenance/history/ofVehicle/";
export const RESET_PASSWORD_ENDPOINT = "/account/password/askreset";
export const CONFIRM_RESET_PASSWORD_ENDPOINT = "/account/password/confirmreset";
export const ALERTS_BY_CUSTOMER_ENDPOINT = "/alerting/ofCustomer";
export const VEHICLES_BY_CUSTOMER_ENDPOINT = "/vehicles/ofCustomer";
export const DATE_LAST_VEHICLE_POLISION = "/tracking/getDateLastVehiclePosition/ofCustomer/";
export const DATE_LAST_JOURNEY = "/tracking/getDateLastJourney/ofCustomer/";
export const GEOFENCING_BY_CUSTOMER_ENDPOINT = "/geofencing/ofCustomer";
export const TACHO_ACTIVITIES_ENDPOINT = "/tacho/cardData/driveractivities";
export const CUSTOMERS_BY_CUSTOMER_ENDPOINT = "/customers/ofCustomer";
export const TRACKING_JOURNEY_ENDPOINT = "/tracking/journey";
export const ALERTING_ENDPOINT = "/alerting";
export const ALERT_CHANNELS_ENDPOINT = "/common/alertingNotificationChannels";
export const FLEETS_OF_CUSTOMER_ENDPOINT = "/fleets/ofCustomer/";
export const VEHICLES_OF_CUSTOMER_ENDPOINT = "/vehicles/ofCustomer/";
export const USERS_OF_CUSTOMER_ENDPOINT = "/users/ofCustomer/";
export const USERS_ACTIVATION_EMAIL_ENDPOINT = "/users/sendActivationEmail";
export const USERS_LOCK_TOGGLE_ENDPOINT = "/users/lock/toggle";
export const USERS_GROUP_ENDPOINT = "/users/group";
export const ONE_USER_ENDPOINT = "/users/user";
export const USERS_ENDPOINT = "/users";
export const USRES_BY_CUSTOMER_ENDPOINT = "/users/ofCustomer";
export const GROUPS_BY_CUSTOMER_ENDPOINT = "/groups/ofCustomer";
export const FLEETS_ENDPOINT = "/fleets";
export const FLEETS_INFO_ENDPOINT = "/fleets/fleet";
export const TEAMS_BY_CUSTOMER_ENDPOINT = "/teams/ofCustomer";
export const DRIVERSS_BY_CUSTOMER_ENDPOINT = "/myassets/drivers/ofCustomer";
export const ONE_TEAM_ENDPOINT = "/teams/team";
export const TEAMS_ENDPOINT = "/teams";
export const SEARCH_DEVICES_ENDPOINT = "/devices/search";
export const ONE_DEVICE_ENDPOINT = "/devices/device";
export const DEVICES_ENDPOINT = "/devices";
export const DEVICE_MODELS_ENDPOINT = "/common/deviceModels";
export const INSTALLATION_TYPE_ENDPOINT = "/common/installationTypes";
export const COUNTRIES_ENDPOINT = "/common/countries";
export const DEVICES_CARRIERS_ENDPOINT = "/common/deviceCarriers";
export const CONFIG_OF_DEVICES_ENDPOINT = "/devices/configuration/ofDevice";
export const DEVICES_HISTORY_ENDPOINT = "/devices/historicalBindingsOf";
export const DEVICES_REPORT_ENDPOINT = "/devices/report/ofDevice";
export const DEVICES_SENDSMS_ENDPOINT = "/devices/sendSMS";
export const DEVICES_CONFIG_ENDPOINT = "/devices/configuration";
export const DRIVERS_BY_CUSTOMER_ENDPOINT = "/drivers/ofCustomer";
export const DRIVERS_NAMESAKE_BY_CUSTOMER_ENDPOINT = "/drivers/namesake/ofCustomer";
export const MERGE_NAMESAKE_ENDPOINT = "/drivers/namesake/merge";
export const DRIVERS_ASSIGN_ENDPOINT = "/drivers/assign/toCustomer";
export const DRIVERS_UNASSIGN_ENDPOINT = "/drivers/unassign";
export const DRIVER_FORCEDDD_ENDPOINT = "/drivers/driver/forceDDD";
export const ONE_DRIVER_ENDPOINT = "/drivers/driver";
export const DRIVERS_MOVE_CUSTOMER_ENDPOINT = "/drivers/move/toCustomer";
export const ACCOUNT_ACTIVATE_ENDPOINT = "/account/activate";
export const ONE_GROUP_ENDPOINT = "/groups/group";
export const GROUPS_ENDPOINT = "/groups";
export const FEATURES_CUSTOMER_ENDPOINT = "/features/ofCustomer";
export const ONE_CUSTOMER_ENDPOINT = "/customers/customer";
export const DEFAULT_FMW_ENDPOINT = "/fmw/default/byCustomer";
export const CANMAP_OFCUSTOMER_ENDPOINT = "/canmaps/ofCustomer";
export const CANMAP_CUSTOMER_ENDPOINT = "/v2/canmaps/ofCustomer/";
export const CANMAP_DISSACIATION = "/v2/canmaps/vehicle/dissociation";
export const CANMAP_ENDPOINT = "/v2/canmaps/";
export const CANMAP_ASSIGNTO_ENDPOINT = "/canmaps/map/assignTo";
export const FIRMWARE_LIST_ENDPOINT = "/fmw/pckgs";
export const FIRMWARE_INFO_ENDPOINT = "/fmw/pckg";
export const DEVICE_MODELS_LIST_ENDPOINT = "/common/deviceModels";
export const ASSIGN_FIRMWARE_ENDPOINT = "/fmw/assign";
export const IMPLEMENT_TYPES_ENDPOINT = "/common/domainValues?domain=ImplementTypes&language=";
export const IMPLEMENTS_OF_CUSTOMER_ENDPOINT = "/implements/ofCustomer/";
export const IMPLEMENT_ENDPOINT = "/implement/";
export const UNASSIGN_IMPLEMENT_ENDPOINT = "/implements/unassign?uidImplements=";
export const ASSIGN_IMPLEMENT_ENDPOINT = "/implements/assign/toCustomer";
export const PUT_IMPLEMENT_ENDPOINT = "/implements/implement";
export const CHANGE_IMPLEMENT_CUSTOMER_ENDPOINT = "/implements/move/toCustomer/";
export const ONE_VEHICLE_ENDPOINT = "/vehicles/vehicle";
export const VEHICLE_MOVE_ENDPOINT = "/vehicles/move/toCustomer";
export const FMWTOKEN_ENDPOINT = "/fmw/getRepoToken";
export const MYASSETS_VEHICLE_ENDPOINT = "/myassets/vehicles/ofCustomer/";

export const MYASSETS_FLEETS_OFCUSTOMER = "/myassets/fleets/ofCustomer/";
export const MYASSETS_TEAMS_OFCUSTOMER = "/myassets/teams/ofCustomer/";
export const MYASSETS_VEHICLES_OFCUSTOMER = "/myassets/vehicles/ofCustomer/";
export const MYASSETS_DRIVERS_OFCUSTOMER = "/myassets/drivers/ofCustomer/";
export const FARM_OFCUSTOMER_ENDPOINT = "/farm/ofCustomer";
export const FARM_LOGBOOKSOFCUSTOMER_ENDPOINT = "/farm/logbooks/ofCustomer";
export const FARM_LOGBOOKS_ENDPOINT = "/farm/logbooks";
export const FARM_STOPLOGBOOKS_ENDPOINT = "/farm/logbook/stop";
export const FARM_LOGBOOKSSCHEDULES_ENDPOINT = "/farm/logbookSchedules";
export const FARM_LOGBOOKSSCHEDULES_OFCUSTOMER_ENDPOINT = "/farm/logbookSchedules/ofCustomer";
export const FARM_LOGBOOKSSCHEDULES_OFCUSTOMER_WITHFILTER = "/farm/logbookSchedules/ofCustomerWithFilter";
export const DOMAIN_VALUES_ENDPOINT = "/common/domainValues";
export const ASSET_VEHICLES_OFCUSTOMER_ENDPOINT = "/myassets/vehicles/ofCustomer";
export const ASSET_DRIVERS_OFCUSTOMER_ENDPOINT = "/myassets/drivers/ofCustomer";
export const ASSET_IMPLEMENTS_OFCUSTOMER_ENDPOINT = "/myassets/implements/ofCustomer";
export const HOMEPAGE_MAP_OFCUSTOMER_ENDPOINT = "/homepage/map/ofCustomer";
export const GET_FAULTS_ENDPOINT = "/fault/getFaults";
export const GET_VEHICLES_SEARCH = "/myassets/vehicles/search";
export const GET_DEVICES_SEARCH = "/myassets/devices/search";
export const GET_DRIVERS_SEARCH = "/myassets/drivers/search";
export const LOGINAS_OF_CUSTOMER = "/loginas/customer";
export const LOGINAS_USER = "/loginas/user";
export const VEHICLE_MODELS = "/vehicleModels/list";
export const VEHICLE_MODELS_DETAIL = '/vehicleModels';
export const VEHICLE_MODEL_IMPORT = '/vehicleModels/import';
export const FLEETS = '/fleets'
export const MISCELLANEUS_ENDPOINT = '/miscellaneous';
export const TRAILERS_BY_CUSTOMER_ENDPOINT = '/etrailer/ofCustomer';

/* PowerBI Endpoints */
export const POWERBI_AUTH_ENDPOINT = "/powerbi/get-access-token/";

/* Agrirouter Endpoints */
// registry page endpoints
export const AGRIROUTER_AUTHORIZATION_ENDPOINT = "/onboard/authorizationUrl?redirectUrl=";
export const AGRIROUTER_ONBOARD_TELEMETRY_ENDPOINT = "/onboard/telemetryPlatform";
export const AGRIROUTER_OFFBOARD_TELEMETRY_ENDPOINT = "/offboard/telemetryPlatform";
export const AGRIROUTER_REONBOARD_TELEMETRY_ENDPOINT = "/reonboard/telemetryPlatform";
export const AGRIROUTER_ONBOARD_VCU_ENDPOINT = "/onboard/:deviceId";
export const AGRIROUTER_OFFBOARD_VCU_ENDPOINT = "/offboard/:deviceId";
export const AGRIROUTER_ONBOARD_CHECK = "/onboard/:uidARDevice/check"
export const AGRIROUTER_OFFBOARD_VCU_RESET = "/offboard/vcu/reset"
export const AGRIROUTER_ONBOARD_CAPABILITIES = "/onboard/:uidARDevice/capabilities"
export const AGRIROUTER_ONBOARD_VCU_STATUS_ENDPOINT = "/onboard/:deviceId/status";
export const AGRIROUTER_ONBOARD_TEL_STATUS_ENDPOINT = "/onboard/telemetryPlatform/ofCustomer/:customerId/status";
export const AGRIROUTER_CREATE_DEVICE_ENDPOINT = "/ardevices";
export const AGRIROUTER_UPDATE_DEVICE_ENDPOINT = "/ardevices/:deviceId";
export const AGRIROUTER_GET_DEVICES_ENDPOINT = "/ardevices/ofCustomer/:uidCustomer";
export const AGRIROUTER_GET_DEVICE_INFO_ENDPOINT = "/ardevices/:deviceId";
export const AGRIROUTER_DELETE_DEVICE_ENDPOINT = "/ardevices/:deviceId";
export const AGRIROUTER_LOAD_DEVICES_ENDPOINT = "/ardevices/devices/ofCustomer/:customerId";
// EFDI status page endpoints
export const AGRIROUTER_GET_COMMUNICATIONS_STATUS_ENDPOINT = "/armessages/lastProcessed/ofCustomer/:customerId";
// TaskData messages page endpoints
export const AGRIROUTER_GET_MSGS_DONE_ENDPOINT = "/armessages/done/ofCustomer/:uidCustomer";
export const AGRIROUTER_GET_MSGS_IN_TRANSIT_ENDPOINT = "/armessages/intransit/ofCustomer/:uidCustomer";
export const AGRIROUTER_GET_MSGS_ACK_ENDPOINT = "/armessages/acknowledged/ofCustomer/:uidCustomer";
// send TaskData endpoints
export const AGRIROUTER_GET_FILE_UPLOADS_ENDPOINT = "/armessages/uploadfile/ofCustomer/:uidCustomer";
export const AGRIROUTER_GET_RECEIVERS_ENDPOINT = "/armessages/uploadfile/receivers/ofCustomer/:uidCustomer";
export const AGRIROUTER_UPLOAD_FILE_ENDPOINT = "/armessages/uploadfile/ofCustomer/:uidCustomer";
export const AGRIROUTER_SEND_UPLOADED_FILE_ENDPOINT = "/armessages/uploadfile/send";

export const VEHICLE_TYPES_ENDPOINT = "/common/vehicles";
export const HIDE_VEHICLE_ENDPOINT = "/hide/toggle";
export const ENGINE_TYPES_ENDPOINT = "/common/engines";
export const INSTALLATIONS_TYPES_ENDPOINT = "/common/installations";
export const ALL_PLANS_ENDPOINT = "/maintenance/plan";
export const Vehicle_FORCEDDD_ENDPOINT = "/vehicles/vehicle/forceDDD";
export const VEHICLE_ASSIGN_ENDPOINT = "/vehicles/assign/toCustomer";
export const VEHICLE_UNASSIGN_ENDPOINT = "/vehicles/unassign?uidVehicles=";
export const TRACKING_FAULTS_ENDPOINT = "/fault/getFaultsByIdRaw/ofVehicle/:uidVehicle/ofIdRaw/:idRaw"
export const TRACKING_FIELDS_ENDPOINT = "/farm/ofCustomer/:uidCustomer/field"
export const TRACKING_MAP = "/tracking/map"
export const DEVICES_ACTIVATION_ENDPOINT = "/devices/ofCustomer/:uidCustomer/activating/:active"
export const STARTWEBCALL_ENDPOINT = "/webcall/startwebcall"
/**/

/*Patterns*/
export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_NUMBER_PATTERN = /[0-9]{1,20}/;
/**/

/* Icons */
export const FILE_EXCEL_ICON = faFileExcel;
export const REFRESH_ICON = faRefresh;
export const PLUS_ICON = faPlus;
export const CLONE_ICON = faClone;
export const TRASH_ICON = faTrash;
export const BACK_ICON = faBackward;
export const FLOPPY_DISK_ICON = faFloppyDisk;
export const RIGHT_LEFT_ICON = faRightLeft;
export const CHECK_ICON = faCheck;
export const PEN_ICON = faPen;
export const BAN_ICON = faBan;
export const CHECK_CIRCLE_ICON = faCheckCircle;
export const WARNING_ICON = faWarning;
export const SEARCH_ICON = faMagnifyingGlass;
export const CIRCLE_EXCLAMATION_ICON = faCircleExclamation;
export const EYE_SLASH_ICON = faEyeSlash;
export const EYE_ICON = faEye;
export const DOUBLE_ARROW_ICON = faArrowRightArrowLeft;
export const BOX_ICON = faBoxArchive;
export const USD_ICON = faUsd;
export const INFO_ICON = faCircleInfo;
export const USER_SHIELD = faUserShield;
export const SCREW_DRIVER_WRENCH = faScrewdriverWrench;

/* Emails */
export const LANDINI_EMAIL = "atfleetmanager@argotractors.com";
export const MCCORMICK_EMAIL = "atfleetmanager@argotractors.com";
export const ARGO_EMAIL = "atfleetmanager@argotractors.com";
export const AFT_EMAIL = "actia.support@actiaitalia.com";


/**/

export const ICON_STATE_MAPPER : any = {
    'administration': faCogs,
    'realtimeDriver': faRoad,
    'tracking': faLocationDot,
    'dashboardHistoricalLegalData': faLineChart,
    'dashboardHistoricalConsumption':faTint,
    'dashboardHistoricalFuelLevel':faGasPump,
    'farmbook' : faSeedling,
    'tacho': faTractor,
    'timecard': faCreditCard,
    'faults' : faExclamationTriangle,
    'vehicleMaintenance': faCalendarCheck,
    'dataUsage': faUserTie,
    'agrirouter': faArrowRightArrowLeft
};

export const ROUTER_STATE_MAPPER : any = {
    'maintenanceOperations': "/app/maintenance/operations",
    'maintenancePlans': "/app/maintenance/plans",
    'maintenanceCustomPlan': "/app/maintenance/maintenanceCustomPlan",    
    'maintenanceDeadlines': "/app/maintenance/deadlines",
    'dashboardHistoricalConsumption': "/app/fuel/dashboardHistoricalConsumption",
    'faults' : '/app/faults',
    'silentDevices': "/app/statistics/silentdevices",
    'alertManagement' : "/app/manage/alert",
    'users' : "/app/manage/user",
    'teams' : "/app/manage/team",
    'devices' : "/app/manage/device",
    'devcfg' : "/app/manage/deviceConfig",
    'fmwpckgs': "/app/manage/firmware",
    'fleets': "/app/manage/fleets",
    'drivers' : "/app/manage/drivers",
    'groups' : "/app/manage/groups",
    'customers' : "/app/manage/customers",
    'canmaps' : "/app/canmaps",
    'fields' : "/app/farmbook/fields",
    'activityAccounting' : "/app/farmbook/activityAccounting",
    'activityAccounting_old' : "/app/farmbook/activityAccountingOld",
    'activityScheduling' : "/app/farmbook/activityScheduling",
    'implements' :  "/app/manage/implements",
    'loginas': "/app/manage/loginas",
    'tracking' : "/app/tracking",
    'ar_registry' : "/app/agrirouter/registry",
    'ar_messages' : "/app/agrirouter/messages",
    'ar_status' : "/app/agrirouter/status",
    'vehicles': "/app/manage/vehicles",
    'ar_administration': "/app/agrirouter/administration",
    'vehiclemodels': "/app/manage/models"
};


export const LANGUAGES : any[] = [
    {key : "en", value:"English"},
    {key : "fr", value:"Français"},
    {key : "it", value:"Italiano"},
    {key : "de", value:"Deutsch"},
    {key : "es", value:"Español"},
    {key : "pt", value:"Português"}
];

export const DEADLINE_TYPES : any = [{name : "Once"}, {name : "Periodic"}];
