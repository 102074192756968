import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../core/services/utils.service';

@Component({
  selector: 'app-template-page',
  templateUrl: './template-page.component.html',
  styleUrls: ['./template-page.component.scss']
})
export class TemplatePageComponent implements OnInit {
  events: string[] = [];
  opened: boolean;
  public displayNav : boolean;
  showFiller = false;
  stylesheetString = "aft-theme";

  constructor(private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.stylesheetString = this.utilsService.getStylesheetString() + "-theme";
    this.displayNav = true;
  }
  
  toggleNav(){
    this.displayNav = !this.displayNav;
  }

}
