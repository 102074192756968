import { Injectable, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DxTreeViewComponent } from "devextreme-angular/ui/tree-view";
import * as moment from "moment";
import { ASSET_VEHICLES_OFCUSTOMER_ENDPOINT, DRIVERS_BY_CUSTOMER_ENDPOINT, GET_DEVICES_SEARCH, GET_DRIVERS_SEARCH, GET_VEHICLES_SEARCH, MYASSETS_DRIVERS_OFCUSTOMER, MYASSETS_FLEETS_OFCUSTOMER, MYASSETS_TEAMS_OFCUSTOMER, MYASSETS_VEHICLES_OFCUSTOMER } from 'src/app/core/config/config.constants';
import { FilterOptions } from "src/app/core/models/filterOptions";
import { ApiService } from "src/app/core/services/api.service";
import { DataService } from "src/app/core/services/data.service";
import { UtilsService } from "src/app/core/services/utils.service";
import { AuthService } from 'src/app/core/services/auth.service';
import { WarningModalComponent } from "../warning-modal/warning-modal.component";

export type AssetsModel = {
    customer: any,
    assets: {
        type: string,
        selected: any[]
    }
};
@Injectable({
    providedIn: 'root'
})
export class FilterService {

    mainColorClass = 'aft-main-bgcolor';
    firstTabOption: string;
    selectedType: any;
    constructor(private utilsService: UtilsService,
        private dataService: DataService,
        public translateService: TranslateService,
        private apiService: ApiService,
        private authService: AuthService,
        public dialog: MatDialog) {
        this.mainColorClass = this.utilsService.getStylesheetString() + '-main-bgcolor';
    }


    toggleFilter() {
        let filterBody = document.querySelector(".filter-body") as HTMLElement;
        if (this.isFilterOpen()) {
            this.closeFilter();
        } else {
            filterBody.style.display = "block";
            filterBody.classList.add("filter-opened");
            this.initCustomerTree();
            // this.initTimespanChooser(); 
            this.initTimespanChooserEvent.emit();
            this.initAssetsChooser().then();
        }
    }

    isFilterOpen(): boolean {
        return (document.querySelector(".filter-body") as HTMLElement).classList.contains("filter-opened");
    }

    closeFilter() {
        (document.querySelector(".filter-body") as HTMLElement).classList.remove("filter-opened");
    }

    hideFilter() {
        (document.querySelector(".filter-wrapper") as HTMLElement).classList.add("hidden");
    }

    removeHidden() {
        (document.querySelector(".filter-wrapper") as HTMLElement).classList.remove("hidden");
    }

    timespanSelection: any;
    fromDate: any;
    toDate: any;
    userIdentity: any;
    treeElementList: any[] = [];
    customerSelection: string;

    fromDtPicker: number;
    toDtPicker: number;

    currentSelectedAssets: AssetsModel;

    selectedCustomer: any;
    selectedDateCache: any;

    filterOptions: FilterOptions | null;
    consuntivazioneAttivita: boolean = false;

    initTimespanChooserEvent = new EventEmitter<any>();
    currentSetEvent = new EventEmitter<any>();
    assetsInitEvent = new EventEmitter<any>();
    assetsTabEvent = new EventEmitter<any>();
    onApplyWithoutSelection = new EventEmitter<any>();
    clearAssetsEvent = new EventEmitter<any>();


    applyFilterEvent = new EventEmitter<any>();
    filterChangeEvent = new EventEmitter<FilterOptions | null>();

    async initAssetsChooser() {
        let firstTab = "vehicle";
        this.selectedType = firstTab;
        let currentDataSource: any = this.getSelectedAssets();

        if (!currentDataSource || (currentDataSource && currentDataSource?.assets?.selected?.length == 0)) {
            let newData = await this.getAssetsDataSource(firstTab);
            currentDataSource = {
                customer: this.getSelectedCustomer(),
                assets: {
                    type: firstTab,
                    selected: newData
                }
            };

        };

        let selectedAssets: AssetsModel = currentDataSource;
        this.updateSelectedAssets(selectedAssets);
        this.updateSelectedAssetsCache();
        this.assetsInitEvent.emit();
    }

    async initFilter(options: FilterOptions | null) {
        if (options?.assets?.types[0]) {
            this.firstTabOption = options?.assets?.types[0];
        }
        this.removeHidden();
        if (options?.assets == null && options?.timespan == null && !this.authService.isFeatureGranted('customers', 'read'))
            this.hideFilter();
        this.setFilterOptions(options);
        this.initCustomerTree();
        this.initTimespanChooser();
        await this.initAssetsChooser();
        setTimeout(() => {
            this.filterChangeEvent.emit(options);
        }, 100);
    }

    initTimespanChooser() {
        this.selectedDateCache = localStorage.getItem('selectedDateCache') || {
            timespanSel: 'month',
            fromDtPicker: moment().valueOf(),
            toDtPicker: moment().valueOf()
        };

        if (typeof this.selectedDateCache === 'string') {
            this.selectedDateCache = JSON.parse(this.selectedDateCache);
        }

        var datecached: {
            timespanSel: string;
            fromDtPicker: number;
            toDtPicker: number;
        } = this.selectedDateCache;

        this.setTimespanSelection(datecached.timespanSel);
        this.setFromDtPicker(datecached.fromDtPicker);
        this.setToDtPicker(datecached.toDtPicker);

        this.calcToDate(this.getTimespanSelection());
        // this.initTimespanChooserEvent.emit();
    }

    handleCustomerClick(treeView: DxTreeViewComponent) {
        let itemsCollections = treeView.instance.element().getElementsByClassName('dx-treeview-item');
        for (let treeItem of Array.from(itemsCollections)) {
            treeItem.classList.remove(this.mainColorClass);
        }
        this.treeElementList.forEach((treeItem) => {
            if (treeItem.itemData.uid === this.getSelectedCustomer().uid)
                (treeItem.itemElement as HTMLElement).classList.add(this.mainColorClass);

        })
        this.clearAssetsEvent.emit(true);
        if (this.currentSelectedAssets) {
            this.currentSelectedAssets.assets.selected = [];
        }

        localStorage.setItem("selectedAssetsCache", JSON.stringify(this.currentSelectedAssets));
    }

    handleTreeExpansionAndCollision(treeView: DxTreeViewComponent) {
        let itemsCollections = treeView.instance.element().getElementsByClassName('dx-treeview-node');
        for (let treeItem of Array.from(itemsCollections)) {
            treeItem.classList.remove('dx-state-focused');
        }
    }

    setCustomerTreeItems(elemList) {
        this.treeElementList = elemList;
    }

    initCustomerTree() {
        let itemFromStorage = localStorage.getItem("selectedCustomerCache");
        if (itemFromStorage != null) {
            itemFromStorage = JSON.parse(itemFromStorage);
            this.setSelectedCustomer(itemFromStorage);
        } else {
            let userInfo = this.dataService.getCurrentUserInfo();
            this.setSelectedCustomer(userInfo.customer);
        }
        this.setCustomerTranslation();
        this.treeElementList.forEach((treeItem) => {
            if (treeItem.itemData?.uid === this.getSelectedCustomer()?.uid) {
                this.selectedCustomer.uid = treeItem.itemData?.uid;
                (treeItem.itemElement as HTMLElement).classList.add(this.mainColorClass);
            } else {
                (treeItem.itemElement as HTMLElement).classList.remove(this.mainColorClass);
            }
        })
    }

    setFilterOptions(filterOpts: FilterOptions | null) {
        this.filterOptions = filterOpts;
    }

    getCurrentFilterOptions() {
        return this.filterOptions;
    }

    setCustomerTranslation() {
        this.customerSelection = ": " + this.getSelectedCustomer()?.name;
    }

    getCustomerTranslation() {
        return this.customerSelection;
    }

    setSelectedCustomer(customer: any) {
        this.selectedCustomer = customer;
        this.setCustomerTranslation();
    }

    getSelectedCustomer() {
        if (this.selectedCustomer) {
            return this.selectedCustomer;
        } else {
            let customerFromStorage = localStorage.getItem("selectedCustomerCache");
            let customerParsed = JSON.parse(customerFromStorage!);
            //console.log('customerParsed',customerParsed);
            return customerParsed;
        }
    }

    async applyFilter(stepName?: string) {
        if (this.getCurrentFilterOptions()?.assets != null && this.getSelectedAssets()?.assets?.selected?.length == 0 && stepName == this.translateService.instant("global.assets")) {
            this.dialog.open(WarningModalComponent, {
                width: '500px',
                data: 'global.noSelection',
                position: { top: '40px' }
            });
            return;
        }
        if (this.getCurrentFilterOptions()?.assets != null && this.getSelectedAssets()?.assets?.selected?.length == 0) {
            let newData = await this.getAssetsDataSource(this.firstTabOption);
            let currentDataSource = {
                customer: this.getSelectedCustomer(),
                assets: {
                    type: this.firstTabOption,
                    selected: newData
                }
            };

            let selectedAssets: AssetsModel = currentDataSource;
            this.updateSelectedAssets(selectedAssets);
        } else {
            if (!this.getCurrentFilterOptions()!.assets && this.getSelectedAssets()?.assets?.selected?.length == 0) {
                // Un filtro senza l'asset-chooser ha cambiato customer -> seleziona di default tutti gli asset del nuovo customer
                this.onApplyWithoutSelection.emit();
            }
        }
        this.applyFilterEvent.emit();
        this.toggleFilter();
    }

    getAssetTypeForAPI(assetType: string) {
        switch (assetType) {
            case 'driver':
                return 'DRIVR';
            case 'teams':
                return 'TEAM';
            case 'fleets':
                return 'FLEET';
            default:
                return 'VEHIC';
        }
    }

    removeDuplicates(arr: any[]): any[] {
        return [...new Set(arr)];
    }

    getApiParams() {
        // Pluck uidAssets
        let selectedAssets = this.getSelectedAssets() != null ? this.getSelectedAssets().assets?.selected : [];
        let uidAssetsList: string[] = [];
        if (selectedAssets) {
            selectedAssets.forEach((asset) => {
                uidAssetsList.push(asset.uid);
            });
        }

        let uidAssets = this.removeDuplicates(uidAssetsList);

        // Get assetType
        let assetType = this.getAssetTypeForAPI(this.getSelectedAssets()?.assets?.type);
        return {
            "uidCustomer": this.getSelectedCustomer().uid,
            "initPeriod": this.getFromDate(),
            "endPeriod": this.getToDate(),
            "assetType": assetType,
            "uidAssets": uidAssets
        };
    }

    updateSelectedAssets(updatedObject: AssetsModel) {
        if (updatedObject?.assets?.selected) {
            this.currentSelectedAssets = updatedObject;
        }
    }

    getSelectedAssets() {
        let fromCache: any = localStorage.getItem('selectedAssetsCache');
        let assets: any;
        if (this.currentSelectedAssets && this.currentSelectedAssets?.assets.selected.length > 0) {
            if (!this.currentSelectedAssets?.assets) {
                assets = { assets: { selected: this.currentSelectedAssets } };
                assets.assets.type = this.selectedType;
            } else {
                assets = this.currentSelectedAssets;
            }
        } else if (fromCache && fromCache !== 'undefined') {
            assets = JSON.parse(fromCache);
        }
        return assets;
    }

    resetDtPicker() {
        this.setTimespanSelection('month');
        this.setFromDtPicker(moment().valueOf());
        this.setToDtPicker(moment().valueOf());
        this.calcToDate(this.getTimespanSelection());
    }

    async getAssetsDataSource(selectedType: string) {
        let apiResult: any[];
        switch (selectedType) {
            case 'driver': if (this.consuntivazioneAttivita) {
                apiResult = <any[]>await this.apiService.get(DRIVERS_BY_CUSTOMER_ENDPOINT + '/' + this.getSelectedCustomer().uid + '?alsoDisabled=false&withFullHistory=false').toPromise(); break;
            } else {
                apiResult = <any[]>await this.apiService.get(MYASSETS_DRIVERS_OFCUSTOMER + this.getSelectedCustomer().uid).toPromise(); break;
            }
            case 'teams': if (this.consuntivazioneAttivita) {
                apiResult = <any[]>await this.apiService.get(ASSET_VEHICLES_OFCUSTOMER_ENDPOINT + '/' + this.getSelectedCustomer().uid + '?withVehicles=false').toPromise(); break;
            } else {
                apiResult = <any[]>await this.apiService.get(MYASSETS_TEAMS_OFCUSTOMER + this.getSelectedCustomer().uid).toPromise(); break;
            }
            case 'vehicle': apiResult = <any[]>await this.apiService.get(MYASSETS_VEHICLES_OFCUSTOMER + this.getSelectedCustomer().uid).toPromise(); break;
            case 'fleets': apiResult = <any[]>await this.apiService.get(MYASSETS_FLEETS_OFCUSTOMER + this.getSelectedCustomer().uid).toPromise(); break;
            default: apiResult = <any[]>await this.apiService.get(MYASSETS_VEHICLES_OFCUSTOMER + this.getSelectedCustomer().uid).toPromise(); break;
        }
        return apiResult;
    }


    calcToDate(currentTimespan) {
        var span: {
            fromDate: number,
            toDate: number
        };
        switch (currentTimespan) {
            case 'timeperiod':
                span = this.utilsService.calcTimeperiod(this.getFromDtPicker(), this.getToDtPicker());
                this.setFromDate(span.fromDate);
                this.setToDate(span.toDate);
                break;
            case 'daily':
                span = this.utilsService.calcDaily(this.getFromDtPicker());
                this.setFromDate(span.fromDate);
                this.setToDate(span.toDate);
                break;
            case 'week':
                span = this.utilsService.calcWeek(this.getFromDtPicker());
                this.setFromDate(span.fromDate);
                this.setToDate(span.toDate);
                break;
            case 'last7days':
                span = this.utilsService.calcLast7Days();
                this.setFromDate(span.fromDate);
                this.setToDate(span.toDate);
                break;
            case 'month':
                span = this.utilsService.calcMonth(this.getFromDtPicker());
                this.setFromDate(span.fromDate);
                this.setToDate(span.toDate);
                break;
            default:
                break;
        }
    }


    setTimespanSelection(selection) {
        this.timespanSelection = selection;
    }
    setFromDate(date) {
        this.fromDate = date;
    }
    setToDate(date) {
        this.toDate = date;
    }
    setFromDtPicker(date) {
        this.fromDtPicker = date;
    }
    setToDtPicker(date) {
        this.toDtPicker = date;
    }

    getTimespanSelection() {
        return this.timespanSelection;
    }
    getFromDate() {
        return this.fromDate;
    }
    getToDate() {
        return this.toDate;
    }
    getFromDtPicker() {
        return this.fromDtPicker;
    }
    getToDtPicker() {
        return this.toDtPicker;
    }

    updateSelectedDateCache() {
        var timespanSel = this.getTimespanSelection();
        this.selectedDateCache = {
            timespanSel: timespanSel,
            fromDtPicker: this.fromDtPicker,
            toDtPicker: this.toDtPicker
        };

        localStorage.setItem("selectedDateCache", JSON.stringify(this.selectedDateCache));
    }

    updateSelectedCustomerCache() {
        let customer = this.getSelectedCustomer();
        localStorage.setItem("selectedCustomerCache", JSON.stringify(customer));
    }

    updateSelectedAssetsCache() {
        localStorage.setItem('selectedAssetsCache', JSON.stringify(this.getSelectedAssets()));
    }

    getSelectedDateCache() {
        return JSON.parse(localStorage.getItem("selectedDateCache")!);
    }

    async searchVehicles(searchPattern) {
        let vehiclesResult = <any[]>await this.apiService.get(GET_VEHICLES_SEARCH + "?searchPattern=" + searchPattern).toPromise();
        return vehiclesResult ?? [];
    }

    async searchDrivers(searchPattern) {
        let driversResult = <any[]>await this.apiService.get(GET_DRIVERS_SEARCH + "?searchPattern=" + searchPattern).toPromise();
        return driversResult ?? [];
    }

    async searchDevices(searchPattern) {
        let devicesResult = <any[]>await this.apiService.get(GET_DEVICES_SEARCH + "?searchPattern=" + searchPattern).toPromise();
        return devicesResult ?? [];
    }
}