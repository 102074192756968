<section [id]="idComponent">
<dx-data-grid
  id="grid"
  [dataSource]="dataTable"
  [keyExpr]="inkeyExpr"
  [columns]="dataCols"
  [showBorders]="showBorders"
  [columnAutoWidth]="true"
  [allowColumnReordering]="allowColumnReordering"
  [allowColumnResizing]="allowColumnResizing"
  [rowAlternationEnabled]="rowAlternationEnabled"
  [showRowLines]="showRowLines"
  [(selectedRowKeys)]="selectedRows"

>
<!-- template col -->
<div *dxTemplate="let data of 'dateTemplate'">
  {{ data.value | date:'shortDate' }}
</div>

<div *dxTemplate="let data of 'dateTimeTemplate'">
  {{ data.value | date:'M/d/yyyy H:mm:ss' }}
</div>


<!-- template col -->
<div *dxTemplate="let data of 'NATemplate'">
  <div *ngIf=" data.value == 'NA'; else elseBlock">
    <span class="text-orange-600">
      {{ data.value }}
    </span>
    </div>
<ng-template #elseBlock>
  {{ data.value }}
</ng-template>

</div>

<div *dxTemplate="let data of 'dataHiddenTemplate'" cellTemplate="dateTemplate">
  <fa-icon class="ckeck-icon" *ngIf="data.value" [icon]="faCheck"></fa-icon>
</div>

<dxo-selection [selectAllMode]="selectAllMode" [showCheckBoxesMode]="'onClick'" [mode]="selectMode">
</dxo-selection>

<!-- pager setting -->
<dxo-paging [pageSize]="pageSize"> </dxo-paging>
<dxo-pager [allowedPageSizes]="allowedPageSizes"
           [showPageSizeSelector]="showPageSizeSelector"
           [visible]="pagerVisibile"
           [displayMode]="displayMode"
           [showInfo]="showInfo"
           [showNavigationButtons]="showNavigationButtons">
</dxo-pager>
<dxo-export [enabled]="enabledExportButton" [allowExportSelectedData]="false"></dxo-export>
<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" [width]="searchWidth">
</dxo-search-panel>

</dx-data-grid>
</section>

