import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './contact-support-modal.component.html',
  styleUrls: ['./contact-support-modal.component.scss']
})
export class ContactSupportModalComponent implements OnInit {
  stylesheetString: string;
  email: string;

  constructor(public dialogRef: MatDialogRef<ContactSupportModalComponent>, @Inject(MAT_DIALOG_DATA) public data: string, 
  private utilService: UtilsService,
  private translateService: TranslateService) { }

  ngOnInit(): void {
    this.email = this.utilService.getMarkEmail();
    this.stylesheetString = this.utilService.getStylesheetString();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
