import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// import DevExpress from "devextreme";

import notify from 'devextreme/ui/notify';
import { ALERTS_BY_CUSTOMER_ENDPOINT } from "../config/config.constants";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { DataService } from "./data.service";
import { SignalrService } from "./signalr.service";
import { UtilsService } from "./utils.service";

@Injectable({
    providedIn: 'root'
})
export class AlertingService {

    constructor(private authService: AuthService, private dataService: DataService, private signalrService: SignalrService, private translateService: TranslateService, private apiService: ApiService, private utilsService: UtilsService) {
        this.identity = this.dataService.getCurrentUser();
        this.hasAlerting = this.authService.isFeatureGranted('alertNotification', 'read') ?? false;
        this.user = this.identity?.user;
    }
    identity: any;
    hasAlerting: boolean;
    user: any;
    messageQueue: any[] = [];
    isDisplayingNotification = false;

    alertingSignalRConfig = {
        startNegotiateNamespace: 'tracking/startLiveMap',
        hubNamespace: 'AlertingNotification',
        onmessage: [
            {
                topic: 'PushNotification',
                callback: this.toastCallback.bind(this)
            },
            {
                topic: 'PushUnreadCount',
                callback: this.updateUnreadCount.bind(this)
            }
        ],
        onstart: this.alertingHubOnStart.bind(this),
        ondisconnected: this.alertingHubOnDisconnected.bind(this)
    };

    toasts = [];



    private alertNotReads = 0;

    setAlertNotReads(updatedCount: number) {
        this.alertNotReads = updatedCount;
    }


    getAlertNotReads() {
        return this.alertNotReads;
    }

    alertingConnect() {
        if (this.hasAlerting) {
            if (!this.identity?.user?.isDemo) {
                this.signalrService.connect(this.alertingSignalRConfig);
            }
            else {
                this.generateDemoAlert();
            }
        }
    }

    toastCallback(message) {
        //var jsData = JSON.parse(message);
        console.log('-----------inserisco il messaggio in coda------------');
        this.messageQueue.push(message);
        console.log(this.messageQueue)
        this.showToast();
    }

    updateUnreadCount(message) {
        this.setAlertNotReads(message.Count);
    }



    alertingHubOnStart() {

        var filters = {
            initPeriod: 0,
            endPeriod: 0,
            assetType: 'USER',
            uidAssets: [this.user.uid],
            uidCustomer: this.user.uidCustomer
        };

        this.signalrService.invoke(this.alertingSignalRConfig.hubNamespace, 'setFilter', filters);

    }

    alertingHubOnDisconnected(e) { }



    showToast(data?) {
        if (!this.isDisplayingNotification && this.messageQueue.length > 0) {
            this.isDisplayingNotification = true;

            console.log('------------tolgo ed elaboro il messaggio dalla coda.-----')
            let data = this.messageQueue.shift();
            console.log(this.messageQueue);
            var message = this.translateService.instant('global.alertMessageTemplate');
            message = message.replace('{eventType}', data.EventType);
            switch (data.AssetInfo.AssetType) {
                case 'VEHIC':
                    message = message.replace('{assetType}', this.translateService.instant('global.vehicle'));
                    break;
            }

            message = message.replace('{asset}', data.AssetInfo.InternalID);
            this.notify(message);
        }
    }

    toastOnClick(e) {
        if (!this.user.isDemo)
            this.markAlertAsRead(e.data.uid);
        else
            this.setAlertNotReads(this.getAlertNotReads() - 1);
    }

    forzaInvio() {
        let a = 0;
        for (a = 0; a < 1; a++) {
            let j = {
                "Id": "653777879c5e673ecd4278a0",
                "AlertId": 4070999,
                "EventType": "GeoFencing",
                "AssetInfo": {
                    "AssetType": "VEHIC",
                    "InternalID": "ABINSULA_TEST_001 " + a,
                    "VIN": "ABINSULA_TEST_001",
                    "Plate": "unknown"
                }
            }
            this.messageQueue.push(j);
            this.showToast();
        }
    }

    notify(msg) {
        notify(
            {
                message: msg,
                width: 450,
                position: {
                    my: 'bottom right',
                    at: 'bottom right',
                    of: window,
                    offset: '0 -50',
                    collision: 'none',
                    direction: 'down-stack'
                },
                animation: {
                    hide: {
                        type: 'fade',
                        duration: 40,
                        to: 0,
                        complete: () => {
                            this.isDisplayingNotification = false;
                            this.showToast();
                        }
                    },
                },
            },
            'warning',
            15000
        );
    }

    markAlertAsRead(uid) {
        var filters = {
            uidCustomer: this.user.uidCustomer,
            uids: [uid]
        };

        this.apiService.put(ALERTS_BY_CUSTOMER_ENDPOINT + `/${this.user.uidCustomer}` + "/markAlertAsRead", filters.uids)
            .subscribe({
                next: () => {
                    this.signalrService.invoke(this.alertingSignalRConfig.hubNamespace, 'MarkAlertAsRead', {});
                },
                error: (error) => {
                    this.utilsService.showErrorPopup(error);
                }
            });
    }

    generateDemoAlert() {
        var timeoutms = Math.floor(Math.random() * 60 + 1);
        var jsData = {
            Id: 'timeoutms',
            alertSeenByUser: false,
            alertId: this.alertNotReads + 1,
            eventTimestamp: Date.now(),
            EventType: 'GeoFencing',
            eventType: 'GeoFencing',
            assetId: '0XXYY11KABC000_' + timeoutms,
            AssetInfo: { AssetType: 'VEHIC', InternalID: 'INTERNAL_ID_00' + timeoutms },
            AssetType: 'VEHIC',
            internalId: 'INTERNAL_ID_00' + timeoutms,
            ruleName: 'Test'
        };

        setTimeout(() => {
            this.setAlertNotReads(this.getAlertNotReads() + 1);
            this.showToast(jsData);
            this.generateDemoAlert();
        }, timeoutms * 1000);

        /*  $timeout(function () {
             //Generate toast
             ++alertNotReads;

             $sessionStorage.alerts.push(jsData);

             showToast(jsData);

             generateDemoAlert();
         }, timeoutms * 1000); */
    }


}