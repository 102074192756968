<div class="card">
  <div class="card-header d-flex">
    <h4 class="f_3">{{searchAssetString}}</h4>
    <a (click)="close()" class="btn btn-square-xs btn-default btn-xs pull-right btn-closeassets"
    pTooltip="{{'global.close' | translate  }}" tooltipPosition="left">

      <i class="fa-solid fa-xmark"></i>
    </a>
  </div>
  <div class="card-body">

    <dx-validation-group #searchSection>
      <div class="search-wrapper">
        <dx-text-box class="search-box" #searchBox value="" [showClearButton]="true">
          <dx-validator>
            <dxi-validation-rule type="custom" [validationCallback]="validateNumber" message="">
            </dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
        <a (click)="onSearch($event)" class="btn btn-square-xs btn-default btn-xs ms-3"
        pTooltip="{{'global.searchAsset' | translate  }}" tooltipPosition="left">

          <fa-icon [icon]="faSearch"></fa-icon>
        </a>
      </div>
    </dx-validation-group>

    <div class="mb-3">
      <dx-radio-group [items]="radioGroupContent" [value]="radioGroupContent[0]" layout="horizontal"
        (onValueChanged)="radioGroupValueChanged($event)">
      </dx-radio-group>
    </div>
    <div class="result-wrapper">
      <section id="searchAssetCustomerList">
        <div class="list-header">
          <strong translate="global.customers"></strong>
        </div>
        <dx-list selectionMode="single" [keyExpr]="'uid'" [selectedItemKeys]="selectedCustomer" [scrollByContent]="true"
          showScrollbar="always" height="300px" (onItemClick)="customerChanged($event)" [dataSource]="customerList"
          [displayExpr]="'name'" [noDataText]="translateService.instant('global.noDataText')">
        </dx-list>
      </section>
      <section id="searchAssetResultList">
        <div *ngIf="resultSectionToggler == 'drivers'" #searchAssetDriversList>
          <div class="list-header">
            <strong translate="global.drivers"></strong>
          </div>
          <dx-list [hoverStateEnabled]="false" [activeStateEnabled]="false" [focusStateEnabled]="false"
            [dataSource]="driversList" [displayExpr]="'vin'" [scrollingEnabled]="true" [scrollByContent]="true"
            showScrollbar="always" height="300px" itemTemplate="drivers-list-item"
            [noDataText]="translateService.instant('global.noDataText')">
            <div *dxTemplate="let driver of 'drivers-list-item'; let index = index">
              <div class="list-result-card">
                <strong class="pt-2 d-block">{{ driver.fullName }}</strong>
                <span class="py-2 d-block">{{ translateService.instant("global.internalId") }}:
                  {{ driver.internalId }}</span>
              </div>
              <div class="py-2 list-result-footer">
                <span>{{ translateService.instant("global.cardId") }}: {{ driver.cardId }}</span>
              </div>
            </div>
          </dx-list>
        </div>
        <div *ngIf="resultSectionToggler == 'vehicles'" #searchAssetVehiclesList>
          <div class="list-header">
            <strong translate="global.vehicles"></strong>
          </div>
          <dx-list [hoverStateEnabled]="false" [activeStateEnabled]="false" [focusStateEnabled]="false"
            [dataSource]="vehiclesList" [displayExpr]="'vin'" [scrollingEnabled]="true" [scrollByContent]="true"
            showScrollbar="always" height="300px" itemTemplate="vehicles-list-item"
            [noDataText]="translateService.instant('global.noDataText')">
            <div *dxTemplate="let vehicle of 'vehicles-list-item'; let index = index">
              <div class="list-result-card">
                <strong class="pt-2 d-block">{{ vehicle.plate }}</strong>
                <span class="py-2 d-block">{{ translateService.instant("global.internalId") }}:
                  {{ vehicle.internalId }}</span>
              </div>
              <div class="py-2 list-result-footer">
                <span>{{ translateService.instant("global.vehicleVIN") }}: {{ vehicle.vin }}</span>
              </div>
            </div>
          </dx-list>
        </div>
        <div *ngIf="resultSectionToggler == 'devices'" #searchAssetDevicesList>
          <div class="list-header">
            <strong translate="global.devices"></strong>
          </div>
          <dx-list [hoverStateEnabled]="false" [activeStateEnabled]="false" [focusStateEnabled]="false"
            [dataSource]="devicesList" [displayExpr]="'vin'" [scrollingEnabled]="true" [scrollByContent]="true"
            showScrollbar="always" height="300px" itemTemplate="devices-list-item"
            [noDataText]="translateService.instant('global.noDataText')">
            <div *dxTemplate="let device of 'devices-list-item'; let index = index">
              <div class="list-result-card">
                <strong class="pt-2 d-block">{{ device.modelName }}</strong>
                <span class="pt-2 d-block">{{ translateService.instant("global.landlord") }}:
                  {{ device.landlordName }}</span>
                <span class="py-2 d-block">{{ translateService.instant("global.vehicleVIN") }}: {{ device.vin }}</span>
              </div>
              <div class="py-2 list-result-footer">
                <span>{{ translateService.instant("global.serialNumber") }}: {{ device.serialNumber }}</span>
              </div>
            </div>
          </dx-list>
        </div>
      </section>
    </div>
  </div>
</div>
