/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable sort-imports */
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  faClose,
  faListSquares,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { locale } from 'devextreme/localization';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { UtilsService } from 'src/app/core/services/utils.service';
import { VehicleService } from 'src/app/pages/management/vehicle/vehicle.service';

@Component({
  selector: 'app-miscellaneous-modal',
  templateUrl: './miscellaneous-modal.component.html',
  styleUrls: ['./miscellaneous-modal.component.scss']
})
export class MiscellaneousModalComponent implements OnInit {
  constructor(
    private utilsService: UtilsService,
    private cdr: ChangeDetectorRef,
    public vehicleSer: VehicleService,
    private translate: TranslateService
  ) {}
  faTrash = faTrash;
  faList = faListSquares;

  faClose = faClose;
  jsoneditorOptions = new JsonEditorOptions();

  showJsonEditor: boolean = false;
  showDataTable: boolean = false;
  private _dataMiscellanues: any;
  private _seletedVehicle: any;
  private _miscellaneousType: string = '';

  scrollByContent = true;

  scrollByThumb = true;
  pageSize = 10;
  allowedPageSizes = [10, 20, 40];
  scrollbarMode: string;
  pullDown = false;
  jsMode = 'form';
  btnShowJson = 'Json';
  stylesheetString = 'aft';
  arrKeyMIsc = 'miscellaneous';

  dataCols: any = [];

  fromDateMin;
  toDateMax;
  fromDateValue;
  toDateValue;

  dataTableFromComponent: any = [];

  inkeyExpr: any;
  tableRefreshInLineGrid: Subject<void> = new Subject<void>();
  paramsMiscellaneus: any | undefined;

  onDataLoadMsg: string;
  dateTimeFormat: string;

  @Input() windowW: string = '50vw';
  @Input() windowH: string = '50vh';
  @Input() vinSelect: string;

  public set dataMiscellanues(value: any) {
    if (value && value.length > 0) {
      this._dataMiscellanues = value;
      if (this.isJsonString(this._dataMiscellanues)) this.createDataSet();
      // console.log(this._dataMiscellanues);
    }
  }

  public get dataMiscellanues() {
    return this._dataMiscellanues;
  }

  @Input()
  public set miscellaneousType(value: any) {
    this._miscellaneousType = value;
    if (this._seletedVehicle && this._seletedVehicle.length === 1) {
      this.loadMicellaneusData();
    }
  }

  public get miscellaneousType() {
    return this._miscellaneousType;
  }

  @Input()
  public set seletedVehicle(value: any) {
    if (value) {
      this._seletedVehicle = value;
      //console.log(this._seletedVehicle);
      //FZ per ora gestiamo solo un VIN quindi controllo
      if (this._seletedVehicle.length === 1) {
        this.loadMicellaneusData();
      }
    }
  }

  public get seletedVehicle() {
    return this._seletedVehicle;
  }

  ngOnInit(): void {
    this.btnShowJson = 'Json';
    this.stylesheetString = this.utilsService.getStylesheetString();
    let language: any;
    if (localStorage.getItem('language') != null) {
      language = localStorage.getItem('language');
    } else language = 'en';
    locale(language);
    this.dateTimeFormat = this.translate.instant('global.dateTimeFormat');
    //this.fromDateValue = this.fromDateMin;
    //this.toDateValue = this.toDateMax;
    // this.loadMicellaneusData();
  }

  loadMicellaneusData() {
    this.dataTableFromComponent = [];
    this.dataCols = [];
    const dateNow = new Date();
    const dateMinusOneDay = new Date();
    dateMinusOneDay.setDate(dateMinusOneDay.getDate() - 1);
    this.toDateMax = dateNow;
    if (!this.fromDateValue) {
      this.fromDateValue = dateNow.getTime() - 60000 * 30;
    }
    if (!this.toDateValue) {
      this.toDateValue = dateNow;
    }

    //console.log(moment(this.fromDateValue).format());
    //FZ converto data in epoca #FZepoca
    this.paramsMiscellaneus = {
      initPeriod: moment.utc(this.fromDateValue).unix() * 1000,
      endPeriod: moment.utc(this.toDateValue).unix() * 1000,
      uidAssets: [this.seletedVehicle[0].uid]
    };
    this.vehicleSer
      .getMiscellaneusData$(this.paramsMiscellaneus, this.miscellaneousType)
      .subscribe((data) => {
        this.dataMiscellanues = [];
        if (data.length > 0) {
          this.dataMiscellanues = data;
        } else {
          this.onDataLoadMsg = this.translate.instant(
            'global.error.functionalCodes.NO_DATA_FOUND'
          );
        }
      });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges() {
    this.btnShowJson = 'Json';
    this.showJsonEditor = false;
    this.fromDateValue = this.fromDateMin;
    this.toDateValue = this.toDateMax;

    const dateNow = new Date();
    const dateMinusOneDay = new Date();
    // console.log("Today: " + dateNow);
    //dateNow.setDate(dateNow.getDate() + 1);
    //console.log("Add 1 day: " + dateNow);
    dateMinusOneDay.setDate(dateMinusOneDay.getDate() - 1);
    //console.log("Minus 1 day: " + dateMinusOneDay);
    this.toDateMax = dateNow;
    //this.fromDateMin = dateMinusOneDay
    this.toDateValue = dateNow;
    this.fromDateValue = dateNow.getTime() - 60000 * 30;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit(): void {
    // Additional styling for custom wizard toolbar & body

    if (this.stylesheetString != 'aft')
      (document.querySelector('#ngwizard') as HTMLElement).classList.add(
        this.stylesheetString
      );
  }

  onJsonSwitch() {
    if (this.showJsonEditor) this.btnShowJson = 'Json';
    else this.btnShowJson = this.translate.instant('global.table');

    this.showJsonEditor = !this.showJsonEditor;
  }

  onChangeJsonEditorData(event: any) {
    //console.log(event);
  }

  onArraySelectedRows(value: any) {
    // console.log('onRowSelectedKey', value);
  }

  getGridElement(event: any) {
    //console.log(event);
    //event.instance.exportToExcel(true)
  }

  createDataSet() {
    let appArrayOfKey: any = [];
    let arrayDataComplete: any = [];
    let somethingWrongOnData: boolean = false;
    // ciclo sui dati per estrarre le key
    console.log(this.dataMiscellanues);
    for (let k in this.dataMiscellanues) {
      try {
        let row = this.dataMiscellanues[k][this.arrKeyMIsc];

        appArrayOfKey.push(Object.keys(row));
      } catch (e) {
        somethingWrongOnData = true;
        console.log(e);
        console.log('Data:', this.dataMiscellanues);
        this.onDataLoadMsg = this.translate.instant(
          'global.error.functionalCodes.ERROR_JSON_FORMAT'
        );
      }
    }
    if (!somethingWrongOnData) {
      this.dataCols = [];
      // concateno tutte le chiavi per poi filtrarle in maniera univoca
      var concat_array = [];
      for (let i = 0; i < appArrayOfKey.length; i++) {
        concat_array = concat_array.concat(appArrayOfKey[i]);
      }
      //console.log(concat_array)
      // var concat_array = appArrayOfKey[0].concat(appArrayOfKey[1]);
      //console.log(concat_array)

      //  filtre tutte le chiavi e crea un arry di chiavi unicoche
      var set_ob = new Set(concat_array);

      // creo array per colonne tabelle
      //aggiungo prima colonna timestamp
      this.dataCols.push({
        dataField: 'timestamp',
        caption: 'timestamp',
        cellTemplate: 'dateTimeTemplate'
      });
      for (var keyElem of set_ob) {
        this.dataCols.push({
          dataField: keyElem,
          caption: keyElem,
          cellTemplate: 'NATemplate'
        });
        //console.log(keyElem);
      }

      // ciclo sui dati per creare dataset in base alle colonne
      // creo un stringa per ogni riga che poi trasformo in Json
      for (let k in this.dataMiscellanues) {
        let timestampRow = this.dataMiscellanues[k].timestamp;
        let row = this.dataMiscellanues[k][this.arrKeyMIsc];
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(timestampRow);

        let string_fin: string = '{"timestamp":"' + d + '",';
        let appFinValue: string;
        for (var keyElem of set_ob) {
          let cellValue;
          if (row[keyElem] || row[keyElem] == 0) cellValue = row[keyElem];
          else cellValue = 'NA';
          string_fin += '"' + keyElem + '":"' + cellValue + '",';
        }
        //tolgo ultimo carattere "," la virgola
        appFinValue = string_fin.slice(0, -1);
        // aggiung parentesi graffa per chiudere la json
        appFinValue += '}';
        //  console.log(string_fin)
        // parsifico la json e la carico sull'array
        const obj = JSON.parse(appFinValue);
        arrayDataComplete.push(obj);
      }
      // passo i dati alla tabella
      this.dataTableFromComponent = arrayDataComplete;
      console.log(this.dataTableFromComponent);
      console.log(this.dataCols);
      this.showDataTable = true;
      this.cdr.detectChanges();
    }
  }

  applyFilter() {
    this.loadMicellaneusData();
  }

  isJsonString(str) {
    try {
      JSON.stringify(str);
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.log(e);
      return false;
    }
    return true;
  }
}
