import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from './core/services/data.service';
import { UtilsService } from './core/services/utils.service';

declare const gtag: Function;
export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = '4Agri';

  constructor(
    private translate: TranslateService,
    private dataService: DataService,
    private router: Router,
    private utilsService: UtilsService) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'G-WVH03M6EEK', { 'page_path': event.urlAfterRedirects });
        }
      })
  }

  ngOnInit() {
    this.translate.setDefaultLang(this.dataService.getLanguage());
    this.utilsService.setHostName(location.host);
    this.setFavicon();
    this.setTitle();
    // this.router.events.subscribe(event => this.closeFilterOnNavigation(event));
  }

  setFavicon() {
    var headID = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    var hostname = location.host;
    //to debug
    //hostname = 'test.mccormickfleet.argotractors.com';
    if (hostname.indexOf('argofleet.argotractors.com') !== -1)
      hostname = 'argofleet.argotractors.com';
    if (hostname.indexOf('mccormickfleet.argotractors.com') !== -1)
      hostname = 'mccormickfleet.argotractors.com';
    if (hostname.indexOf('landinifleet.argotractors.com') !== -1)
      hostname = 'landinifleet.argotractors.com';
    link.href = '../assets/styles/customers/' + hostname + "/images/favicon.png";
    headID.appendChild(link);
  }

  getTitleString() {
    let host = location.host;
    // to debug
    //host = 'test.mccormickfleet.argotractors.com';
    if (host.indexOf('argofleet.argotractors.com') !== -1)
      return 'Argo Fleet';
    else if (host.indexOf('mccormickfleet.argotractors.com') !== -1)
      return 'McCormick Fleet';
    else if (host.indexOf('landinifleet.argotractors.com') !== -1)
      return 'Landini Fleet';
    else
      return 'ACTIAFleetAgriculture';
  }

  setTitle() {
    let titleString = this.getTitleString();
    let headID = document.getElementsByTagName('head')[0];
    let title = document.createElement('title');
    title.textContent = titleString;
    headID.appendChild(title);
  }

  closeFilterOnNavigation(event) {
    if (event instanceof NavigationEnd) {
      if (!event.url.includes("/auth")) {

      }
    }
  }
}
