<div  #filterRef class="filter-wrapper" id="filtro">
  <section class="flex flex-row  items-center filter-header"  *ngIf="showFilter$ | async">
    <div >
      <strong><i class="fa-solid fa-filter ms-1"></i></strong>
    </div>
    <div>
      <div  class="me-2 mr-l filterTitle font-bold" #filterHead >

          <span class="ml-2" (click)="openPanel($event,'CUSTOMER')" >{{"global.customer" | translate}} : {{ selectedCustomerName$ | async }}</span>


        <span *ngIf="showFilterAssets$ |async">
          <span class="pr-2 pl-2 pb-0 m-0">|</span>
          <!-- <a class="underline decoration-sky-600  font-bold text-sm m-0 text-black cursor-pointer" #showFilterAssetsA
            pTooltip="{{'global.filter' | translate }}" tooltipPosition="top"
            *ngIf="titDefaultAssetype === 'VEHIC'" (click)="openPanel($event,'VEHIC')" appTracking componentName="showFilterAssetsA" trackingValue="{{selectedStoreCustomer |json}}"> -->
            <a class="underline decoration-sky-600  font-bold text-sm m-0 text-black cursor-pointer" #showFilterAssetsA
            pTooltip="{{'global.filter' | translate }}" tooltipPosition="top"
            *ngIf="titDefaultAssetype === 'VEHIC'" (click)="openPanel($event,'VEHIC')" >
            <i class="fa fa-tractor"></i>

            <span *ngIf="assetTitleDesc != '' ; else elseBlockVehic">
              <span
              class="underline decoration-sky-600  italic  text-sm m-0 text-black cursor-pointer">( {{assetTitleDesc}}  )</span></span>
              <ng-template #elseBlockVehic>
                <span *ngIf="totAssetsApply == (totVechiles$ | async) && (totVechiles$ | async)  != 0 "
                class="underline decoration-sky-600  font-bold text-sm m-0 text-black cursor-pointer"> ( {{"global.all" | translate}} )</span>
              <span *ngIf="totAssetsApply != (totVechiles$ | async)"
                class="underline decoration-sky-600  font-bold text-sm m-0 text-black cursor-pointer"> ( {{totAssetsApply}} )</span>
             </ng-template>




          </a>

          <a class="underline decoration-sky-600  font-bold text-sm m-0 text-black cursor-pointer"
          pTooltip="{{'global.filter' | translate }}" tooltipPosition="top"
           *ngIf="titDefaultAssetype === 'DRIVR'" (click)="openPanel($event,'DRIVR')">
          <i class="fa fa-user"></i>
          <span *ngIf="assetTitleDesc != '' ; else elseBlockDriver">
            <span
            class="underline decoration-sky-600  italic  text-sm m-0 text-black cursor-pointer"> ( {{assetTitleDesc}}  )</span></span>
            <ng-template #elseBlockDriver>
              <span *ngIf="totAssetsApply == (totDrivers$ | async) &&  (totDrivers$ | async) != 0"
              class="underline decoration-sky-600  font-bold text-sm m-0 text-black cursor-pointer"> ( {{"global.all" | translate}} )</span>
            <span *ngIf="totAssetsApply != (totDrivers$ | async)"
              class="underline decoration-sky-600  font-bold text-sm m-0 text-black cursor-pointer"> ( {{totAssetsApply}} )</span>
            </ng-template>

        </a>
           <!-- <i class="fa-solid fa-filter ms-1 pr-2"></i> -->
           <!-- <i *ngIf="titDefaultAssetype === 'VEHIC'" class="fa fa-truck">
             <span  *ngIf="totAssetsApply == (totVechiles$ | async)">
              <span class="underline decoration-sky-600  font-bold text-sm m-0 text-black cursor-pointer">( {{"global.all" | translate}} )</span>
            </span>
             <span  *ngIf="totAssetsApply != (totVechiles$ | async)">
              <span class="underline decoration-sky-600  font-bold text-sm m-0 text-black cursor-pointer">( {{totAssetsApply}} )</span>
             </span>

            </i> -->
           <!-- <i *ngIf="titDefaultAssetype === 'DRIVR'" class="fa fa-user">
            <span   *ngIf="totAssetsApply == (totDrivers$ | async)">( {{"global.all" | translate}} )</span>
            <span   *ngIf="totAssetsApply != (totDrivers$ | async)"> ( {{totAssetsApply}} ) </span>
          </i> -->

           <!-- <i class="fa-solid fa-filter ms-1 pr-2"></i>{{titleFilter}} : {{titleCountAssets}} -->
         <!-- </a> -->
        </span>

      </div>

    </div>
    <div *ngIf="isTimespanVisible"  (click)="openPanel($event,'TIMESPAN')">

      <strong> - <i class="fa-solid fa-calendar ms-1"></i></strong>
      <!-- <span class="mx-1">{{ translateService.instant('global.timeperiod') }}:</span> -->
      <!-- <strong [translate]="'global.' + timespanObject.timespan"></strong> -->
      <span class="mx-1 font-bold">{{ translateService.instant('global.from') }}:</span>
      <a class="underline decoration-cyan-600  italic  text-sm m-0 text-black cursor-pointer" *ngIf="timeSpanSelected != 'dateTimePeriod'">{{ fromDtPicker$  | date: dateFormat:userUtc }}</a>
      <a class="underline decoration-cyan-600  italic  text-sm m-0 text-black cursor-pointer" *ngIf="timeSpanSelected == 'dateTimePeriod'">{{ fromDtPicker$  | date: dateTimeFormat:userUtc }}</a>
      <span class="mx-1 font-bold">{{ translateService.instant('global.to') }}:</span>
      <a  class="underline decoration-cyan-500  italic  text-sm m-0 text-black cursor-pointer"  *ngIf="timeSpanSelected != 'dateTimePeriod'">{{ toDtPicker$  | date: dateFormat:userUtc }}</a>
      <a  class="underline decoration-cyan-500  italic  text-sm m-0 text-black cursor-pointer"  *ngIf="timeSpanSelected == 'dateTimePeriod'">{{ toDtPicker$  | date: dateTimeFormat:userUtc }}</a>
    </div>

  </section>
  <p-overlayPanel #opFilterRef [showTransitionOptions]="'.12s cubic-bezier(0, 0, 0.2, 1)'" (onShow)="onPanelOpen($event)"
    [hideTransitionOptions]="'.1s linear'" [dismissable]="false" [showCloseIcon]="true" (onHide) ="onHidePanel()" [appendTo]="filterRef"   >
    <ng-template pTemplate>
      <div class="filter-recap">

        <button id="applyBtn" [ngClass]="stylesheetString" (click)="applyFilter()" class="btn btn-sm"
          translate="global.applyFilter" [disabled]="(isLoadingVehicles$ | async) || (isLoadingDrivers$ |async)"></button>
          <!-- <a class="underline decoration-sky-500  font-bold text-sm m-0 text-black cursor-pointer" (click)="onShowAssets()"

            pTooltip="{{'global.filter' | translate }}" tooltipPosition="left">

            <i *ngIf="titleAssetType === 'VEHIC'" class="fa fa-truck"></i>
            <i *ngIf="titleAssetType === 'DRIVR'" class="fa fa-user"></i>
             ( {{titleCountAssets}} )

          </a> -->
          <div class="flex  items-center p-2" *ngIf="includeChildrens$ | async"
          pTooltip="{{'global.includeChildrensData' | translate }}" tooltipPosition="top">
            <div >
              <dx-check-box [(value)]="includeChildrenValue" width="100%"
              (onValueChanged)="setIncludeChildrenValue($event)" > </dx-check-box>
            </div>
            <div class="pl-2">
              <i class="fa-solid fa-folder-tree" ></i>
            </div>

          </div>


      </div>
      <div id="longtabs" >
        <dx-tabs [dataSource]="longtabs" [ngClass]="stylesheetString" [selectedIndex]="choosedTab" (onItemClick)="selectTab($event)"
          style="width: 420px;">
        </dx-tabs>

        <div class="tabs-panel" >
          <div *ngIf="choosedTab == 0" class="p-1 pt-2" style="height: 80vh !important;"  >


            <!-- <dx-scroll-view #scrollviewCustomTree  [scrollByContent]="scrollByContent"
              [scrollByThumb]="scrollByThumb" [showScrollbar]="scrollbarMode" [bounceEnabled]="true"
              eachBottomText="Updating..." [height]="windowHCustomers"> -->
              <app-cutomers-tree-ref *ngIf="!(isLoading$ |async)" (customerSelected)="onTreeItemClick($event)" [isForFilter]="true"
              [listIn]="posts$ |async" ></app-cutomers-tree-ref>
            <!-- </dx-scroll-view> -->
          </div>
          <div *ngIf="choosedTab == 1"  class="p-0 " >

            <!-- <dx-scroll-view #scrollviewAssestTree  [scrollByContent]="scrollByContent"
              [scrollByThumb]="scrollByThumb" [showScrollbar]="scrollbarMode"
              eachBottomText="Updating..." style="height: 70vh !important;"> -->
                   <!-- <div *ngFor="let vechile of vechicleOfCustomer$ |async; let i = index">
                      <p>
                        {{i}} - {{vechile['plate']}}
                      </p>

                  </div> -->
                  <p-divider  align="center" >
                    <div class="inline-flex align-items-center ">
                      <i class="pi pi-list  text-sm"></i>

                  </div>
                  </p-divider>
                  <app-assets-chooser-ref></app-assets-chooser-ref>


            <!-- </dx-scroll-view> -->
          </div>
          <div *ngIf="choosedTab == 2" class="p-1" >

            <!-- <dx-scroll-view #scrollviewTimeSpan  [scrollByContent]="scrollByContent"
              [scrollByThumb]="scrollByThumb" [showScrollbar]="scrollbarMode" [bounceEnabled]="false"
              eachBottomText="Updating..." style="height: 30vh !important;"> -->
              <app-timespan-chooser-ref></app-timespan-chooser-ref>
            <!-- </dx-scroll-view> -->
          </div>
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>
</div>
