 <dx-tab-panel id="assetsTab" #tabPanel [width]="500" [dataSource]="enabledTabPanelItems" [selectedIndex]="currentIndex" [loop]="true"
  [animationEnabled]="false" [swipeEnabled]="false" (onTitleClick)="onTabChanged($event)" [scrollingEnabled]="true">
  <div *dxTemplate="let enabledTabPanelItems of 'title'">
    <span>{{ enabledTabPanelItems.translatedType }}
      <i *ngIf="enabledTabPanelItems?.type === 'driver' || enabledTabPanelItems?.type === 'teams'" [ngClass]="enabledTabPanelItems.icon"></i>
      <fa-icon *ngIf="enabledTabPanelItems?.type == 'fleets' || enabledTabPanelItems?.type == 'vehicle'" [icon]="enabledTabPanelItems.icon"></fa-icon>
    </span>
 
  </div>
  <div *dxTemplate="let enabledTabPanelItems of 'item'">
    <div>
      <dx-list [(selectedItems)]="selectedAssets" style="display: block !important" #list [dataSource]="currentDataSource" selectionMode="all" selectAllMode="allPages" [selectAllText]="translateService.instant('global.selectAll')"
      [showSelectionControls]="true" [nextButtonText]="translateService.instant('global.showMore')" [searchEnabled]="true" searchMode="contains" [noDataText]="translateService.instant('global.noDataText')"
      [searchExpr]="['internalId','name']" (onSelectionChanged)="onCheckboxToggle($event)" (onContentReady)="onContentReady($event)">
    </dx-list>
  </div>
  </div>


</dx-tab-panel> 


