import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-associated-vehicles-modal',
  templateUrl: './associated-vehicles-modal.component.html',
  styleUrls: ['./associated-vehicles-modal.component.scss']
})
export class AssociatedVehiclesModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AssociatedVehiclesModalComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {}



  ngOnInit(): void {
    console.log(this.data)
  }

  close(): void {
    this.dialogRef.close();
  }

  save(){
    this.dialogRef.close(true);
  }

}
