import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MAINTENANCE_EDIT_ENDPOINT, MAINTENANCE_HISTORY, MAINTENANCE_HISTORY_OFVEHICLE, MAINTENANCE_PENDING_OFVEHICLE } from 'src/app/core/config/config.constants';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
    providedIn: 'root'
})
export class VehicleMaintenanceService {
    constructor(
        private apiService: ApiService,
    ) {}

    getCurrentTimeStamp(): number {
        return Date.now();
    }

    getVehiclePendingOperations(uid): Observable<any>{
        return this.apiService.get(MAINTENANCE_EDIT_ENDPOINT + uid + '?cacheBuster=' + this.getCurrentTimeStamp())
    }

    getVehicleHistoryOperations(uid): Observable<any>{
        return this.apiService.get(MAINTENANCE_HISTORY + uid + '?cacheBuster=' + this.getCurrentTimeStamp())
    }

    getVehiclePendingOperationsNew(uid): Observable<any>{
        return this.apiService.get(MAINTENANCE_PENDING_OFVEHICLE + uid)
    }

    getVehicleHistoryOperationsNew(uid): Observable<any>{
        return this.apiService.get(MAINTENANCE_HISTORY_OFVEHICLE + uid)
    }

    setVehiclePendingOperation(uid, params): Observable<any>{
        return this.apiService.put(MAINTENANCE_PENDING_OFVEHICLE + uid + '?cacheBuster=' + this.getCurrentTimeStamp(), params)
    }

    getListByFleetsUid(uid): Observable<any>{
        return this.apiService.get(MAINTENANCE_HISTORY + uid)
    }
}
