<div class="card">
    <h4 class="card-header modal-header">{{"global.modal.assignment" | translate}}</h4>
    <div class="card-body">

        <dx-tabs [dataSource]="tabPanelItems" [scrollByContent]="true" style="margin-bottom: 15px;" [selectedIndex]="0"
            [showNavButtons]="true" (onSelectionChanged)="onTabChanged($event)">
        </dx-tabs>

        <p *ngIf="submitted && isError" style="color: rgb(206, 3, 3);">{{"global.modal.assignError" | translate}}</p>
        <div *ngIf="chosenTab?.service == 'vehicle'">
            <p class="card-text boldFont">{{"global.modal.vehicleList" | translate}}</p>
            <div *ngIf="selectedVehicleRows?.length">
                <span translate="vehicles.selectedList" class="bolder"></span>:
                <ng-container *ngFor="let vehicle of selectedVehicleRows; let isLast = last">
                    {{vehicle?.vin}}{{isLast ? '' : ', '}}
                </ng-container>
            </div>
            <dx-drop-down-box [(value)]="selectedUids" valueExpr="uid" displayExpr="name"
                placeholder="Select a value..." [showClearButton]="true" [dataSource]="vehicles">
                <div *dxTemplate="let data of 'content'">
                    <dx-data-grid [dataSource]="vehicles" [columns]="['vin']" [selection]="{ mode: 'multiple' }"
                        [hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
                        [filterRow]="{ visible: true }" [scrolling]="{ mode: 'virtual' }" [height]="345"
                        [(selectedRowKeys)]="selectedUids" (onSelectionChanged)="vehicleSelectionChanged($event)">
                    </dx-data-grid>
                </div>
            </dx-drop-down-box>
        </div>
        <div *ngIf="chosenTab?.service == 'model'">
            <p class="card-text boldFont">{{"global.modal.modelList" | translate}}</p>
            <div *ngIf="selectedModelRows?.length">
                <span translate="models.selectedList" class="bolder"></span>:
                <ng-container *ngFor="let model of selectedModelRows; let isLast = last">
                    {{model?.name}}{{isLast ? '' : ', '}}
                </ng-container>
            </div>
            <dx-drop-down-box [(value)]="selectedUids" valueExpr="uid" displayExpr="name"
                placeholder="Select a value..." [showClearButton]="true" [dataSource]="models">
                <div *dxTemplate="let data of 'content'">
                    <dx-data-grid [dataSource]="models" [columns]="['name']" [selection]="{ mode: 'multiple' }"
                        [hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
                        [filterRow]="{ visible: true }" [scrolling]="{ mode: 'virtual' }" [height]="345"
                        [(selectedRowKeys)]="selectedUids" (onSelectionChanged)="modelSelectionChanged($event)">
                    </dx-data-grid>
                </div>
            </dx-drop-down-box>
        </div>

    </div>
    <div class="card-footer">
      <dx-button class="ml-1" type="success" [useSubmitBehavior]="true" (onClick)="confirm()">
        {{"global.modal.confirm" | translate}}
    </dx-button>
    <dx-button class="ml-1" stylingMode="outlined" type="normal" (onClick)="close()">
      {{"global.modal.cancel" | translate}}
    </dx-button>



    </div>
</div>
