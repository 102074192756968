<div class="card">
    <h4 class="card-header">{{"global.modal.danger" | translate}}</h4>
    <div class="card-body">
      <p class="card-text">{{data | translate}}</p>
    </div>
    <div class="card-footer">

      <dx-button class="ml-1" stylingMode="outlined" type="normal" (onClick)="close()"> {{"global.modal.ok" | translate}}
      </dx-button>

    </div>
</div>
