import { ApiService } from 'src/app/core/services/api.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  openHubs:any[] = [];
  openingHubs :any[] = [];
  baseUrl = '';
  signalRUrl = '';
  alertingSignalRUrl = '';
  totTime = 0;

  constructor(private apiService : ApiService) { 
    this.baseUrl = environment.baseUrl;
    this.signalRUrl = environment.signalRUrl;
    this.alertingSignalRUrl = environment.alertingSignalRUrl;
  }

  connect(hubConfig) {
    let self = this;
    var startUrl = this.baseUrl + 'api/' + hubConfig.startNegotiateNamespace;
    
    var jwtToken = null;
    var hubSignalR: any = null;

    var totTime = 0;
    if (this.isConnecting(hubConfig.hubNamespace)) {

      do {
        setTimeout(() => {
          ++totTime;
        }, 1000);
      }
      while (!this.isConnected(hubConfig.hubNamespace) && totTime < 5);
    }


    if (!this.isConnected(hubConfig.hubNamespace)) {
      this.openingHubs.push(hubConfig.hubNamespace);
      var options: any = {
        accessTokenFactory: async function () {
          this.jwtToken = await self.apiService.get(startUrl, true).toPromise();
          return this.jwtToken;
        },
        //skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      };

        hubSignalR = new HubConnectionBuilder()
            .withUrl(hubConfig.hubNamespace == 'AlertingNotification' ? this.alertingSignalRUrl + hubConfig.hubNamespace : this.signalRUrl + hubConfig.hubNamespace, options)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

      hubSignalR.hubConfig = hubConfig;
      hubSignalR.start().then(function () {
        self.onConnected(hubSignalR);
      });
    }
    return hubSignalR;
  }

  onHTTPerror(e) {
    var indexToRemove = this.openingHubs.indexOf(e.config.hubConfig.hubNamespace);
    if (indexToRemove > -1) {
      this.openingHubs.splice(indexToRemove, 1);
    }
    e.config.hubConfig.onclose(e);
  }

  onConnected(hubSignalR) {
    let self = this;
    this.openHubs[hubSignalR.hubConfig.hubNamespace] = hubSignalR;
    var indexToRemove = this.openingHubs.indexOf(hubSignalR.hubConfig.hubNamespace);
    if (indexToRemove > -1) {
      this.openingHubs.splice(indexToRemove, 1);
    }

    // hubSignalR.onclose(function (error) { if (!_.isNull(error) && !_.isUndefined(error)) self.onDisconnected(hubSignalR); });
    _.each(hubSignalR.hubConfig.onmessage, function (topicMap) {
      hubSignalR.on(topicMap.topic, topicMap.callback);
    });
    hubSignalR.hubConfig.onstart();
    // hubSignalR.deferred.resolve(hubSignalR); 
  }

  onDisconnected(hubSignalR) {
    hubSignalR.hubConfig.ondisconnected();
    this.disconnect(hubSignalR.hubConfig.hubNamespace);
  }

  invoke(hubNamespace, topic, filters) {
    if (this.isConnected(hubNamespace)) {
      this.openHubs[hubNamespace].invoke(topic, filters);
    }
  }

  isConnected(hubNamespace) {
    return (typeof this.openHubs[hubNamespace] !== 'undefined' && this.openHubs[hubNamespace] !== null && this.openHubs[hubNamespace]._connectionState.toLowerCase() === "connected");
  }

  isConnecting(hubNamespace) {
    return (this.openingHubs.indexOf(hubNamespace) !== -1);
  }

  disconnect(hubNamespace) {
    if (this.isConnected(hubNamespace)) {
      this.openHubs[hubNamespace].off(this.openHubs[hubNamespace].hubConfig.onmessage.receiveAssetPositionTopic, this.openHubs[hubNamespace].hubConfig.onmessage.receiveAssetPositionFunction);
      //openHubs[hubNamespace].onclose(null);
      this.openHubs[hubNamespace].stop().then(function () {
      });
      this.openHubs[hubNamespace] = null;
    }
  }
}
