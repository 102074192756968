<div class="card">
  <h4 class="card-header">{{"global.modal.confirmation" | translate}}</h4>
  <div class="card-body">
    <p class="card-text">{{ data | translate}}</p>
  </div>
  <div class="card-footer">
    <dx-button class="ml-1" stylingMode="outlined" type="success" (onClick)="save()"> {{"global.modal.yes" | translate}}
    </dx-button>
    <dx-button class="ml-1" stylingMode="outlined" type="normal" (onClick)="close()"> {{"global.modal.no" | translate}}
    </dx-button>
      <!-- <a (click)="save()" class="btn btn-yes">{{"global.modal.yes" | translate}}</a>
      <a (click)="close()" class="btn btn-no">{{"global.modal.no" | translate}}</a> -->
  </div>
</div>
