import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-detail-modal',
  templateUrl: './detail-modal.component.html',
  styleUrls: ['./detail-modal.component.scss']
})
export class DetailodalComponent implements OnInit {

  public piani: any;
  public edit: boolean;
  public codice: boolean;
  public pageSize: number;
  public allowedPageSizes: any[];
  public isAllRowsValid: boolean;
  public custom: boolean;

  constructor(public dialogRef: MatDialogRef<DetailodalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }



  ngOnInit(): void {
    this.piani = [];
    console.log(this.data)
    if (this.data.data) {
      this.piani = this.data.data;
    }
    this.edit = this.data.edit;
    this.codice = this.data.codice;
    this.custom = this.data.custom;
    this.pageSize = 20;
    this.isAllRowsValid = true;
    this.allowedPageSizes = [20, 40, 60];
  }

  onRowValidating(e: any) {
    this.isAllRowsValid = e.isValid;
  }

  add(event) {
    this.piani.unshift(event.data);
  }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(true);
  }

}
