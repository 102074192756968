import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseApi = '';
  baseApiCloud = '';

  constructor(private http: HttpClient, private handler: HttpBackend,) { 
    this.baseApi = environment.baseApi;
    this.baseApiCloud = environment.baseApiCloud;
  }

  get(url: string, noBaseApi = false){
    if(noBaseApi){
      return this.http.get(url);
    }
    return this.http.get(this.baseApi + url);
  }

  getWithParams(url: string, params?: any){
    return this.http.get(this.baseApi + url, {params});
  }

  post(url: string, params?: any){
    return this.http.post(this.baseApi + url, params);
  }

  put(url: string, params?: any){
    return this.http.put(this.baseApi + url, params);
  }

  delete(url: string, params?: any){
    return this.http.delete(this.baseApi + url, params);
  }

  patch(url: string, params?: any){
    return this.http.patch(this.baseApi + url, params);
  }

  downloadFile(url: string): Observable<any> {
    const httpClientWithoutAuthorization = new HttpClient(this.handler);
    return httpClientWithoutAuthorization.get(url, {
      responseType: 'arraybuffer'
    });
  }

  downloadRegulation(url: string): Observable<any> {
    return this.http.get(this.baseApi + url, {
      headers: {
          'Accept': 'application/pdf'
      },
      responseType: 'arraybuffer'
    });
  }

  /* use of baseApiCloud variable for Agrirouter services */

  getFromCloudString(url: string){
    return this.http.get(this.baseApiCloud + url, {responseType: "text"});
  }

  getFromCloud(url: string){
    return this.http.get(this.baseApiCloud + url);
  }

  getFormCheck(url: string){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(this.baseApiCloud + url, {
      headers,
      observe: 'response'
    });
  }

  postFromCloud(url: string, params?: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.baseApiCloud + url, params,{
      headers,
      observe: 'response'
    });
  }

  putFromCloud(url: string, params?: any){
    return this.http.put(this.baseApiCloud + url, params);
  }

  deleteFromCloud(url: string, params?: any){
    return this.http.delete(this.baseApiCloud + url, params);
  }

  patchFromCloud(url: string, params?: any){
    return this.http.patch(this.baseApiCloud + url, params);
  }

}