import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { NoAuthGuardService } from './core/guards/no-auth-guard.service';
import { TemplatePageComponent } from './template-page/template-page.component';

const routes: Routes = [
  {
    path: 'auth', 
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [NoAuthGuardService]
  },
  {path : "" , component: TemplatePageComponent , 
    children:[
      { path: '', redirectTo: 'app', pathMatch: 'full' },
      {
        path: "app", 
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [AuthGuardService]
      }
    ] 
  },
  {
    path: '**', 
    loadChildren: () => import('./oops/oops.module').then(m => m.OopsModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }