/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable sort-imports */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, EMPTY, map, Observable, ReplaySubject } from 'rxjs';
import {
  ALL_PLANS_ENDPOINT,
  ENGINE_TYPES_ENDPOINT,
  INSTALLATIONS_TYPES_ENDPOINT,
  MAINTENANCE_PLAN_ENDPOINT_OFCUSTOMER,
  MISCELLANEUS_ENDPOINT,
  VEHICLE_TYPES_ENDPOINT,
  VEHICLES_OF_CUSTOMER_ENDPOINT
} from 'src/app/core/config/config.constants';

import { KeyValueBag } from 'src/app/core/models/keyValueBag';
import { VehicleRestricted } from 'src/app/core/models/VehicleRestricted';
import { UtilsService } from 'src/app/core/services/utils.service';

import { environment } from 'src/environments/environment';
import { BaseNameUidRoot } from 'src/app/core/models/BaseNameUidRoot.model';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private utilsService: UtilsService,

    private spinner: NgxSpinnerService
  ) {}

  public listVehicleType$ = new ReplaySubject<BaseNameUidRoot[] | null>(1);

  baseApi = environment.baseApi;

  private _jsonURL = 'assets/data/miscellaneous.data.json';

  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  getMiscellaneusData$(dataParameters: any, type: string): Observable<any> {
    return this.http
      .post<any>(this.baseApi + MISCELLANEUS_ENDPOINT + type, dataParameters)
      .pipe(
        map((Payload) => {
          //console.log(Payload)
          return Payload;
        }),
        catchError((err: any) => {
          return err;
        })
      );
  }

  getVehicleTypes$(): Observable<KeyValueBag[]> {
    return this.http
      .get<KeyValueBag[]>(`${this.baseApi + VEHICLE_TYPES_ENDPOINT}`)
      .pipe(
        map((Payload) => {
          return Payload;
        }),
        catchError((err: HttpErrorResponse) => {
          this.utilsService.showErrorPopup(err);

          return EMPTY;
        })
      );
  }

  getBrands$(uid): Observable<KeyValueBag[]> {
    return (
      this.http
        // eslint-disable-next-line prettier/prettier
        .get<KeyValueBag[]>(
          `${
            this.baseApi +
            VEHICLES_OF_CUSTOMER_ENDPOINT +
            uid +
            '/brands'
          }`
        )
        .pipe(
          map((Payload) => {
            return Payload;
          }),
          catchError((err: HttpErrorResponse) => {
            this.utilsService.showErrorPopup(err);

            return EMPTY;
          })
        )
    );
  }

  getModels$(uid): Observable<KeyValueBag[]> {
    return (
      this.http
        // eslint-disable-next-line prettier/prettier
        .get<KeyValueBag[]>(
          `${
            this.baseApi +
            VEHICLES_OF_CUSTOMER_ENDPOINT +
           uid +
            '/brandsAndModels'
          }`
        )
        .pipe(
          map((Payload) => {
            return Payload;
          }),
          catchError((err: HttpErrorResponse) => {
            this.utilsService.showErrorPopup(err);

            return EMPTY;
          })
        )
    );
  }

  getAllPlans$(): Observable<KeyValueBag[]> {
    return (
      this.http
        // eslint-disable-next-line prettier/prettier
        .get<KeyValueBag[]>(`${this.baseApi + ALL_PLANS_ENDPOINT}`)
        .pipe(
          map((Payload) => {
            return Payload;
          }),
          catchError((err: HttpErrorResponse) => {
            this.utilsService.showErrorPopup(err);

            return EMPTY;
          })
        )
    );
  }

  getPlans(customer): Observable<KeyValueBag[]>{
    return (
      this.http
        // eslint-disable-next-line prettier/prettier
        .get<KeyValueBag[]>(`${this.baseApi + MAINTENANCE_PLAN_ENDPOINT_OFCUSTOMER}`+ '/'+customer)
        .pipe(
          map((Payload) => {
            return Payload;
          }),
          catchError((err: HttpErrorResponse) => {
            this.utilsService.showErrorPopup(err);

            return EMPTY;
          })
        )
    );
  }

  getVehiclesOfCustomer$(uid): Observable<VehicleRestricted[]> {
    this.spinner.show();
    return this.http
      .get<VehicleRestricted[]>(
        `${
          this.baseApi +
          VEHICLES_OF_CUSTOMER_ENDPOINT +
          '/' +
          uid +
          '?alsoDisabled=true&withFullHistory=false'
        }`
      )
      .pipe(
        map((Payload) => {
          this.spinner.hide();
          return Payload;
        }),
        catchError((err: HttpErrorResponse) => {
          this.utilsService.showErrorPopup(err);
          this.spinner.hide();
          // let message = err.error.Message
          // console.log(err.error)
          // notify({
          //   message,
          //   width: 450,
          // },
          // 'error',
          // 2000);
          return EMPTY;
        })
      );
  }

  getSelectedVehicle$(uid:any,vehicleUid: any) {
    return this.http
      .get<VehicleRestricted[]>(
        `${
          this.baseApi +
          VEHICLES_OF_CUSTOMER_ENDPOINT +
          '/' +
         uid +
          '/vehicle/' +
          vehicleUid
        }`
      )
      .pipe(
        map((Payload) => {
          return Payload;
        }),
        catchError((err: HttpErrorResponse) => {
          this.utilsService.showErrorPopup(err);

          return EMPTY;
        })
      );
  }
}
