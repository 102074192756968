<!-- <div class="d-flex">
  <div ngbDropdown class="pull-right">
      <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{selectedLanguage?.value}}</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem *ngFor="let lang of languages" (click)="selectLanguage(lang)">{{lang?.value}}</button>
      </div>
  </div>
</div> -->



<div class="d-flex">
  <div  class="pull-right pt-2">
    <p-dropdown [options]="languages"
      [(ngModel)]="selectedLanguage"
      optionLabel="value"
      (onChange) =selectLanguage(selectedLanguage) >

      <ng-template let-country pTemplate="item">
        <div class="flex align-items-center gap-2" >
            <img
            src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
            [class]="'flag ' + country.key.toLowerCase()"
             style="width: 18px"
             *ngIf="country.key.toLowerCase() != 'en'"/>

             <img
             src="../../../../assets/images/locale/gb.png"
             [class]="'flag ' + country.key.toLowerCase()"
              style="width: 18px"
              *ngIf="country.key.toLowerCase() == 'en'"/>




            <div>{{ country.value }}</div>

        </div>

    </ng-template>

  </p-dropdown>
  </div>
</div>





