import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ApiService } from 'src/app/core/services/api.service';
import { Component, Input, OnChanges, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/core/services/data.service';
import * as _ from 'lodash';
import { CurrentUserInfo } from 'src/app/core/models/currentUserInfo';
import { CURRENT_USER_ENDPOINT } from 'src/app/core/config/config.constants';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnChanges, OnDestroy {
  @Input() displayNav : boolean = true;
  @ViewChild('sidenav') sideNav : any;
  open = true;
  subscription : Subscription;
  stylesheetString = 'aft';
  menuList:any;
  constructor(
    private dataService: DataService,
    private apiService : ApiService,
    private utilsService : UtilsService,
    private translate : TranslateService,
    private router : Router) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  currentUserInfo: CurrentUserInfo;

  ngOnChanges() {
    if(this.sideNav){
      this.toggleNav();
    }
  }

  ngOnInit() {
    this.stylesheetString = this.utilsService.getStylesheetString();
    this.currentUserInfo = this.dataService.getCurrentUserInfo();
    this.menuList = this.getMenuInfo();
    this.getCurrent(false);
    this.subscription = this.dataService.refreshNavEvent.subscribe({
      next: (v) => {
        this.getCurrent();
      },
    });
  }



  getCurrent(refresh : boolean = true) {
    this.apiService.get(CURRENT_USER_ENDPOINT)
      .subscribe(
        (currentUserInfo: any) => {
          currentUserInfo = currentUserInfo as CurrentUserInfo;
          this.dataService.setCurrentUserInfo(currentUserInfo);
         // this.dataService.setLanguage(currentUserInfo.user.preferences.language.label);
          this.translate.setDefaultLang(this.dataService.getLanguage());
          this.currentUserInfo = this.dataService.getCurrentUserInfo();
          if(refresh){
            this.getMenuInfo();
            this.dataService.setLanguage(currentUserInfo.user.preferences.language.label);
            this.router.navigate(['/app/home']).then((_) => window.location.reload());
          }
        },
        error => {
          this.utilsService.showErrorPopup(error);
        });
  }

  toggleNav(){
    this.sideNav.toggle();
  }

  getMenuInfo(){
    let orderedMenu = _.orderBy(this.currentUserInfo?.menu, ['order'],['asc']);
    return orderedMenu;
  }
}
