import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';




@Component({
  selector: 'actia-jsonEditor',
  templateUrl: './jsonEditor.component.html',
  styleUrls: ['./jsonEditor.component.scss']
})
export class ActiaJsonEditorComponent implements OnInit {




  @Input() dataJS:any;

  @Input() idComponent:string | undefined;



  @Input()
  get mode(): any { return this._mode; }
  set mode(value: any) {

    this._mode = value;

    this.jsoneditorOptions.mode = value;
  }
  private _mode:any;

  @Output() onChangeObject = new EventEmitter()

  jsoneditorOptions = new JsonEditorOptions();

  constructor() {


  }


  ngOnInit(): void {

  }

  changeLog(event:any){

    this.onChangeObject.emit(event)

  }

}
