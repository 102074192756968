import { DataService } from 'src/app/core/services/data.service';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { LANGUAGES } from 'src/app/core/config/config.constants';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  public languages : any[];
  public selectedLanguage : any;

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit(): void {
    let lang = this.dataService.getLanguage();
    this.languages = LANGUAGES;
    this.selectedLanguage = _.find(this.languages, ['key', lang]);
    console.log(this.languages);
  }

  selectLanguage(lang : any){
    this.selectedLanguage = lang;
    this.dataService.setLanguage(lang?.key);
    window.location.reload()
  }

}
