import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxTreeViewComponent } from 'devextreme-angular';
import * as _ from 'lodash';
import {  Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { UtilsService } from 'src/app/core/services/utils.service';

import { AppStateInterface } from 'src/app/appState.interface';
import { Store, select } from '@ngrx/store';

import { selectedCustomerSelector } from 'src/app/core/store/selectors';

@Component({
  selector: 'app-cutomers-tree-ref',
  templateUrl: './cutomers-tree-ref.component.html',
  styleUrls: ['./cutomers-tree-ref.component.scss']
})
export class CutomersTreeRefComponent implements OnInit, OnChanges {
  @ViewChild(DxTreeViewComponent, { static: false }) treeViewRef: DxTreeViewComponent;
//  selectedCustomer$: Observable<SelectedCustomerCacheInterface>;

  selectedCustomerFromStore$: any;
  public customers: any;
  @Output() customerSelected = new EventEmitter<any>();
  @Input() refreshCustomers: boolean;
  @Input() selectedCustomer: any;

  treeElementsList: any[] = [];
  @Input() isForFilter: boolean = false;
  subselectedCustomerSelector:Subscription;
  stylesheetString: string  = 'aft';
  @Input()
  get listIn(): any {
    return this._listIn;
  }
  set listIn(value: any) {

    this._listIn = value;
    //********* IMPORTANT  *************/
    //#FZ this prevent the error on is not extensible
    this.customers = JSON.parse(JSON.stringify(value));
    this.subselectedCustomerSelector = this.store.pipe(select(selectedCustomerSelector)).subscribe((res) => {
     // console.log('selectedCustomerUidSelector',res);
      this.selectedCustomerFromStore$= JSON.parse(JSON.stringify(res));


    });
    if (this.selectedCustomerFromStore$)
    setTimeout(() => {
    this.treeViewRef.instance.expandItem(this.selectedCustomerFromStore$.uid)
  }, 100);


  }
  private _listIn: any;
  subscription: Subscription;
  userInfo = this.dataService.getCurrentUserInfo();
  constructor(
    private apiService: ApiService,
    private utilsService: UtilsService,
    public dialog: MatDialog,

    private dataService: DataService,

    private store:Store<AppStateInterface>) {





     }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    this.stylesheetString = this.utilsService.getStylesheetString();
  }

  resetSearchInput() {

  }


  onItemClick(e: any) {
      this.isForFilter = true;
      
      let treeview = <HTMLCollectionOf<HTMLElement>>document.getElementsByClassName("dx-treeview-item");
      for (let index = 0; index < treeview.length; index++) {
        treeview.item(index)?.classList.remove(this.stylesheetString+'-main-bgcolor');
      }
      (e.itemElement as HTMLElement).classList.add(this.stylesheetString+'-main-bgcolor');

     this.customerSelected.emit(e.itemData);


  }

  onItemCollapseAndExpanded(e: any) {

  }



  onItemRendered(e: any) {
    if (this.isForFilter) this.treeElementsList.push(e);
    var span = e.itemElement.getElementsByTagName("span")[0];
    if (e.itemData.isHardLocked === true) {
      span.classList.add('aft-disabled__color');
    }
    else {
      span.classList.remove('aft-disabled__color');
    }
  }

  updateCustomerListWithChildrenInfo(customerList: any) {
  //   let self = this
  //   _.each(self.customers, function (customer) {
  //     var childrens;

  //     // Not root
  //     if (customer.uidParent !== null) {
  //       var customerParent = _.find(customerList, { uid: customer.uidParent });
  //       childrens = _.filter(customerList, { uidParent: customer.uidParent });
  //       customerParent.nameWithChildren = customerParent.name + ' (' + childrens.length + ')';

  //     } else {
  //       // Root customer
  //       childrens = _.filter(customerList, { uidParent: customer.uid });
  //       customer.nameWithChildren = customer.name + ' (' + childrens.length + ')';
  //     }
  //     if (!customer.nameWithChildren) customer.nameWithChildren = customer.name;
  //   });
  }

  ngOnDestroy(): void {
    this.subselectedCustomerSelector.unsubscribe();

}


}
