<dx-tab-panel
  #tabPanel
  [dataSource]="enabledTabPanelItems"
  [selectedIndex]="currentIndex"
  [loop]="false"
  [animationEnabled]="true"
  [swipeEnabled]="true"
  (onItemClick)="onTabChanged($event)"
  [width]="400"
  [ngClass]="stylesheetString"
>
<div *dxTemplate="let item of 'title'">
  <span>
    <i class="fa-solid fa-{{item.icon}}" aria-hidden="true"></i>
    {{ item.text }}</span>
</div>
  <div *dxTemplate="let item of 'item'">
    <div class="tabpanel-item">
      <div *ngIf="item.id == 0 " class="p-1" style="height: 50vh !important;">

        <dx-list #listAssetsVehicle [(selectedItems)]="selectedVehicleCustomers" style="display: block !important;"
          [(searchValue)]="searchTextVechicles" [dataSource]="dataSourceVehicle"
          [selectionMode]="item.selectionMode" selectAllMode="allPages"

          [(selectAllText)]="textSelectAllVehicles"
          [showSelectionControls]="true"
          [nextButtonText]="translateService.instant('global.next')" [searchEnabled]="true" searchMode="contains"
          [noDataText]="translateService.instant('global.noDataText')"
          [searchExpr]="['internalId', 'fullName', 'name', 'plate','trailerFind']"
          (onSelectionChanged)="onSelectionChangedVehicleCustomer($event)"
          (onSelectAllValueChanged) = "selectAllVehiclesChange($event)"
          itemTemplate="listItemVehicle"
          (onOptionChanged)="onOptionChangedVehicles($event)">
          >

         <dxo-search-editor-options
          placeholder="{{'global.search' | translate}}"


          >
      </dxo-search-editor-options>
          <!-- itemTemplate="listItemVehicle"> -->


        <div *dxTemplate="let item of 'listItemVehicle'">

          <div class="flex flex-row  items-center">
            <div class="basis-3/4">
              <span  class="font-bold tracking-wider">{{item.plate}}</span>



              <span class="txt-secondline"> ( {{item.internalId}} ) </span>
              <div  *ngIf="item?.trailerFind != ''" class=" txt-secondline underline decoration-sky-500">  {{item.trailerFind | translate}} </div>

            </div>
            <div class="flex basis-1/4 justify-end">

              <i *ngIf="item?.vehicleType?.idVehicleCategory===3"  class="fa-solid fa-trailer pr-2">

              </i></div>

          </div>

          </div>



        </dx-list>

        <!-- <dx-list #listAssetsVehicle [(selectedItems)]="selectedVehicleCustomers" style="display: block !important;"
        [(searchValue)]="searchText" [dataSource]="dataSourceVehicle"
        [selectionMode]="item.selectionMode" selectAllMode="allPages"
        [selectAllText]="translateService.instant('global.selectAll')" [showSelectionControls]="true"
        [nextButtonText]="translateService.instant('global.next')" [searchEnabled]="true" searchMode="contains"
        [noDataText]="translateService.instant('global.noDataText')"
        [searchExpr]="['internalId', 'fullName', 'name', 'plate']"
        (onSelectionChanged)="onSelectionChangedVehicleCustomer($event)"
        (onSelectAllValueChanged)="onSelectAllValueChangedCustomer($event)">

      </dx-list> -->

    </div>
    <div *ngIf="item.id == 1 " class="p-1"  style="height: 50vh !important; ">

      <dx-list #listAssetsDrivers [(selectedItems)]="selectedAssetsDrivers" style="display: block !important;"
        [(searchValue)]="searchTextDrivers" [dataSource]="dataSourceDrivers"
        [selectionMode]="item.selectionMode" selectAllMode="allPages"
        [(selectAllText)]="textSelectAllDrivers"

        [showSelectionControls]="true"
        [nextButtonText]="translateService.instant('global.next')" [searchEnabled]="true" searchMode="contains"
        [noDataText]="translateService.instant('global.noDataText')"
        [searchExpr]="['internalId', 'fullName', 'name', 'plate']"
        (onSelectionChanged)="onSelectionChangedDrivers($event)"
        (onSelectAllValueChanged) = "selectAllDriversChange($event)"
        (onOptionChanged)="onOptionChangedDrivers($event)"
         itemTemplate="listItemDrivers"
        >

        <dxo-search-editor-options
        placeholder="{{'global.search' | translate}}"


        >
    </dxo-search-editor-options>

        <div *dxTemplate="let item of 'listItemDrivers'">

          <div class="flex flex-row  items-center">
            <div class="basis-3/4">
              <span class="font-bold ">{{item.fullName}}</span>
              <span class="txt-secondline"> ( {{item.cardId}} ) </span>
            </div>


          </div>

        </div>

      </dx-list>

      <!-- <dx-list #listAssetsDrivers [(selectedItems)]="selectedAssetsDrivers" style="display: block !important;"
      [(searchValue)]="searchText" [dataSource]="dataSourceDrivers"
      [selectionMode]="item.selectionMode" selectAllMode="allPages"
      [selectAllText]="translateService.instant('global.selectAll')" [showSelectionControls]="true"
      [nextButtonText]="translateService.instant('global.next')" [searchEnabled]="true" searchMode="contains"
      [noDataText]="translateService.instant('global.noDataText')"
      [searchExpr]="['internalId', 'fullName', 'name', 'plate']"
      (onSelectionChanged)="onSelectionChangedDrivers($event)"
      (onSelectAllValueChanged)="onSelectAllValueChangedDrivers($event)">

    </dx-list> -->



  </div>
    </div>
    </div>
</dx-tab-panel>


<!--

    <dx-tabs [dataSource]="enabledTabPanelItems" [selectedIndex]="currentIndex" (onItemClick)="onTabChanged($event)"
    style="width: 400px;">

  </dx-tabs>
  <div class="tabs-panel" >


    <div *ngIf="currentIndex == 0 " class="p-1" style="height: 50vh !important; width: 400px;">

        <dx-list #listAssetsVehicle [(selectedItems)]="selectedVehicleCustomers" style="display: block !important;"
          [(searchValue)]="searchText" [dataSource]="dataSourceVehicle"
          [selectionMode]="arraySelectionMode[currentIndex].selectionMode" selectAllMode="allPages"
          [selectAllText]="translateService.instant('global.selectAll')" [showSelectionControls]="true"
          [nextButtonText]="translateService.instant('global.next')" [searchEnabled]="true" searchMode="contains"
          [noDataText]="translateService.instant('global.noDataText')"
          [searchExpr]="['internalId', 'fullName', 'name', 'plate']"
          (onSelectionChanged)="onSelectionChangedVehicleCustomer($event)"
          (onSelectAllValueChanged)="onSelectAllValueChangedCustomer($event)">

        </dx-list>

    </div>
    <div *ngIf="currentIndex == 1 " class="p-1"  style="height: 50vh !important; width: 400px;">

        <dx-list #listAssetsDrivers [(selectedItems)]="selectedAssetsDrivers" style="display: block !important;"
          [(searchValue)]="searchText" [dataSource]="dataSourceDrivers"
          [selectionMode]="arraySelectionMode[currentIndex].selectionMode" selectAllMode="allPages"
          [selectAllText]="translateService.instant('global.selectAll')" [showSelectionControls]="true"
          [nextButtonText]="translateService.instant('global.next')" [searchEnabled]="true" searchMode="contains"
          [noDataText]="translateService.instant('global.noDataText')"
          [searchExpr]="['internalId', 'fullName', 'name', 'plate']"
          (onSelectionChanged)="onSelectionChangedDrivers($event)"
          (onSelectAllValueChanged)="onSelectAllValueChangedDrivers($event)">

        </dx-list>


    </div>

  </div> -->



