import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { UtilsService } from 'src/app/core/services/utils.service';

import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import { getSelectedPeriod, getTabAssetChooserRefSelector, getTabFilterRefSelector } from 'src/app/core/store/selectors';
import { Store, select } from '@ngrx/store';
import { AppStateInterface } from 'src/app/appState.interface';
import { Subscription } from 'rxjs';
import { IntialParameter } from 'src/app/core/store/interface/tabFilterRef.interface';
import { SelectedPeriodFitler } from 'src/app/core/store/interface/selectedPeriod.interface';
import * as PostActions from '../../../core/store/actions';
import { locale } from 'devextreme/localization';
import { DataService } from 'src/app/core/services/data.service';

type SelectBoxType = {
  id: string,
  value: string
};

type DataAvailabilty = {
  unit: string,
  num: number
}


@Component({
  selector: 'app-timespan-chooser-ref',
  templateUrl: './timespan-chooser-ref.component.html',
  styleUrls: ['./timespan-chooser-ref.component.scss']
})
export class TimespanChooserRefComponent implements OnInit {
  @ViewChild(DxDateBoxComponent) dxDateBox: DxDateBoxComponent;
  stylesheetString: string;

  constructor(private utilsService: UtilsService,
    private translateService: TranslateService,
    private store: Store<AppStateInterface>,
    private elRef: ElementRef,
    private dataService: DataService,
    private renderer: Renderer2, private el: ElementRef
  ) {

    this.subgetSelectePeriod = this.store.pipe(select(getSelectedPeriod)).subscribe((res) => {
      let appRes =
        this.currentTimespan = res.timespanSel;
      this.fromDtPicker = res.fromDtPicker;
      this.toDtPicker = res.toDtPicker;


      // this.fromTime  = this.fromDtPicker
      // this.toTime =new Date(this.toDtPicker)
      // this.fromTimeValue = this.fromTime;
      // this.toTimeValue = this.toTime

    });




  }

  defaultPeriods = ['dateTimePeriod', 'timeperiod', 'daily', 'week', 'last7days', 'month'];
  daysMaxPeriodDefault = 100;

  timespanSelectBoxItems: SelectBoxType[] = [];
  selectedPeriodType: SelectBoxType;

  nowDate = moment().valueOf();

  maxDaysPeriod: number;

  //default values from service
  currentTimespan: string = 'month';
  fromDtPicker: number;
  toDtPicker: number;

  fromDtPickerMinVal = moment().subtract(2, 'year').valueOf();

  fromDate: number;
  toDate: number;
  // fromTimeValue:any;
  // toTimeValue:any;

  displayFormat: any;
  displayDateTimeFormat: any
  calendarOptions: Object;

  summaryFromDate: string | null;
  summaryToDate: string | null;

  oldDataAvailabilityDefault: DataAvailabilty = {
    unit: "year",
    num: 2
  };



  // fromTime:Date;
  // toTime:Date;




  // tabPeriodConfig:IntialParameter =
  //       {
  //         timespan: [
  //             {periods:['timeperiod', 'daily', 'week', 'last7days', 'month'],
  //             daysMaxPeriod:100}

  //         ]
  //     }

  tabPeriodConfig: any;

  oldDataAvailability: DataAvailabilty;
  lng: any = localStorage.getItem('language');
  subgetSelectePeriod: Subscription;
  ngOnInit(): void {
    this.translateService.get('global.dateFormat').subscribe(format => {
      this.displayFormat = format;
    })

    locale(this.dataService.getLanguage());

    this.stylesheetString = this.utilsService.getStylesheetString();
    this.translateService.get('global.dateTimeFormat').subscribe(dateTimeFormat => {
      this.displayDateTimeFormat = dateTimeFormat;
    })
    // this.fromTime.setUTCHours(0,0,0,0);
    // this.toTime.setUTCHours(23,59,59,999);
    let getTabAssetsSub: Subscription = this.store.pipe(select(getTabFilterRefSelector)).subscribe((resTabFilterRef) => {
      // console.log('getTabFilterRefSelector',resTabFilterRef);
      //  console.log(res)
      if (resTabFilterRef.tabFilterRef.length == 3)
        this.tabPeriodConfig = resTabFilterRef.tabFilterRef[2]
      else
        this.tabPeriodConfig = resTabFilterRef.tabFilterRef[1]
      //  console.log(this.tabPeriodConfig);

      this.maxDaysPeriod = this.tabPeriodConfig.intialParameter[0].timespan && this.tabPeriodConfig.intialParameter[0].timespan[0].daysMaxPeriod ? this.tabPeriodConfig.intialParameter[0].timespan[0].daysMaxPeriod : this.daysMaxPeriodDefault;
      this.timespanSelectBoxItems = this.createTranslatedOptionsForTimespanSelectBox();
      if (this.timespanSelectBoxItems.length == 1) {
        this.currentTimespan = this.timespanSelectBoxItems[0].id;
        this.updateCalendarOptions();
        this.calcToDate();
      }





    });
    getTabAssetsSub.unsubscribe();


  }


  createTranslatedOptionsForTimespanSelectBox() {
    let periodsToCycle = this.tabPeriodConfig.intialParameter[0].timespan && this.tabPeriodConfig.intialParameter[0].timespan[0].periods.length > 0 ? this.tabPeriodConfig.intialParameter[0].timespan[0].periods : this.defaultPeriods;
    var items: SelectBoxType[] = [];
    periodsToCycle.forEach((item: any) => {
      items.push({ id: item, value: this.translateService.instant("global." + item) });
    }); return items;
  }

  toValueChanged(ev: any) {
    // this.setMaxIntervalDate(true);
    if (ev.value) this.toDtPicker = ev.value.valueOf();
    this.calcToDate();
  }

  fromValueChanged(ev: any) {
    // this.setMaxIntervalDate(false);
    if (ev.value) this.fromDtPicker = ev.value.valueOf();
    this.calcToDate();
  }


  // fromTimeValueChanged(ev: any){
  // // console.log(moment(ev.value).format('HH:mm'))
  //  this.fromTimeValue = ev.value
  //  this.calcToDate()
  // // let appfromTime = this.calcDailyTime(this.fromDtPicker,this.fromTimeValue);
  //  //let fromEpochDateTime = moment(moment(this.fromDtPicker).format('yyyy-MM-DD') + ' ' + moment(this.fromTimeValue).format('HH:mm'),'yyyy-MM-DD HH:mm').valueOf()
  // // console.log( appfromTime)


  // }

  // toTimeValueChanged(ev: any){
  //  //  console.log(moment(ev.value).format('HH:mm'))
  //   this.toTimeValue = ev.value;
  //   this.calcToDate()
  //   //let appfromTime = this.calcDailyTime(this.fromDtPicker,this.toTimeValue);
  //   //let fromEpochDateTime = moment(moment(this.fromDtPicker).format('yyyy-MM-DD') + ' ' + moment(this.fromTimeValue).format('HH:mm'),'yyyy-MM-DD HH:mm').valueOf()
  //   //console.log( appfromTime)
  //   // let toEpochDateTime = moment(moment(this.fromDtPicker).format('yyyy-MM-DD') + ' ' + moment(this.toTimeValue).format('HH:mm'),'yyyy-MM-DD HH:mm').valueOf()
  //   // console.log( toEpochDateTime)

  // }

  selectBoxChanged(ev: any) {
    if (ev.selectedItem) {
      if (ev.selectedItem.id != this.currentTimespan) {
        if (ev.selectedItem.id == 'timeperiod') {
          this.fromDtPicker = moment().startOf('day').valueOf();;
          this.toDtPicker = moment().valueOf();
        }
        else {
          this.fromDtPicker = this.toDtPicker = moment().valueOf();
        }
      }

      this.currentTimespan = ev.selectedItem.id;
      this.updateCalendarOptions();
      //this.setMaxIntervalDate(true);
      this.calcToDate();
    }
  }

  updateCalendarOptions() {
    if (this.currentTimespan == 'month') {
      this.fromDtPickerMinVal = moment().subtract(1, 'month').startOf('month').valueOf();
      this.displayFormat = 'MMM y';
      this.calendarOptions = {
        maxZoomLevel: 'year',
        minZoomLevel: 'century'
      }
    } else {
      this.fromDtPickerMinVal = moment().subtract(this.maxDaysPeriod, 'days').startOf('day').valueOf();
      this.calendarOptions = {
        maxZoomLevel: 'month',
        minZoomLevel: 'century'
      }
    }
  }


  calcToDate() {
    switch (this.currentTimespan) {
      case 'timeperiod':
        this.calcTimeperiod();
        break;
      case 'dateTimePeriod':
        this.calcTimeperiod();
        break;
      case 'daily':
        this.calcDaily();
        break;
      case 'week':
        this.calcWeek();
        break;
      case 'last7days':
        this.calcLast7Days();
        break;
      case 'month':
        this.calcMonth();
        break;
      default:
        break;
    }


    let newStorePeriod: SelectedPeriodFitler = {
      fromDtPicker: this.fromDate,
      toDtPicker: this.toDate,
      timespanSel: this.currentTimespan, //timeperiod month daily week last7days
      isApply: false
    }


    this.store.dispatch(
      PostActions.setSelectedPediod(newStorePeriod)
    );

  }

  calcTimeperiod() {
    this.formatDate();
    //    var span = this.utilsService.calcDateTimeperiod(this.fromDtPicker, this.toDtPicker,this.displayDateTimeFormat);
    //   this.fromDate = span.fromDate;
    //  this.toDate = span.toDate;

    this.fromDate = this.fromDtPicker
    this.toDate = this.toDtPicker

  }
  //  calcDailyTime(){

  //     this.fromDate = this.formatDailyTime(this.fromDtPicker,this.fromTimeValue)
  //     this.toDate = this.formatDailyTime(this.fromDtPicker,this.toTimeValue)
  //     this.fromDtPicker = this.fromDate;
  //     this.toDtPicker = this.toDate;


  //  }
  calcDaily() {
    this.formatDate();
    var span = this.utilsService.calcDaily(this.fromDtPicker);

    this.fromDate = span.fromDate;
    this.toDate = span.toDate;
    console.log(span)
  }

  formatDailyTime(dateIn, timeIN) {
    return moment(moment(dateIn).format('yyyy-MM-DD') + ' ' + moment(timeIN).format('HH:mm'), 'yyyy-MM-DD HH:mm').valueOf()
  }



  calcWeek() {
    var span = this.utilsService.calcWeek(this.fromDtPicker);
    this.fromDate = span.fromDate;
    this.toDate = span.toDate;
    console.log(span)
    this.formatDate();
    this.summaryFromDate = this.utilsService.formatDateFromEpoch(span.fromDate, this.displayFormat, null);
    this.summaryToDate = this.utilsService.formatDateFromEpoch(span.toDate, this.displayFormat, null);
    this.toDtPicker = this.fromDtPicker;
  }

  calcMonth() {
    var span = this.utilsService.calcMonth(this.fromDtPicker);
    this.fromDate = span.fromDate;
    this.toDate = span.toDate;
    this.summaryFromDate = this.utilsService.formatDateFromEpoch(span.fromDate, this.displayFormat, null);
    this.summaryToDate = this.utilsService.formatDateFromEpoch(span.toDate, this.displayFormat, null);
    this.toDtPicker = this.fromDtPicker;
  }

  calcLast7Days() {
    var span = this.utilsService.calcLast7Days();
    this.fromDate = span.fromDate;
    this.toDate = span.toDate;
    this.summaryFromDate = this.utilsService.formatDateFromEpoch(span.fromDate, this.displayFormat, null);
    this.summaryToDate = this.utilsService.formatDateFromEpoch(span.toDate, this.displayFormat, null);
  }

  formatDate() {
    if (this.lng === 'it') {
      this.displayFormat = 'dd/MM/yyyy'
    } else {
      this.displayFormat = 'MM/dd/yyyy'
    }
  }

  formatDateTime() {
    if (this.lng === 'it') {
      this.displayFormat = 'dd/MM/yyyy HH:mm'
    } else {
      this.displayFormat = 'MM/dd/yyyy HH:MM'
    }
  }


  ngOnDestroy(): void {
    this.subgetSelectePeriod.unsubscribe();
  }

  buttonClick() {
    console.log('clik')
  }


}
