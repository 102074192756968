import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';

import { Store, select } from '@ngrx/store';
import { AppStateInterface } from 'src/app/appState.interface';
import { getDriversOfCustomersSelector, getFleetsOfCustomersSelector, getSelectedAssests, getTabAssetChooserRefSelector, getTeamsOfCustomersSelector, getVehiclesOfCustomersSelector } from 'src/app/core/store/selectors';
import { SelectedAssets } from 'src/app/core/store/interface/selectedAssets.interface';
import dxList from 'devextreme/ui/list';
import * as PostActions from '../../../core/store/actions'
import { UtilsService } from 'src/app/core/services/utils.service';





@Component({
  selector: 'app-assets-chooser-ref',
  templateUrl: './assets-chooser-ref.component.html',
  styleUrls: ['./assets-chooser-ref.component.scss']
})
export class AssetsChooserRefComponent implements OnInit {


  scrollByContent = true;
  scrollByThumb = true;
  scrollbarMode: string;
  searchTextVechicles: string = '';
  searchTextDrivers: string = '';
  vechicleOfCustomer$: any;
  fleetOfCustomer$: any;
  driversOfCustomer$: any;
  teamsOfCustomers$: any;
  currentDataSource: DataSource;
  dataSourceVehicle: DataSource;
  dataSourceFleets: DataSource;
  dataSourceDrivers: DataSource;
  dataSourceTeams: DataSource;
  selectedVehicleCustomers: any[] = [];
  selectedAssetsDrivers: any[] = [];
  selectedAssetsFleets: any[] = [];
  selectedAssetsTeams: any[] = [];
  getSeletedAssetsFromStore$: SelectedAssets;

  enabledTabPanelItems: any[] = [];
  subgetVehiclesOfCustomersSelector: Subscription;
  subgetDriversOfCustomersSelector: Subscription
  subgetTabAssetChooserRefSelector: Subscription;
  subgetSelectedAssests: Subscription;
  maxRecordForPaginate: number = 100;
  arraySelectionMode: any = [];
  textSelectAllVehicles: string = '';
  textSelectAllDrivers: string = '';
  totVechilesfromStore: number;
  totDriversfromStore: number;
  appStoreList: SelectedAssets = {
    assetType: '',
    selected: [],
    totSelected: 0,
    isApply: false
  }
  @ViewChild("listAssetsVehicle", { static: false }) dataListVehicle: dxList
  @ViewChild("listAssetsDrivers", { static: false }) dataListDriver: dxList
  @ViewChild("listAssetsFleets", { static: false }) dataListFleet: dxList
  @ViewChild("listAssetsTeams", { static: false }) dataListTeams: dxList
  stylesheetString: any;
  constructor(
    public translateService: TranslateService,    
    private utilsService: UtilsService,
    private store: Store<AppStateInterface>
  ) {

    this.subgetVehiclesOfCustomersSelector = this.store.pipe(select(getVehiclesOfCustomersSelector)).subscribe((resVehi) => {
      //console.log('getVehiclesOfCustomersSelector',res);
      // this.vechicleOfCustomer$ = res;
      //this.dataSourceVehicle = JSON.parse(JSON.stringify(resVehi));
      this.totVechilesfromStore = resVehi.length;
      this.dataSourceVehicle = this.updateDataSourceRef(0, resVehi);
      this.selectedVehicleCustomers = resVehi;
    })


    this.subgetDriversOfCustomersSelector = this.store.pipe(select(getDriversOfCustomersSelector)).subscribe((resDrivers) => {
      //console.log('getDriversOfCustomersSelector',res);
      // this.driversOfCustomer$ = res;
      // this.dataSourceDrivers = JSON.parse(JSON.stringify(resDrivers));
      this.totDriversfromStore = resDrivers.length;
      this.dataSourceDrivers = this.updateDataSourceRef(1, resDrivers);
      this.selectedAssetsDrivers = resDrivers;
    })

    // this.store.pipe(select(getFleetsOfCustomersSelector)).subscribe((res) => {
    //   console.log('getFleetsOfCustomersSelector',res);
    //   // this.fleetOfCustomer$ = res;
    //   this.dataSourceFleets = this.updateDataSourceRef(2,res);
    // })

    // this.store.pipe(select(getTeamsOfCustomersSelector)).subscribe((res) => {
    //   console.log('getTeamsOfCustomersSelector',res);
    //   // this.teamsOfCustomers$ = res;
    //   this.dataSourceTeams = this.updateDataSourceRef(2,res);
    // })



    this.subgetSelectedAssests = this.store.pipe(select(getSelectedAssests)).subscribe((res) => {
      //console.log('getApplySelectedCustomer',res)
      this.getSeletedAssetsFromStore$ = res;

      switch (this.getSeletedAssetsFromStore$.assetType) {
        case 'VEHIC':
          this.selectedVehicleCustomers = this.getSeletedAssetsFromStore$.selected;
          //this.selectedVehicleCustomers = [];
          this.selectedAssetsDrivers = [];
          this.selectedAssetsFleets = [];
          this.selectedAssetsTeams = [];
          if (this.selectedVehicleCustomers.length < this.totVechilesfromStore)
            this.textSelectAllVehicles = this.translateService.instant('global.selectAll')
          else
            this.textSelectAllVehicles = this.translateService.instant('global.deSelectAll')

          this.textSelectAllDrivers = this.translateService.instant('global.selectAll')
          this.emptySelection = false;
          this.currentIndex = 0;
          break;
        case 'DRIVR':
          this.selectedAssetsDrivers = this.getSeletedAssetsFromStore$.selected;
          this.selectedVehicleCustomers = [];
          //this.selectedAssetsDrivers = [];
          this.selectedAssetsFleets = [];
          this.selectedAssetsTeams = [];
          this.emptySelection = false;
          if (this.selectedAssetsDrivers.length < this.totDriversfromStore)
            this.textSelectAllDrivers = this.translateService.instant('global.selectAll')
          else
            this.textSelectAllDrivers = this.translateService.instant('global.deSelectAll')

          this.textSelectAllVehicles = this.translateService.instant('global.selectAll')


          this.currentIndex = 1;
          break;
        // case 'TEAM':
        //   this.selectedAssetsTeams = this.getSeletedAssetsFromStore$.selected;
        //   this.selectedVehicleCustomers = [];
        //   this.selectedAssetsDrivers = [];
        //   this.selectedAssetsFleets = [];
        //   //this.selectedAssetsTeams = [];
        //   this.emptySelection = false;
        //     break;
        // case 'FLEET':
        //   this.selectedAssetsFleets = this.getSeletedAssetsFromStore$.selected;
        //   this.selectedVehicleCustomers = [];
        //   this.selectedAssetsDrivers = [];
        //   //this.selectedAssetsFleets = [];
        //   this.selectedAssetsTeams = [];
        //   this.emptySelection = false;
        //     break;

      }




    });



  }



  currentApiDataSource: any[] = [];


  //defaultTypes = ['vehicle', 'driver', 'fleets', 'teams'];

  emptySelection: boolean = true;
  //subscription: Subscription;
  //subscriptionSearch: Subscription;

  currentIndex: number;

  ngOnInit() {

    // this.currentIndex = 0;
    // //FZ ricevo elenco delle tab da visualizzare e inizializzo
    this.subgetTabAssetChooserRefSelector = this.store.pipe(select(getTabAssetChooserRefSelector)).subscribe((res) => {
      //   //console.log('getTabAssetChooserRefSelector',res);
      this.enabledTabPanelItems = JSON.parse(JSON.stringify(res.tabFilterRef));
      //console.log(this.enabledTabPanelItems)

      for (let elem of this.enabledTabPanelItems) {
        elem.text = this.translateService.instant(elem.text)
        this.arraySelectionMode.push({ id: elem.id, selectionMode: elem.selectionMode }

        )

      }
    });
    // console.log(this.enabledTabPanelItems)
    // console.log(this.enabledTabPanelItems)

    this.stylesheetString = this.utilsService.getStylesheetString();

  };



  onTabChanged(event) {
    this.currentIndex = event.itemData.id;

  }

  updateDataSourceRef(tabSel, dataSetIn) {
    let sortField = '';

    switch (tabSel) {
      case 0:
        this.currentApiDataSource = JSON.parse(JSON.stringify(dataSetIn));
        this.currentApiDataSource.forEach((el) => {
          el.text = `${el.internalId} ( ${el.plate} )`
          if (el.vehicleType?.idVehicleCategory === 3)
            el.trailerFind = this.translateService.instant("global.vehicleTypes.semitrailer")
          else
            el.trailerFind = '';
        });
        //    this.currentApiDataSource.sort(function(a,b) {
        //     console.log('a',a)
        //     console.log('b',b)
        //     return a.vehicleType.idVehicleCategory - b.vehicleType.idVehicleCategory;
        // });

        sortField = 'plate'
        break;
      case 1:
        this.currentApiDataSource = JSON.parse(JSON.stringify(dataSetIn));
        this.currentApiDataSource.forEach((el) => el.text = `${el.fullName}` + ` (${el.cardId})`)
        sortField = 'fullName'
        break;
      case 2:
        this.currentApiDataSource = JSON.parse(JSON.stringify(dataSetIn));
        this.currentApiDataSource.forEach((el) => el.text = `${el.name}`);
        sortField = 'name'
        break;
      case 3:

        break;

      default:
        this.currentApiDataSource = JSON.parse(JSON.stringify(dataSetIn));
        this.currentApiDataSource.forEach((el) => el.text = `${el.internalId} (${el.plate})`);
        sortField = 'plate'
        break;
    }
    // se sono più del valore impostato (100) pagino. Risolve problemi di lentezza caricamentos
    // console.log(this.currentApiDataSource)
    if (this.currentApiDataSource.length > this.maxRecordForPaginate) {

      return new DataSource({
        store: new ArrayStore({
          key: 'uid',
          data: this.currentApiDataSource
        }),
        // paginate: editedApiDataStore.length > 10 ? true : false,
        paginate: true,
        // pageSize: 10,
        sort: sortField
      });
    }
    else {
      return new DataSource({
        store: new ArrayStore({
          key: 'uid',
          data: this.currentApiDataSource
        }),
        // paginate: editedApiDataStore.length > 10 ? true : false,
        paginate: false,
        // pageSize: 10,
        sort: sortField
      });
    }

  }



  onOptionChangedDrivers(event) {
    if (event.name === 'searchValue') {
      // console.log(event)
      if (event.value.length == 1 && event.previousValue == '') {
        this.textSelectAllDrivers = this.translateService.instant('global.selectAll')
        this.selectedAssetsDrivers = [];

      }
    }


  }

  onOptionChangedVehicles(event) {
    if (event.name === 'searchValue') {
      // console.log(event)
      if (event.value.length == 1 && event.previousValue == '') {

        this.textSelectAllVehicles = this.translateService.instant('global.selectAll')
        this.selectedVehicleCustomers = [];
      }
    }


  }

  onContentReady(e: any) {

  }



  onSelectionChangedVehicleCustomer(ev) {
    if (this.selectedVehicleCustomers.length > 0) {

      this.appStoreList.assetType = 'VEHIC';
      this.appStoreList.selected = this.selectedVehicleCustomers;
      this.appStoreList.isApply = false;
      this.appStoreList.totSelected = this.selectedVehicleCustomers.length;
      //this.selectedVehicleCustomers = [];
      this.selectedAssetsDrivers = [];
      this.selectedAssetsFleets = [];
      this.selectedAssetsTeams = [];
      this.emptySelection = false;
      this.store.dispatch(PostActions.setSelectedAssets(this.appStoreList));

    }
    else {
      if (this.selectedAssetsDrivers.length <= 0
        && this.selectedAssetsFleets.length <= 0
        && this.selectedAssetsTeams.length <= 0) {

        this.emptySelection = true;
      }

    }
  }

  onSelectionChangedDrivers(ev) {
    if (this.selectedAssetsDrivers.length > 0) {
      this.appStoreList.assetType = 'DRIVR';
      this.appStoreList.selected = this.selectedAssetsDrivers;
      this.appStoreList.isApply = false;
      this.appStoreList.totSelected = this.selectedAssetsDrivers.length;
      this.selectedVehicleCustomers = [];
      //this.selectedAssetsDrivers = [];
      this.selectedAssetsFleets = [];
      this.selectedAssetsTeams = [];
      this.emptySelection = false;
      this.store.dispatch(PostActions.setSelectedAssets(this.appStoreList));

    }
    else {
      if (this.selectedVehicleCustomers.length <= 0
        && this.selectedAssetsFleets.length <= 0
        && this.selectedAssetsTeams.length <= 0) {

        this.emptySelection = true;
      }
    }
  }

  onSelectionChangedFleets(ev) {
    if (this.selectedAssetsFleets.length > 0) {
      this.appStoreList.assetType = 'FLEET';
      this.appStoreList.selected = this.selectedAssetsFleets;
      this.appStoreList.isApply = false;
      this.appStoreList.totSelected = this.selectedAssetsFleets.length;
      this.selectedVehicleCustomers = [];
      this.selectedAssetsDrivers = [];
      //this.selectedAssetsFleets = [];
      this.selectedAssetsTeams = [];
      this.emptySelection = false;
      this.store.dispatch(PostActions.setSelectedAssets(this.appStoreList));
    }
    else {
      if (this.selectedVehicleCustomers.length <= 0
        && this.selectedAssetsDrivers.length <= 0
        && this.selectedAssetsTeams.length <= 0)
        this.emptySelection = true;
    }
  }

  onSelectionChangedTeams(ev) {
    if (this.selectedAssetsTeams.length > 0) {
      this.appStoreList.assetType = 'TEAM';
      this.appStoreList.selected = this.selectedAssetsTeams;
      this.appStoreList.isApply = false;
      this.appStoreList.totSelected = this.selectedAssetsTeams.length;
      this.selectedVehicleCustomers = [];
      this.selectedAssetsDrivers = [];
      this.selectedAssetsFleets = [];
      this.emptySelection = false;
      //this.selectedAssetsTeams = [];
      this.store.dispatch(PostActions.setSelectedAssets(this.appStoreList));
    }
    else {
      if (this.selectedVehicleCustomers.length < 0
        && this.selectedAssetsDrivers.length < 0
        && this.selectedAssetsFleets.length < 0)
        this.emptySelection = true;
    }
  }

  selectAllVehiclesChange(e) {

    if (e.value) {
      this.textSelectAllVehicles = this.translateService.instant('global.deSelectAll')
    }
    else {
      this.textSelectAllVehicles = this.translateService.instant('global.selectAll')
    }

    // console.log(this.textSelectAllVehicles,e)
  }
  selectAllDriversChange(e) {

    if (e.value) {

      this.textSelectAllDrivers = this.translateService.instant('global.deSelectAll')
    }
    else {
      this.textSelectAllDrivers = this.translateService.instant('global.selectAll')
    }
  }


  // onSelectAllValueChangedCustomer(e){
  //  // console.log('onSelectAllValueChangedCustomer',e)
  //   this.appStoreList.assetType='';
  //   this.appStoreList.selected = [];
  //   this.appStoreList.totSelected = 0;
  //   this.appStoreList.isApply=false;

  //   if (!e.value){
  //     this.store.dispatch(PostActions.setSelectedAssets(this.appStoreList ));
  //   }

  // }


  // onSelectAllValueChangedDrivers(e){
  // //console.log('onSelectAllValueChangedDrivers',e)
  // this.appStoreList.assetType='';
  //   this.appStoreList.selected = [];
  //   this.appStoreList.totSelected = 0;
  //   this.appStoreList.isApply=false;
  // if (!e.value){
  //   this.store.dispatch(PostActions.setSelectedAssets(this.appStoreList ));
  // }

  // }

  ngOnDestroy(): void {
    this.subgetVehiclesOfCustomersSelector.unsubscribe();
    this.subgetDriversOfCustomersSelector.unsubscribe();
    this.subgetTabAssetChooserRefSelector.unsubscribe();
    this.subgetSelectedAssests.unsubscribe();
  }
  // onFocusInVehicles(ev)
  // {
  //   console.log('onFocusIn',ev)
  //   this.selectedVehicleCustomers = [];


  // }

  onFocusInDrivers(ev) {
    console.log('onFocusIn', ev)
    this.selectedAssetsDrivers = [];


  }




}
