import { Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { Injectable } from '@angular/core';
import { CurrentUserInfo } from '../models/currentUserInfo';
import { PossiblePreferences } from '../models/possiblePreferences';
import * as locale from 'locale-codes'

@Injectable(
  {
    providedIn: 'root'
  }
)
export class DataService {
  private currentUserInfo : any;
  private possiblePreferences : PossiblePreferences;
  refreshNavEvent : Subject<any> = new Subject<any>();
  reonboarding: boolean;


  constructor() {}

  setCurrentUserInfo(currentUserInfo: CurrentUserInfo) {
    this.currentUserInfo = currentUserInfo;
    localStorage.setItem('user', JSON.stringify(currentUserInfo));
  }

  getCurrentUserInfo() {
    if (this.currentUserInfo) return this.currentUserInfo;
    else return this.getCurrentUser();
  }

  setReonboarding(reonboarding: boolean){
    localStorage.setItem('reonboard', String(reonboarding));
  }

  getReonboarding(): boolean{
    return localStorage.getItem('reonboard') === 'true';
  }

  getCurrentUser() {
    let result = localStorage.getItem('user');
    return JSON.parse(result || '{}');
  }

  getCustomer() {
    let current = localStorage.getItem("user");
    let userInfo = JSON.parse(current!);
    return userInfo?.customer;
  }

  setPossiblePreferences(possiblePreferences: PossiblePreferences) {
    this.possiblePreferences = possiblePreferences;
  }

  getPossiblePreferences() {
    return this.possiblePreferences;
  }

  setLanguage(language: string) {
    localStorage.setItem("language", language);
  }

  getLanguage(): string {
    let lang = localStorage.getItem("language");
    if (lang) {
      return lang
    }
    return "en";
  }

  refreshNav(){
    this.refreshNavEvent.next(null);
  }

  getLocale(lang,type){
    // FZ 6163 for documentation on localize :
    //  https://www.npmjs.com/package/locale-codes
    let res;
    if (lang == 'en')
      res = 'en-GB'
    else
      res = lang +'-'+ lang.toUpperCase();;

    res = locale.getByTag(res)[type]
    return res;

  }

}
