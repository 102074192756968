import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { CANMAP_CUSTOMER_ENDPOINT, CANMAP_ENDPOINT } from 'src/app/core/config/config.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-import-map-modal',
  templateUrl: './import-map-modal.component.html',
  styleUrls: ['./import-map-modal.component.scss']
})
export class ImportMapModalComponent implements OnInit {
  vehicles: any = [];
  models: any = [];
  selectedValue: any;
  submitted = false;
  fileName = '';
  file;
  faUpload: any;
  isError = false;
  uidCanMapType: any;
  text: string;
  canmap: any;
  fileToBeImported: string;
  canmapContent: string;
  selectedCustomerUid: string;

  constructor(public dialogRef: MatDialogRef<ImportMapModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, 
  private apiService: ApiService,
  private utilsService: UtilsService) {  
    this.uidCanMapType = data.uidCanMapType;
    this.selectedCustomerUid = data.uidCustomer;
    this.text = data.text;   
  }

  ngOnInit(): void { 
    this.faUpload = faUpload;
  }

  close(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.submitted = true;
    if (this.fileName !== '' && this.fileName !== undefined){
      this.updateCanMap();
    }
    else {
      this.isError = true;
    }
  }

  updateCanMap(): void{
    const formData = new FormData();
    formData.append("", this.file);
    this.apiService.post(CANMAP_CUSTOMER_ENDPOINT + this.selectedCustomerUid + '/new-version/of/' + this.uidCanMapType, formData)
      .subscribe(
        (canmap: any) => {
          this.canmap = canmap;
          this.dialogRef.close({done: true});
        },
        error => {
          this.utilsService.showErrorPopup(error);
          this.dialogRef.close({done: false});
        });
  }

  onFileSelected(event): void {
    this.file = event.target.files[0];
    if (this.file){
      this.fileName = this.file.name;
      this.changeFileToData(this.file);
      this.isError = false;
    } else {
      this.fileName = '';
      this.isError = true;
    }
    
  }

  changeFileToData(file: any): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.fileToBeImported = String(reader.result);
      this.canmapContent = this.fileToBeImported;
    }
    reader.readAsText(file);

  }
}
