<div class="card">
  <h4 class="card-header">{{'global.actualDeadline' | translate}}</h4>
  <div class="card-body">
    <dx-form [formData]="operation">
      <dxi-item dataField="engineHours" [isRequired]="true">
        <dxo-label text="{{'global.deadlines.engineHours' | translate}}"></dxo-label>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="date" [isRequired]="true">
        <dxo-label text="{{'global.deadlines.date' | translate}}"></dxo-label>
        <dx-date-box [displayFormat]="displayFormat" [(value)]="operation.date"></dx-date-box>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="notes">
        <dxo-label text="{{'global.deadlines.notes' | translate}}"></dxo-label>
      </dxi-item>
      <dxi-item dataField="nextNotificationDisabled" [colSpan]="2">
        <dxo-label text="{{'global.deadlines.nextNotificationDisabled' | translate}}"></dxo-label>
      </dxi-item>
    </dx-form>

  </div>
  <div class="row errorMessage" *ngIf="errorMessage != ''">
    * {{errorMessage}}
  </div>
  <div class="card-footer">
    <dx-button class="ml-1" stylingMode="outlined" type="success" (onClick)="save()"> {{"global.save" | translate}}
    </dx-button>
    <dx-button class="ml-1" stylingMode="outlined" type="normal" (onClick)="close()"> {{"global.cancel" | translate}}
    </dx-button>


  </div>
</div>
