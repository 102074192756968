<dx-scroll-view #scrollviewMiscellaneous id="scrollviewMiscellaneous" [scrollByContent]="scrollByContent"
  [scrollByThumb]="scrollByThumb" [showScrollbar]="scrollbarMode" [bounceEnabled]="pullDown"
  reachBottomText="Updating..." [width]="windowW" [height]="windowH">
  <div id="scrollviewMiscellaneous-content">

    <div class="card">
      <div class="flex justify-between card-header ml-2">
        <div class="flex items-center ">
          <h4 class="m-0">VIN: {{vinSelect}}</h4>
        </div>
        <div>


          <a (click)="onJsonSwitch()" class="mr-2 cursor-help hover:text-sky-700">{{btnShowJson}}
            <!-- <fa-icon [icon]="faList"></fa-icon> -->
          </a>


        </div>

      </div>

      <div class="card-body pt-1">
        <div class="flex justify-center items-center   gap-4" *ngIf="!showJsonEditor">
          <div>
            <dx-date-box
             type="datetime"
             label="{{'global.froms' | translate }}"
             labelMode="floating"
             [(value)] = fromDateValue
             [min] = fromDateMin
             [max] = toDateMax

             class="m-1"
             placeholder="{{dateTimeFormat}}"
             [displayFormat]="dateTimeFormat"
           >
            </dx-date-box>
          </div>
          <div>
            <dx-date-box
            type="datetime"
            label="{{'global.tos' | translate }}"
            labelMode="floating"
            [(value)] = toDateValue
            [min] = fromDateMin
            [max] = toDateMax
            placeholder="{{dateTimeFormat}}"
            [displayFormat]="dateTimeFormat"
            class="m-1"
           >
            </dx-date-box>
          </div>
          <div>
            <button id="applyBtn" (click)="applyFilter()"  [ngClass]="stylesheetString" class="btn btn-sm"
        translate="global.applyFilter"></button>
          </div>

        </div>
        <hr class="m-2">
        <!--
          <div *ngFor="let item of _dataMiscellanues; index as i">
                      {{item | json}}
          </div> -->

          <div class="flex justify-center items-center ml-2 h-80"  *ngIf="!showDataTable &&!showJsonEditor">

              <span class="font-mono  tracking-wide text-teal-500">
                  {{onDataLoadMsg}}
              </span>

          </div>


        <actia-tableDevExtreme [idComponent]="'firstTAble'" [pageSize]=pageSize [allowedPageSizes]=allowedPageSizes
          [dataTable]="dataTableFromComponent" [enabledExportButton]="true" [inkeyExpr]="inkeyExpr"
          [dataCols]="dataCols" [inkeyExpr]="inkeyExpr" [selectMode]="'none'"
          (onSelectedRows)="onArraySelectedRows($event)" (gridComponent)="getGridElement($event)"
          [tableRefresh]="tableRefreshInLineGrid.asObservable()" *ngIf="showDataTable && !showJsonEditor "></actia-tableDevExtreme>

        <div *ngIf="showJsonEditor" class="overflow-scroll h-auto">
          <actia-jsonEditor [dataJS]="dataMiscellanues" [mode]="jsMode"
            (onChangeObject)="onChangeJsonEditorData($event)"></actia-jsonEditor>

        </div>


      </div>
      <!-- <div class="card-footer">
        <a (click)="close()" class="btn btn-ok">{{"global.close" | translate}}</a>
    </div> -->
    </div>
  </div>
</dx-scroll-view>
