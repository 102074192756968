<div class="card">
    <h4 class="card-header">{{"global.modal.importSuccess" | translate}}</h4>
    <div class="card-body">
      <div class="card-text">
        <div *ngFor = "let line of display">
          <div>
            {{line}}
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">

      <dx-button class="ml-1" stylingMode="outlined" type="normal" (onClick)="close()"> {{"global.modal.ok" | translate}}
      </dx-button>

    </div>
</div>
