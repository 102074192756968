import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxTextBoxComponent, DevExtremeModule, DxValidationGroupComponent } from 'devextreme-angular';
import { faSearch, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FilterService } from '../filter/filter.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-assets-search',
  templateUrl: './assets-search.component.html',
  styleUrls: ['./assets-search.component.scss']
})
export class AssetsSearchComponent implements OnInit {
  value: any;

  constructor(public translateService: TranslateService, 
    private filterService: FilterService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<AssetsSearchComponent>) {}

  @ViewChild(DxTextBoxComponent, { static: false }) searchBox: DxTextBoxComponent;
  faSearch: IconDefinition = faSearch;
  searchAssetString = this.translateService.instant("global.searchAsset");
  radioGroupContent: string[] = [];
  radioGroupSelectedValue = 'drivers';
  resultSectionToggler = 'drivers';
  searchResponse: {
    items: any[],
    name: string,
    uid: string
  }[] = [];

  @ViewChild('searchSection', {static: false}) validationGroup: DxValidationGroupComponent;
  selectedCustomer: any;

  customerList: any[] = [];
  vehiclesList: any[] = [];
  driversList: any[] = [];
  devicesList: any[] = [];
  isVehicle: boolean = false;
  isDriver: boolean = false;
  isDevice: boolean = false;

  ngOnInit(): void {

    this.isDriver = this.authService.isFeatureGranted("drivers", "read");
    this.isVehicle = this.authService.isFeatureGranted("vehicles", "read");
    this.isDevice = this.authService.isFeatureGranted("devices", "read");


    if(this.isDriver){
      this.radioGroupContent.push(this.translateService.instant("global.drivers"));
    }
    if(this.isVehicle){
      this.radioGroupContent.push(this.translateService.instant("global.vehicles"));
    }
    if(this.isDevice){
      this.radioGroupContent.push(this.translateService.instant("global.devices"));
    }
  }

  radioGroupValueChanged(ev: any) {
    let translatedSelectedValue = ev.value;
    let result = this.radioGroupSelectedValue;
    switch (translatedSelectedValue) {
      case this.translateService.instant("global.drivers"): result = 'drivers'; break;
      case this.translateService.instant("global.vehicles"): result = 'vehicles'; break;
      case this.translateService.instant("global.devices"): result = 'devices'; break;
    }
    this.radioGroupSelectedValue = result;
  }

  validateNumber() {
    return this.value.length > 2;
  }

  async onSearch(e: any) {
   let validationResponse = this.validationGroup.instance.validate();
    if (validationResponse.isValid) {
      this.clearDataStore();
      this.resultSectionToggler = this.radioGroupSelectedValue;
      switch (this.resultSectionToggler) {
        case 'drivers': this.searchResponse = await this.filterService.searchDrivers(this.searchBox.value); break;
        case 'vehicles': this.searchResponse = await this.filterService.searchVehicles(this.searchBox.value); break;
        case 'devices': this.searchResponse = await this.filterService.searchDevices(this.searchBox.value); break;
      }
      this.setSearchResponse();
    }
  }

  setSearchResponse() {
    if (this.searchResponse.length > 0) {
      this.customerList = [];
      this.searchResponse.forEach((singleResponse) => {
        this.customerList.push({
          name: singleResponse.name,
          uid: singleResponse.uid
        });
      });
      this.selectedCustomer = this.customerList[0].uid;
      this.updateDataStore(this.searchResponse[0]);
    } else {
      this.clearDataStore();
    }
  }

  clearDataStore() {
    this.selectedCustomer = null;
    this.searchResponse = [];
    this.customerList = [];
    this.driversList = [];
    this.vehiclesList = [];
    this.devicesList = [];
  }

  close(){
    this.dialogRef.close();
  }

  updateDataStore(arrayEntries: any) {
    switch (this.resultSectionToggler) {
      case 'drivers': this.driversList = arrayEntries.items; break;
      case 'vehicles': this.vehiclesList = arrayEntries.items; break;
      case 'devices': this.devicesList = arrayEntries.items; break;
    }
    //console.log(this.vehiclesList, this.driversList, this.devicesList);
  }

  customerChanged(ev: any) {
    this.selectedCustomer = ev.itemData.uid;
    let newEntry = this.searchResponse.find((entry) => entry.uid == this.selectedCustomer);
    this.updateDataStore(newEntry);
  }

}
