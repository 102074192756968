import { UtilsService } from 'src/app/core/services/utils.service';
import { ApiService } from './api.service';
import { DataService } from 'src/app/core/services/data.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { LOGIN_ENDPOINT } from '../config/config.constants';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router, 
    private dataService: DataService, 
    private apiService: ApiService, 
    private utilsService: UtilsService) { }

  logOut() {   
    localStorage.clear();
    this.router.navigate(['/auth/login'])
  }


  isAuthenticated() {
    let token = localStorage.getItem("token");
    return token != null;
  }

  isFeatureGranted(functionalityName, level) {
    level = level.toLowerCase();
    var feature;
    let user = this.dataService.getCurrentUserInfo();
    if (this.isAuthenticated() && !_.isEmpty(user) && !_.isEmpty(user.user) && !_.isEmpty(user.user.group) && !_.isEmpty(user.user.group.features)) {
      for (var i = 0; i < user.user.group.features.length; ++i) {
        feature = user.user.group.features[i];
        for (var k = 0; k < feature.functionalities.length; ++k) {
          if (feature.functionalities[k].name === functionalityName) {
            // funzi. trovata.
            if (level === 'write' && feature.functionalities[k].settings.manage) {
              // vero che ha "write"
              return true;
            }
            if (level === 'read' && feature.functionalities[k].settings.enabled) {
              // vero che ha "read"
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  // Check if user has write grant on provided menu label name
  isMenuLabelWriteGranted(menuLabelName) {
    let user = this.dataService.getCurrentUser();
    var menuItem = this.utilsService.getMenuItem(user.menu, menuLabelName);
    if (menuItem !== undefined) {
      return menuItem.w;
    } else {
      return false;
    }
  }

  // Check if user has read grant on provided menu label name
  isMenuLabelReadGranted(menuLabelName) {
    let user = this.dataService.getCurrentUser();
    var menuItem = this.utilsService.getMenuItem(user.menu, menuLabelName);
    if (menuItem !== undefined) {
      return true;
    } else {
      return false;
    }
  }
}