import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { DataService } from 'src/app/core/services/data.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { VehicleMaintenanceService } from 'src/app/pages/vehicules-maintenance/vehicles-maintenance.service';

@Component({
  selector: 'edit-vehicle-maintenance-modal',
  templateUrl: './edit-vehicle-maintenance-modal.component.html',
  styleUrls: ['./edit-vehicle-maintenance-modal.component.scss']
})
export class EditMaintenanceVehicleModalComponent implements OnInit {
  operation: any = {};
  vehicleUid: any;
  uidOperation: any;
  errorMessage: string = '';
  language: string = 'en';
  displayFormat: string;

  constructor(public dialogRef: MatDialogRef<EditMaintenanceVehicleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService,
    private utilsService: UtilsService,    
    private dataService: DataService,
    private vehicleMaintenanceService: VehicleMaintenanceService) {
    let operation = {
      //date: new Date(data.actualDeadlines?.date),
      date: new Date(),
      engineHours: data.engineHours,
      notes: data.notes,
      nextNotificationDisabled: data.nextNotificationDisabled,
    }
    this.operation = operation;
    this.vehicleUid = data.currentVehicleUid;
    this.uidOperation = data.uidOperationsBlock;
  }

  ngOnInit(): void {
    this.translateService.setDefaultLang(this.dataService.getLanguage());
    this.language = this.dataService.getLanguage();
    this.translateService.get('global.dateFormat').subscribe(dateFormat => {
      this.displayFormat = dateFormat;
    })
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    if(this.operation.engineHours >= this.data.engineHours){
      if (this.operation?.date != '' && (this.operation?.engineHours && this.operation?.engineHours != '' || (this.operation?.engineHours == "" && this.operation?.engineHours == 0 && typeof (this.operation?.engineHours) == 'number'))) {
        this.saveOperation()
      }
    }else{
      this.errorMessage = 'Le ore motore inserite sono inferiori alle ore effettive'
    }
  }

  saveOperation(): void {
    let params = {
      UidOperationsBlock: this.uidOperation,
      engineHours: this.operation.engineHours,
      timestampMillisec: this.utilsService.toUTC(new Date(this.operation.date)),
      notes: this.operation.notes,
      nextNotificationDisabled: this.operation.nextNotificationDisabled
    };
    this.vehicleMaintenanceService.setVehiclePendingOperation(this.vehicleUid, params).subscribe(() => {
      this.dialogRef.close({ currentVehicle: this.vehicleUid });
    }, (error) => {
      this.dialogRef.close();
    });
  }

}
