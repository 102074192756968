<div class="card">
  <h4 class="card-header">Veicoli associati</h4>
  <div class="card-body">
    <dx-data-grid id="gridContainer" [dataSource]="data" [allowColumnReordering]="true"
      [allowColumnResizing]="true" [showRowLines]="true">
      <dxi-column dataField="internalId" caption="ID interno">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>
      <dxi-column dataField="plate" caption="Targa">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
      </dxo-search-panel>
    </dx-data-grid>
  </div>
  <div class="card-footer">
    <a (click)="close()" class="btn btn-no">Chiudi</a>
  </div>
</div>