import { HandleErrorInterceptor } from './core/Interceptors/handle-error-interceptor';
import { HeadersInterceptor } from './core/Interceptors/headers-interceptor.service';
import { CoreModule } from './core/core.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { TemplatePageComponent } from './template-page/template-page.component';
import { SharedModule } from './shared/shared.module';
import { TokenInterceptor } from './core/Interceptors/token-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { AuthService } from './core/services/auth.service';
import { NgcCookieConsentModule,NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import localePt from '@angular/common/locales/pt';
import localeEs from '@angular/common/locales/es';
import { StoreModule } from '@ngrx/store';
import { reducerCompleteApplyState, reducerFilterRefSetting, reducerSetTabAssetChooserRef, reducerTrailersOfCustomers, reducersApplySelectedAssets, reducersApplySelectedCustomer, reducersApplySelectedPeriod, reducersCustomers, reducersDriversOfCustomers, reducersSelectedCustomerCache, reducersSetSelectedAssets, reducersSetSelectedPeriod, reducersSetTabFilterRef, reducersUser, reducersVehiclesOfCustomers } from './core/store/reducers';
import { metaReducers } from './core/store/hydration';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { PostEffects } from './core/store/effects';
import { HydrationEffects } from './core/store/hydration/hydration.effects';
registerLocaleData(localeFr);
registerLocaleData(localeEn);
registerLocaleData(localeIt);
registerLocaleData(localeDe);
registerLocaleData(localePt);
registerLocaleData(localeEs);

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.baseUrl
  },
  palette: {
    popup: {
      background: "#7bac41"
    },
    button: {
      background: "white",
      text: "gray"
    }
  },
  theme: "classic",
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a  class="cc-link" id="cookieconsent-link" >{{link}}</a>
    </span>
    `,
  }
};
@NgModule({
  declarations: [
    AppComponent,
    TemplatePageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LeafletModule,
    LeafletDrawModule,
    LeafletMarkerClusterModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),


    StoreModule.forRoot({}, {}),

    StoreModule.forRoot(
      {
      'getCustomers':reducersCustomers,
      'getVehiclesOfCustomers':reducersVehiclesOfCustomers,
       'getTrailersOfCustomers' : reducerTrailersOfCustomers,
     // 'getFleetsOfCustomers':reducersFleetsOfCustomers,
      'getDriversOfCustomers':reducersDriversOfCustomers,
     // 'getTeamsOfCustomers':reducersTeamsOfCustomers,
      'setSelectedCustomerCache':reducersSelectedCustomerCache,
      'setTabFilterRef':reducersSetTabFilterRef,
      'setTabAssetChooserRef':reducerSetTabAssetChooserRef,
      'user':reducersUser,
      'applySelectedCustomer':reducersApplySelectedCustomer,
      'setSelectedAssets':reducersSetSelectedAssets,
      'applySelectedAssets':reducersApplySelectedAssets,
      'setSelectedPeriod':reducersSetSelectedPeriod,
      'applySelectedPeriod':reducersApplySelectedPeriod,
      'completeApplyState':reducerCompleteApplyState,
      'filterRefSetting':reducerFilterRefSetting
        },
     { metaReducers }),
     EffectsModule.forRoot([PostEffects,HydrationEffects]),
     StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
     }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
        provide: HTTP_INTERCEPTORS,
        useFactory: function(authService: AuthService) {
          return new HandleErrorInterceptor(authService);
        },
        multi: true,
        deps: [AuthService]
     },
     DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
