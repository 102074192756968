/* eslint-disable prettier/prettier */
/* eslint-disable sort-imports */
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DxCheckBoxModule, DxDataGridModule, DxFormModule, DxListModule, DxButtonModule, DxContextMenuModule, DxMenuModule, DxPopoverModule, DxRadioGroupModule, DxSelectBoxModule, DxTemplateModule, DxTextBoxModule, DxValidatorModule, DxTagBoxModule, DxPopupModule, DxTreeViewModule, DxSliderModule, DxDateBoxModule, DxNumberBoxModule, DxValidationGroupModule, DxTextAreaModule, DxTabPanelModule, DxTabsModule, DxSwitchModule, DxLookupModule, DxFileUploaderModule, DxChartModule, DxProgressBarModule, DxAccordionModule, DxColorBoxModule, DxRangeSelectorModule, DxScrollViewModule, DxScrollViewComponent, DxLoadPanelModule, DxDropDownBoxModule } from 'devextreme-angular';
import {MatSidenavModule} from '@angular/material/sidenav';
import { SidenavComponent } from './components/nav/sidenav/sidenav.component';
import { SidenavItemComponent } from './components/nav/sidenav-item/sidenav-item.component';
import { MatMenuModule } from '@angular/material/menu';
import { HeaderComponent } from './components/nav/header/header.component';
import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { RouterModule } from '@angular/router';
import { DxoPagerModule, DxoPagingModule } from 'devextreme-angular/ui/nested';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import { WarningModalComponent } from './components/warning-modal/warning-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { CutomersTreeComponent } from './components/cutomers-tree/cutomers-tree.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FilterComponent } from './components/filter/filter.component';
import { NgWizardModule, THEME } from 'ng-wizard';
import { AssetsChooserComponent } from './components/assets-chooser/assets-chooser.component';
import { TimespanChooserComponent } from './components/timespan-chooser/timespan-chooser.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { AssetsSearchComponent } from './components/assets-search/assets-search.component';
import { HelpHeaderPopupComponent } from './components/page-header/help-header-popup/help-header-popup.component';
import { ImportMapModalComponent } from './components/import-map-modal/import-map-modal.component';
import { AssignMapModalComponent } from './components/assign-map-modal/assign-map-modal.component';
import { MatSliderModule } from '@angular/material/slider';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ProgressModalComponent } from './components/progress-modal/progress-modal.component';
import { ContactSupportModalComponent } from './components/contact-support-modal/contact-support-modal.component';
import { ImportSuccessModalComponent } from './components/import-success-modal/import-success-modal.component';
import { GridToolbarComponent } from './components/grid-toolbar/grid-toolbar.component';
import { EditMaintenanceVehicleModalComponent } from './components/edit-vehicle-maintenance-modal/edit-vehicle-maintenance-modal.component';
import { MiscellaneousModalComponent } from './components/miscellaneous-modal/miscellaneous-modal.component';
import { ActiaTableDevExtremeComponent } from './components/tableDevExtreme/tableDevExtreme.component';
import { ActiaJsonEditorComponent } from './components/jsonEditor/jsonEditor.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { NoticeModalComponent } from './components/notice-modal/notice-modal.component';
import { FilterRefComponent } from './components/filter-ref/filter-ref.component';
import { AssetsChooserRefComponent } from './components/assets-chooser-ref/assets-chooser-ref.component';
import { TimespanChooserRefComponent } from './components/timespan-chooser-ref/timespan-chooser-refcomponent';
import { CutomersTreeRefComponent } from './components/cutomers-tree-ref/cutomers-tree-ref.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {TabViewModule} from 'primeng/tabview';
import {DividerModule} from 'primeng/divider';
import {FieldsetModule} from 'primeng/fieldset';
import { TooltipModule } from 'primeng/tooltip';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {SkeletonModule} from 'primeng/skeleton';
import { DropdownModule } from 'primeng/dropdown';
import { DetailodalComponent } from '../pages/vehicules-maintenance/customize-maintenance/detail-modal/detail-modal.component';
import { AssociatedVehiclesModalComponent } from '../pages/vehicules-maintenance/customize-maintenance/associatedVehicles/associated-vehicles-modal.component';



const COMPONENTS : any[] = [
  HeaderComponent,
  SidenavComponent,
  SidenavItemComponent,
  ProgressModalComponent,
  ContactSupportModalComponent,
  ConfirmationModalComponent,
  NoticeModalComponent,
  DetailodalComponent,
  AssociatedVehiclesModalComponent,
  EditMaintenanceVehicleModalComponent,
  WarningModalComponent,
  ErrorModalComponent,
  CutomersTreeComponent,
  CutomersTreeRefComponent,
  LanguageSelectorComponent,
  SuccessModalComponent,
  GridToolbarComponent,
  MiscellaneousModalComponent,
  ActiaTableDevExtremeComponent,
  ActiaJsonEditorComponent,
  ImportSuccessModalComponent,
  FilterComponent,
  FilterRefComponent,
  AssetsChooserComponent,
  AssetsChooserRefComponent,
  TimespanChooserComponent,
  TimespanChooserRefComponent,
  PageHeaderComponent,
  AssetsChooserComponent,
  AssetsSearchComponent,
  ImportMapModalComponent,
  AssignMapModalComponent
];
const PIPES: any[] = [];
const MODULES: any[] = [
  RouterModule,

  DxMenuModule,
  ClipboardModule,
  DxContextMenuModule,
  MatExpansionModule,
  MatSlideToggleModule,
  DxRadioGroupModule,
  MatSliderModule,
  MatProgressBarModule,
  DxPopoverModule,
  DxSwitchModule,
  DxTextBoxModule,
  DxButtonModule,
  DxValidatorModule,
  DxoPagerModule,
  DxoPagingModule,
  DxListModule,
  DxLookupModule,
  DxTagBoxModule,
  DxColorBoxModule,
  DxRangeSelectorModule,
  DxDataGridModule,
  DxTextAreaModule,
  DxTabsModule,
  DxTabPanelModule,
  MatSidenavModule,
  DxFormModule,
  DxTextBoxModule,
  DxAccordionModule,
  DxProgressBarModule,
  DxChartModule,
  DxCheckBoxModule,
  DxTagBoxModule,
  DxFileUploaderModule,
  DxValidationGroupModule,
  DxButtonModule,
  FormsModule,
  DxNumberBoxModule,
  ReactiveFormsModule,
  DxTreeViewModule,
  DxDateBoxModule,
  DxSelectBoxModule,
  DxPopupModule,
  DxSliderModule,
  DxDropDownBoxModule,
  MatMenuModule,
  RouterModule,
  MatDialogModule,
  FontAwesomeModule,
  DxTemplateModule,
  DxFileUploaderModule,
  DxTabPanelModule,
  DxTextAreaModule,
  DxScrollViewModule,
  DxLoadPanelModule,
  NgJsonEditorModule,
  OverlayPanelModule,
  TabViewModule,
  DividerModule,
  FieldsetModule,
  TooltipModule,
  ProgressBarModule,
  InputTextModule,
  ButtonModule,
  SkeletonModule,
  DropdownModule,
  TranslateModule.forRoot({
    loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
  }),
  MatPasswordStrengthModule.forRoot(),
  NgWizardModule.forRoot({
    theme: THEME.default
  })
];
const DIRECTIVES: any[] = [];


@NgModule({
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES, HelpHeaderPopupComponent,FilterRefComponent],
  exports: [...COMPONENTS, ...PIPES, ...DIRECTIVES, ...MODULES],
  imports: [
    CommonModule,
    ...MODULES
  ]
})
export class SharedModule { }


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
