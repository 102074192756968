<div class="row aft-grid-toolbar">
  <div class="col-2 col-xs-12 col-md-2 aft-text-align-left">
    <div class="dx-field-value toLeft">
      <dx-switch [value]="true" style="width: 80px;" [switchedOnText]="switchedOnText"
        [switchedOffText]="switchedOffText" (onValueChanged)="switchChanged($event)"></dx-switch>
    </div>
  </div>

  <div class="col-8 col-xs-12 col-md-8">
    <div class="aft-grid-toolbar__title-wrapper">
      <span class="aft-grid-toolbar__title aft-whitespace-prewrap">{{options.title}}</span>
    </div>

    <div class="aft-margin-left-right-auto" style="text-align: -webkit-center;">
      <dx-select-box class="spacing" [dataSource]="{store: vehicles}" [searchEnabled]="true"
        displayExpr="plateWithInternalId" valueExpr="uid" [value]="vehicles[0]?.uid"
        (onValueChanged)="onVehicleChanged($event)"></dx-select-box>
    </div>
  </div>

  <div class="col-2 col-xs-12 col-md-2 aft-text-align-right">
    <a class="btn btn-default btn-xs btn-square-xs aft-margin-left-10 aft-margin-right-20 toRight" (click)="btnExportClick()"
      *ngIf="options.showExport"
      pTooltip="{{'global.export' | translate  }}" tooltipPosition="left">

      <span class="fa fa-file-excel-o"></span>
    </a>
  </div>
