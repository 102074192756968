<div style="width: 90%;">
  <!--  timespan select  -->
  <div class="my-2 w-75">
    <dx-select-box [value]="currentTimespan" (onSelectionChanged)="selectBoxChanged($event)" [searchEnabled]="true"
      [dataSource]="timespanSelectBoxItems" displayExpr="value" valueExpr="id" placeholder="selectTimePeriod(trans)">
    </dx-select-box>
  </div>

  <!-- date field From - To -->
  <div class="row aft-margin-top-10">
    <div class="col-xs-12 col-sm-6 col-md-12">
      <div class="row col-xs-12">
        <!-- label and value -->
        <div>
          <div *ngIf="currentTimespan != 'last7days'" class="aft-filter__time-span-chooser__label">
            <strong *ngIf="currentTimespan == 'timeperiod'" translate="global.from"></strong>
            <strong *ngIf="currentTimespan == 'daily'" translate="global.day"></strong>
            <strong *ngIf="currentTimespan == 'month'" translate="global.month"></strong>
            <strong *ngIf="currentTimespan == 'week'" translate="global.week"></strong>
          </div>
        </div>
        <!-- from datepicker -->
        <div *ngIf="currentTimespan != 'last7days'" class="dx-field-value">
          <dx-date-box (onValueChanged)="fromValueChanged($event)" [max]="toDtPicker"
            [displayFormat]="displayFormat" [calendarOptions]="calendarOptions" [value]="fromDtPicker" type="date">
          </dx-date-box>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-12" *ngIf="currentTimespan == 'timeperiod'">
      <div class="row col-xs-12">
        <!-- label and value -->
        <div>
          <div class="aft-filter__time-span-chooser__label">
            <strong translate="global.to"></strong>
          </div>
        </div>

        <!-- to datepicker -->
        <div class="dx-field-value">
          <dx-date-box (onValueChanged)="toValueChanged($event)" [min]="fromDtPicker"
            [displayFormat]="displayFormat" [calendarOptions]="calendarOptions" [value]="toDtPicker" type="date">
          </dx-date-box>
        </div>
      </div>
    </div>
  </div>

  <!-- timespan summary labels and values-->
  <div class="row">
    <div class="col-xs-12" *ngIf="currentTimespan != 'timeperiod' && currentTimespan != 'daily'">
      <div class="aft-filter__time-span-chooser__label">
        <strong translate="global.from"></strong> {{ summaryFromDate }}
        &nbsp;<strong translate="global.to"></strong> {{ summaryToDate }}
      </div>
    </div>
  </div>