<div #filter [ngClass]="{hidden: !isFilterVisible}" class="filter-wrapper">
  <section #filterHead class="filter-header align-items-start" (click)="toggleFilter()">
    <strong><i class="fa-solid fa-filter ms-1"></i></strong>
    <div class="me-2 mr-l filterTitle"><div translate="global.customer"></div> {{ getCustomerTranslation() }} </div>
    <div *ngIf="isFilterOpen()" class="pr-5"><i (click)="toggleFilter()" class="fa-solid fa-times"></i></div>
  </section>
    <div class="filter-body filter-display">
    <div *ngIf="isCustomerStringVisible" class="filter-recap"><button id="applyBtn" [ngClass]="stylesheetString" (click)="applyFilter()" class="btn btn-sm m-2" translate="global.applyFilter"></button> </div>
      <ng-wizard [config]="ngWizardConfigs" (stepChanged)="stepChanged($event)">
  
        <ng-wizard-step *ngIf="customerFilterState" [title]="'global.customers' | translate">
          <app-cutomers-tree (customerSelected)="onTreeItemClick($event)" [isForFilter]="true"></app-cutomers-tree>
        </ng-wizard-step>
        
        <ng-wizard-step *ngIf="assetsFilterState" [title]="'global.assets' | translate">
          <app-assets-chooser></app-assets-chooser>
        </ng-wizard-step>
        
        <ng-wizard-step *ngIf="timespanFilterState" [title]="'global.timeperiod' | translate">
          <app-timespan-chooser></app-timespan-chooser>
        </ng-wizard-step>
      </ng-wizard>
    </div>
</div>