<div class="card">
    <h4 class="card-header modal-header">{{"global.modal.importFileTitle" | translate}}</h4>
    <div class="card-body">
        <p *ngIf="submitted && isError" style="color: rgb(206, 3, 3);">{{"global.modal.importFileError" |
            translate}}</p>
        <input type="file" accept=".json" class="file-input" (change)="onFileSelected($event)" #fileUpload>

        <div class="file-upload">
            <span class="file_name" [ngClass]="fileName == '' ? 'file_name_error' : 'file_name_ok'">{{fileName ||
                'global.canMaps.jsonFile' | translate}}</span>

            <button class="import_btn btn" mat-mini-fab color="primary" (click)="fileUpload.click()">
                <fa-icon style="margin-top: 20px;" [icon]="faUpload"></fa-icon>
            </button>
        </div>
    </div>
    <div class="card-footer">
      <dx-button class="ml-1" stylingMode="outlined" type="success" (onClick)="confirm()"> {{"global.modal.confirm" | translate}}
      </dx-button>
      <dx-button class="ml-1" stylingMode="outlined" type="normal" (onClick)="close()"> {{"global.modal.cancel" | translate}}
      </dx-button>

    </div>
</div>
